import {
  SET_TEACHER_PRIORITY,
  setTeacherPriorityType,
  TeacherPriorityActions,
} from '@modules/teacher-priority/actions';
import { TeacherPriorityReducer } from '@modules/teacher-priority/types';

const defaultState: TeacherPriorityReducer = {};
const TeacherPriorityReducerMethod = (
  state: TeacherPriorityReducer = defaultState,
  action: TeacherPriorityActions,
): TeacherPriorityReducer => {
  if (action.type === SET_TEACHER_PRIORITY) {
    const item = (action as setTeacherPriorityType).payload;
    if (!item) {
      return state;
    }
    return {
      ...state,
      [item.userId]: item.value,
    };
  }

  return state;
};

export default TeacherPriorityReducerMethod;
