import { ICompany } from '@modules/company/types';
import {
  CompanyActions,
  CREATE_OR_UPDATE_COMPANY,
  createOrUpdateCompanyType, REMOVE_COMPANY, removeCompanyType,
  SET_COMPANY_LIST,
  setCompanyListType,
} from '@modules/company/actions';

const CompanyReducerMethod = (
  state: ICompany[] = [],
  action: CompanyActions,
): ICompany[] => {
  if (action.type === SET_COMPANY_LIST) {
    return (action as setCompanyListType).payload || [];
  }
  if (action.type === CREATE_OR_UPDATE_COMPANY) {
    const item = (action as createOrUpdateCompanyType).payload;
    const index = state.findIndex(i => i.id === item.id);

    if (!!~index) {
      return [
        ...state.slice(0, index),
        item,
        ...state.slice(index + 1),
      ];
    }

    return [
      item,
      ...state,
    ];
  }

  if (action.type === REMOVE_COMPANY) {
    const id = (action as removeCompanyType).payload;

    const index = state.findIndex(i => i.id === id);

    return [
      ...state.slice(0, index),
      ...state.slice(index + 1),
    ];
  }

  return state;
};

export default CompanyReducerMethod;
