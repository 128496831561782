import {
  ILoadTransactionByAccount,
  IUpdateTransaction,
} from '@modules/transactions/types/transaction';
import { CommonAction } from '@modules/common/actions';

export const SET_TRANSACTIONS_LIST = 'SET_TRANSACTIONS_LIST';
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export const SET_COUNT_TRANSACTION = 'SET_COUNT_TRANSACTION';

export const setTransactionsList = CommonAction<ILoadTransactionByAccount>(SET_TRANSACTIONS_LIST);
export const updateTransactions = CommonAction<IUpdateTransaction>(UPDATE_TRANSACTIONS);
export const setCountTransaction = CommonAction<number>(SET_COUNT_TRANSACTION);

export type setTransactionsListType = ReturnType<typeof setTransactionsList>;
export type updateTransactionsType = ReturnType<typeof updateTransactions>;
export type setCountTransactionType = ReturnType<typeof setCountTransaction>;

export type TransactionsAction = setTransactionsListType | updateTransactionsType | setCountTransactionType
