import { BoxTariff } from '@modules/box-tariffs/types';
import {
  BoxTariffsActions,
  CREATE_OR_UPDATE_BOX_TARIFFS,
  creteOrUpdateBoxTariffsType,
  REMOVE_BOX_TARIFFS,
  removeReviewActionType,
  SET_BOX_TARIFFS_LIST,
  setBoxTariffsListType,
} from '@modules/box-tariffs/actions';

const BoxReducerMethod = (
  state: BoxTariff[] = [],
  action: BoxTariffsActions,
): BoxTariff[] => {
  if (action.type === SET_BOX_TARIFFS_LIST) {
    return (action as setBoxTariffsListType).payload || [];
  }

  if (action.type === CREATE_OR_UPDATE_BOX_TARIFFS) {
    const item = (action as creteOrUpdateBoxTariffsType).payload;
    const index = state.findIndex(i => i.id === item.id);

    if (!!~index) {
      return [
        ...state.slice(0, index),
        item,
        ...state.slice(index + 1),
      ];
    }

    return [
      item,
      ...state,
    ];
  }

  if (action.type === REMOVE_BOX_TARIFFS) {
    const id = (action as removeReviewActionType).payload;
    const item = state.find(i => i.id === id);

    if (!item) {
      return state;
    }

    const index = state.findIndex(i => i.id === id);

    return [
      ...state.slice(0, index),
      ...state.slice(index + 1),
    ];
  }

  return state;
};

export default BoxReducerMethod;
