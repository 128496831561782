import { CommonAction } from '@modules/common/actions';
import { Token } from '@modules/accounts/model/token';

export const SET_TOKEN = 'SET_TOKEN';
export const CREATE_TOKEN = 'CREATE_TOKEN';

export const SetToken= CommonAction<Token[]>(SET_TOKEN);
export const CreateToken = CommonAction<Token>(CREATE_TOKEN);

export type SetTokenType = ReturnType<typeof SetToken>;
export type CreateTokenType = ReturnType<typeof CreateToken>;

export type ActionToken = SetTokenType | CreateTokenType
