import {
  CREATE_OR_UPDATE_TEAM_CATEGORIES,
  CREATE_OR_UPDATE_TEAM_ITEMS, REMOVE_OR_UPDATE_TEAM_CATEGORIES, REMOVE_OR_UPDATE_TEAM_ITEMS,
  SET_LIST_TEAM_CATEGORIES,
  SET_LIST_TEAM_ITEMS,
} from '@modules/information/team/actions';
import {
  ITeam, ITeamCategory, ITeamItem,
  SetCreateOrUpdateTeamCategoriesAction,
  SetCreateOrUpdateTeamItemsAction,
  SetRemoveOrUpdateTeamCategoriesAction,
  SetRemoveOrUpdateTeamItemsAction,
  SetTeamCategoriesListAction,
  SetTeamListItemsAction,
  TeamAction,
} from '@modules/information/team/types/information';

const defaultState: ITeam = {
  items: [],
  categories: [],
};

const sorter = (a: ITeamItem | ITeamCategory, b: ITeamItem | ITeamCategory) => a.sort - b.sort;

const TeamReducerMethod = (
  state: ITeam = defaultState,
  action: TeamAction,
): ITeam => {
  if (action.type === SET_LIST_TEAM_ITEMS) {
    return {
      ...state,
      items: (action as SetTeamListItemsAction).payload,
    };
  }
  if (action.type === SET_LIST_TEAM_CATEGORIES) {
    return {
      ...state,
      categories: (action as SetTeamCategoriesListAction).payload,
    };
  }

  if (action.type === CREATE_OR_UPDATE_TEAM_ITEMS) {
    const item = (action as SetCreateOrUpdateTeamItemsAction).payload;
    const index = state.items.findIndex(i => i.id === item.id);

    if (!!~index) {
      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          item,
          ...state.items.slice(index + 1),
        ].sort(sorter),
      };
    }
    return {
      ...state,
      items: [
        item,
        ...state.items,
      ].sort(sorter),
    };
  }

  if (action.type === CREATE_OR_UPDATE_TEAM_CATEGORIES) {
    const category = (action as SetCreateOrUpdateTeamCategoriesAction).payload;

    const index = state.categories.findIndex(i => i.id === category.id);
    if (!!~index) {
      return {
        ...state,
        categories: [
          ...state.categories.slice(0, index),
          category,
          ...state.categories.slice(index + 1),
        ].sort(sorter),
      };
    }
    return {
      ...state,
      categories: [
        category,
        ...state.categories,
      ].sort(sorter),
    };
  }


  if (action.type === REMOVE_OR_UPDATE_TEAM_CATEGORIES) {
    const id = (action as SetRemoveOrUpdateTeamCategoriesAction).payload;
    const index = state.categories.findIndex(i => i.id === id);

    return {
      ...state,
      categories: [
        ...state.categories.slice(0, index),
        ...state.categories.slice(index + 1),
      ],
    };
  }


  if (action.type === REMOVE_OR_UPDATE_TEAM_ITEMS) {
    const id = (action as SetRemoveOrUpdateTeamItemsAction).payload;
    const index = state.items.findIndex(i => i.id === id);

    return {
      ...state,
      items: [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1),
      ],
    };
  }

  return state;
};

export default TeamReducerMethod;
