import { IConfirmPurchase, ProductByCategory, StageProduct } from '@modules/investments/products/types/product';
import { CommonAction } from '@modules/common/actions';
import { StageLesson } from '@modules/investments/products/types/stage';

export const SET_PRODUCTS_LIST_LESSON = 'SET_PRODUCTS_LIST_LESSON';
export const SET_LESSON_FOR_PURCHASE = 'SET_LESSON_FOR_PURCHASE';
export const SET_PRODUCTS_LIST_ADMIN = 'SET_PRODUCTS_LIST_ADMIN';

// export const ADD_OR_UPDATE_PRODUCT_LESSON = 'ADD_OR_UPDATE_PRODUCT_LESSON';

export const setProductsListLesson = CommonAction<ProductByCategory[]>(SET_PRODUCTS_LIST_LESSON);
export const setLessonForPurchase = CommonAction<IConfirmPurchase>(SET_LESSON_FOR_PURCHASE);
export const setProductsListAdminAction = CommonAction<StageProduct<StageLesson>[]>(SET_PRODUCTS_LIST_ADMIN);

// export const addOrUpdateProductLesson = CommonAction<ProductByCategory>(ADD_OR_UPDATE_PRODUCT_LESSON);
