import { CommonAction } from '@modules/common/actions';
import { AppEmailLayout } from '@modules/template/types/index';

export const SET_EMAIL_LAYOUT_CREATE_OR_UPDATE = 'SET_EMAIL_LAYOUT_CREATE_OR_UPDATE'
export const SET_EMAIL_LAYOUT_LIST = 'SET_EMAIL_LAYOUT_LIST'
export const SET_EMAIL_LAYOUT_COUNT = 'SET_EMAIL_LAYOUT_COUNT'
export const SET_EMAIL_LAYOUT_REMOVE = 'SET_EMAIL_LAYOUT_REMOVE'

export const setEmailLayoutCreateOrUpdateAction = CommonAction<AppEmailLayout>(SET_EMAIL_LAYOUT_CREATE_OR_UPDATE);
export const setEmailLayoutListAction = CommonAction<AppEmailLayout[]>(SET_EMAIL_LAYOUT_LIST);
export const setEmailLayoutCountAction = CommonAction<number>(SET_EMAIL_LAYOUT_COUNT);
export const setEmailLayoutRemoveAction = CommonAction<number>(SET_EMAIL_LAYOUT_REMOVE);

export type setEmailLayoutCreateOrUpdateType = ReturnType<typeof setEmailLayoutCreateOrUpdateAction>;
export type setEmailLayoutListType = ReturnType<typeof setEmailLayoutListAction>;
export type setEmailLayoutCountType = ReturnType<typeof setEmailLayoutCountAction>;
export type setEmailLayoutRemoveType = ReturnType<typeof setEmailLayoutRemoveAction>;

export type EmailLayoutActions = setEmailLayoutCreateOrUpdateType |  setEmailLayoutListType | setEmailLayoutCountType | setEmailLayoutRemoveType
