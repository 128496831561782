import {
  DepositAction,
  SET_CURRENCY, SET_DEPOSIT_COUNT,
  SET_DEPOSIT_LIST, SET_ORDER,
  SetCurrencyType, SetDepositCountType,
  SetDepositListType, setItemDepositListType, SetOrderType, UPDATE_DEPOSIT_LIST,
} from '@modules/deposit/actions';
import { ICurrency, IDepositReducer } from '@modules/deposit/types';

const defaultState: IDepositReducer = {
  list: [],
  currency: {} as ICurrency,
  order: '',
  count: 0,
};

const DepositReducer = (
  state: IDepositReducer = defaultState,
  action: DepositAction,
): IDepositReducer => {
  if (action.type === SET_DEPOSIT_LIST) {
    return {
      ...state,
      list: (action as SetDepositListType).payload,
    };
  }

  if (action.type === SET_CURRENCY) {
    const { slug, value } = (action as SetCurrencyType).payload;
    return {
      ...state,
      currency: {
        ...state.currency,
        [slug]: value,
      },
    };
  }

  if (action.type === SET_ORDER) {
    return {
      ...state,
      order: (action as SetOrderType).payload,
    };
  }

  if (action.type === SET_DEPOSIT_COUNT) {
    return {
      ...state,
      count: (action as SetDepositCountType).payload,
    };
  }

  if (action.type === UPDATE_DEPOSIT_LIST) {
    const item = (action as setItemDepositListType).payload;
    const index = state.list.findIndex((i) => i.id === item.id);
    const exist = state.list.find((i) => i.id === item.id);
    if (!exist) {
      return {
        ...state,
        list: [
          item,
          ...state.list,
        ],
      };
    }

    return {
      ...state,
      list: [
        ...state.list.slice(0, index),
        item,
        ...state.list.slice(index + 1),
      ],
    };
  }

  return state;
};

export default DepositReducer;
