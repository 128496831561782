import { Product, IProduct } from '@modules/investments/products/types/product';
import { loadPurchaseLessonAction, loadPurchaseHistoryAction } from '@modules/investments/purchases/actions';
import { IAllStage, ICommonStage } from '@modules/investments/products/types/stage';
import { BaseCommandProps } from '@app-types/CommonTypes';
import { InvestmentsCategory } from '@modules/investments/category/types/category';
import Tz from '@utils/tzTime';
import moment from 'moment';

export class PurchaseExtra {
  public countLesson: number;
  public categoryTitleCard?: string;
  public teacherId?: number;
  public handler?: string;
  public price?: number;
  public orderId?: number;
  public promoId?: number;

  constructor(count: number, teacherId?: number, categoryTitleCard?: string, handler?: string, price?: number, orderId?: number, promoId?: number) {
    this.countLesson = count;
    this.categoryTitleCard = categoryTitleCard;
    this.teacherId = teacherId;
    this.handler = handler;
    this.price = price;
    this.orderId = orderId;
    this.promoId = promoId;
  }
}

export interface IPurchase {
  id: number
  title: string,
  url: string,
  description: string,
  sort: number
  show: boolean,
  deleted: boolean,
  fund?: string
  createdAt?: Date
  category?: InvestmentsCategory
  product?: Product
  stage?: IAllStage
  purchaseId?: number
  subjectId: number
  exchanged?: boolean
  extra?: PurchaseExtra
  stageId?: number;
  withdrawn: boolean;
}

export class BasePurchase implements IPurchase {
  public id: number;
  public title: string;
  public url: string;
  public description: string;
  public sort: number;
  public show: boolean;
  public deleted: boolean;
  public createdAt?: Date;
  public purchaseId?: number;
  public subjectId: number;
  public exchanged?: boolean;
  public extra?: PurchaseExtra;
  public stageId?: number;
  public category?: InvestmentsCategory;
  public product?: Product
  public withdrawn: boolean;

  constructor(obj: IPurchase) {
    this.id = obj.id;
    this.title = obj.title;
    this.url = obj.url;
    this.description = obj.description;
    this.sort = obj.sort;
    this.show = obj.show;
    this.deleted = obj.deleted;
    this.createdAt = obj.createdAt ? new Date(obj.createdAt) : undefined;
    this.purchaseId = obj.purchaseId;
    this.subjectId = obj.subjectId;
    this.exchanged = obj.exchanged || false;
    this.extra = new PurchaseExtra(obj.extra?.countLesson || 0, obj.extra?.teacherId, obj.extra?.categoryTitleCard, obj.extra?.handler, obj.extra?.price, obj.extra?.orderId, obj.extra?.promoId);
    this.stageId = obj.stageId;
    this.category = obj.category && new InvestmentsCategory(obj.category);
    this.product = obj.product && new Product(obj.product);
    this.withdrawn = obj.withdrawn;
  }

  get createdDate(): number {
    return moment(this.createdAt).utcOffset(0).unix();
  }

  get isGeneral(): boolean {
    return this.extra?.handler == 'general';
  }

  get isTrial(): boolean {
    return this.extra?.handler == 'trial';
  }
}

export class Purchase<Stage extends ICommonStage> extends BasePurchase {
  public stage: Stage;

  constructor(obj: IPurchase, stage: Stage) {
    super(obj);
    this.stage = stage;
  }

  get teacherId() {
    return this.extra?.teacherId;
  }

  createDate(format: string = 'YYYY-MM-DD'): string {
    const date = moment(this.createdAt).utcOffset(0).unix();
    return Tz.unix(date).format(format);
  }

  get price(): number {
    if (this.extra?.price) {
      return this.extra.price;
    }

    return this.stage.price;
  }

  get countLesson(): number {
    return this.extra?.countLesson || this.stage.countLesson || 0;
  }
}


export type setPurchaseLessonAction = ReturnType<typeof loadPurchaseLessonAction>;
export type setPurchaseHistoryAction = ReturnType<typeof loadPurchaseHistoryAction>;

export interface ICreatePurchaseAdmin {
  userId: number
  type: string
  stageId: number,
  fund?: string
}

export interface IPurchaseCount {
  shares: number,
  goods: number,
  credit: number,
}

export interface IPurchaseAdmin {
  id: number,
  stageId: number,
  price: number,
  stage: number,
  createdAt: Date,
  fund: string,
  productId: number,
  shares: string,
}

export interface getPurchaseCountByWeightRes extends BaseCommandProps {
  length: number,
  contractId?: number
}
