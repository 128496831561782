import moment from "moment";

class UtcTime {
  public date;
  constructor() {
    this.date = this.get()
  }

  get = (date?: Date) => {
    return moment(date).subtract(moment(date).utcOffset() / 60, 'hour').toDate()
  }

  getAnotherZone = (gmt: number): Date => {
    return moment(this.date).add((moment(this.date).utcOffset() - gmt) / 60, 'hour').toDate();
  }
}

export default UtcTime