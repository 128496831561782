import { IWebinar } from '@modules/information/webinars/types/information';
import { CommonAction } from '@modules/common/actions';

export const SET_LIST_WEBINARS = 'SET_LIST_WEBINARS';
export const CREATE_OR_UPDATE_WEBINARS = 'CREATE_OR_UPDATE_WEBINARS';
export const REMOVE_WEBINARS = 'REMOVE_WEBINARS';

export const setWebinarsList = CommonAction<IWebinar[]>(SET_LIST_WEBINARS)
export const createOrUpdateWebinar = CommonAction<IWebinar>(CREATE_OR_UPDATE_WEBINARS)
export const removeWebinar = CommonAction<number>(REMOVE_WEBINARS)
