import { BaseCommandProps } from '@app-types/CommonTypes';
import moment from 'moment';
import Tz from '@utils/tzTime';

export interface IDialog {
  dialogId: number,
  ownerId: number,
  users: number[],
  unread: boolean,
  createdAt: Date
  updatedAt: Date
}

export interface HasUnreadDialog extends BaseCommandProps {
  unread: boolean
}

export interface IMessage {
  id: number
  message: string,
  userId: number,
  dialogId: number,
  files: IFile
  createdAt: Date
}

export class Message implements IMessage {
  public createdAt: Date;
  public dialogId: number;
  public files: IFile;
  public id: number;
  public message: string;
  public userId: number;
  constructor(obj: IMessage) {
    this.userId = obj.userId
    this.createdAt = new Date(obj.createdAt)
    this.files = obj.files
    this.id = obj.id
    this.message = obj.message
    this.dialogId = obj.dialogId
  }

  get createTime() {
    return Tz.unix(this.createdAt.getTime() / 1000);
  }
}


export interface IChatReducer {
  unread: boolean,
  dialogs: IDialog[],
  messages: IMessagesDialog,
  files: FileClass[],
}

export interface IMessagesDialog {
  [dialogId: number]: Message[]
}

export interface IMessageDialogPayload {
  dialogId: number,
  messages: Message[]
}

export interface IFile {
  name: string
  size: number,
  url?: string
  createdAt?: Date
}

export class FileClass implements IFile {
  public name: string;
  public size: number;
  public url?: string;
  public createdAt?: Date;

  constructor(obj: IFile) {
    this.name = obj.name
    this.size = obj.size
    this.url = obj.url
    this.createdAt = moment(obj.createdAt).toDate()
  }

  get dateCreate(): string {
    return moment(this.createdAt).format('YYYY-MM-DD')
  }
}

