import { ILang } from '@modules/translate/types/lang';
import { ITranslate, TranslateObjectResSocket } from '@modules/translate/types/translate';
import { CommonAction } from '@modules/common/actions';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';
export const SET_TRANSLATE_LIST = 'SET_TRANSLATE_LIST';
export const SET_CACHE = 'SET_CACHE';
export const UPDATE_TRANSLATE_LIST_SOCKET = 'UPDATE_TRANSLATE_LIST_SOCKET';

export interface SetLanguageAction {
  type: string,
  payload: {
    code: string
  },
}


export interface SetLanguageListAction {
  type: string,
  payload: {
    langs: ILang[]
  },
}

export interface SetTranslateListAction {
  type: string,
  payload: {
    translates: ITranslate
  },
}

export interface SetCachetAction {
  type: string,
  payload: {
    cache: number
  },
}


export type TranslateAction =
  SetLanguageAction
  | SetLanguageListAction
  | SetTranslateListAction
  | SetCachetAction
  | updateTranslateListType;


export const setCache = (cache: number) => ({
  type: SET_CACHE,
  payload: { cache },
});

// Language
export const setLanguage = (code: string) => ({
  type: SET_LANGUAGE,
  payload: { code },
});

export const setLanguageList = (langs: ILang[]) => ({
  type: SET_LANGUAGE_LIST,
  payload: { langs },
});

// Translate

export const setTranslateList = (translates: ITranslate) => ({
  type: SET_TRANSLATE_LIST,
  payload: { translates },
});

export const updateTranslateList = CommonAction<TranslateObjectResSocket>(UPDATE_TRANSLATE_LIST_SOCKET);
export type updateTranslateListType = ReturnType<typeof updateTranslateList>;



