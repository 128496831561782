
export interface IFullStatus {
  id: number,
  userId: number,
  statusId: number,
  params: IStatus
}

export interface IStatus {
  title: string,
  level: number,
}

export class Status implements IStatus {
  public title: string
  public level: number

  constructor(obj: IStatus) {
    this.title = obj.title
    this.level = obj.level
  }

  get isPremier(): boolean {
    return this.level == 2
  }
}
