import { combineReducers } from 'redux';
import { IAccountsReducer, IAccountsUserReducer, UserBalance } from '@modules/accounts/type/account';
import AccountsAdminReducer from '@modules/accounts/reducers/admin';
import AccountsUserReducer from '@modules/accounts/reducers/user';

export interface AccountsCombineReducer {
  admin: IAccountsReducer,
  user: IAccountsUserReducer
}

const AccountsReducer = combineReducers<AccountsCombineReducer>({
  admin: AccountsAdminReducer,
  user: AccountsUserReducer
});

export default AccountsReducer
