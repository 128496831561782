import UtcTime from '@helpers/UtcTime';

export interface ICommonStage {
  id: number,
  availableFrom: Date,
  stage: number,
  price: number,
  token: string,
  productId: number
  createdAt?: Date,
  productTitle?: string,
  categoryId: number
  countLesson: number
  lang: string,
  slotSize: number,

  getPrice(): number
}

export class CommonStage implements ICommonStage {
  public readonly id: number;
  public price: number;
  public stage: number;
  public token: string;
  public createdAt?: Date;
  public availableFrom: Date;
  public productId: number;
  public categoryId: number;
  public countLesson: number;
  public productTitle?: string;
  public lang: string;
  public slotSize: number;

  constructor(obj: ICommonStage) {
    this.id = obj.id;
    this.price = Number(obj.price);
    this.stage = obj.stage;
    this.token = obj.token;
    this.createdAt = obj.createdAt ? new Date(obj.createdAt) : undefined;
    this.productId = obj.productId;
    this.categoryId = obj.categoryId;
    this.countLesson = obj.countLesson;
    this.productTitle = obj.productTitle;
    this.availableFrom = new UtcTime().get(obj.availableFrom);
    this.lang = obj.lang;
    this.slotSize = obj.slotSize;
  }

  getPrice = () => this.price;

  get getDuration(): number {
    return this.slotSize * 30;
  }
}

export interface IStageLesson extends ICommonStage {
  oldPrice: number;
  countLesson: number;
  displayOneLesson: boolean;
  lang: string;
  slotSize: number;
  // DEPRECATED
  lessonDuration: number;
  level: number,
}

export class StageLesson extends CommonStage implements IStageLesson {
  public oldPrice: number;
  public countLesson: number;
  public displayOneLesson: boolean;
  public lessonDuration: number;
  public lang: string;
  public slotSize: number;
  public level: number;


  constructor(obj: IStageLesson) {
    super(obj);
    this.oldPrice = Number(obj.oldPrice);
    this.countLesson = Number(obj.countLesson);
    this.displayOneLesson = obj.displayOneLesson;
    // DEPRECATED
    this.lessonDuration = Number(obj.lessonDuration);
    this.lang = obj.lang;
    this.slotSize = obj.slotSize;
    this.level = obj.level;
  }

  getOnePrice = () => {
    if (this.token === 'RUB') {
      if (this.displayOneLesson) {
        return (this.price / this.countLesson);
      } else {
        return 0;
      }
    }
    if (this.displayOneLesson) {
      return (this.price / this.countLesson);
    }
    return 0;
  };

  getPrice = () => {
    return this.price;
  };

  getOldPrice = () => {
    return this.oldPrice || 0;
  };
}


export type AllStage = StageLesson
export type IAllStage = IStageLesson
