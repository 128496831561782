import { CommonAction } from '@modules/common/actions';
import { StageGoods, StagePretoken, StageShares } from '@modules/investments/products/types/stage';

export const SET_STAGE_SHARES = 'SET_STAGE_SHARES';
export const SET_STAGE_GOODS = 'SET_STAGE_GOODS';
export const SET_STAGE_PRETOKEN = 'SET_STAGE_PRETOKEN';

export const setStageSharesAction = CommonAction<StageShares[]>(SET_STAGE_SHARES)
export const setStageGoodsAction = CommonAction<StageGoods[]>(SET_STAGE_GOODS)
export const setStagePretokenAction = CommonAction<StagePretoken[]>(SET_STAGE_PRETOKEN)

export type setStageSharesType = ReturnType<typeof setStageSharesAction>;
export type setStageGoodsType = ReturnType<typeof setStageGoodsAction>;
export type setStagePretokenType = ReturnType<typeof setStagePretokenAction>;

export type SharesAction = setStageSharesType | setStageGoodsType | setStagePretokenType;


