class StatusError extends Error {
  public status: number;
  public extra?: object;

  constructor(message: string, status: number, extra?: object) {
    super(message);
    this.status = status;
    this.extra = extra;
  }
}

export default StatusError;
