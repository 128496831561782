import { CommonAction } from '@modules/common/actions';
import { BoxTariff } from '@modules/box-tariffs/types';

export const SET_BOX_TARIFFS_LIST = 'SET_BOX_TARIFFS_LIST'
export const CREATE_OR_UPDATE_BOX_TARIFFS = 'CREATE_OR_UPDATE_BOX_TARIFFS'
export const REMOVE_BOX_TARIFFS = 'REMOVE_BOX_TARIFFS'

export const setBoxTariffsListAction = CommonAction<BoxTariff[]>(SET_BOX_TARIFFS_LIST);
export const creteOrUpdateBoxTariffsAction = CommonAction<BoxTariff>(CREATE_OR_UPDATE_BOX_TARIFFS);
export const removeBoxTariffsAction = CommonAction<number>(REMOVE_BOX_TARIFFS);

export type setBoxTariffsListType = ReturnType<typeof setBoxTariffsListAction>;
export type creteOrUpdateBoxTariffsType = ReturnType<typeof creteOrUpdateBoxTariffsAction>;
export type removeReviewActionType = ReturnType<typeof removeBoxTariffsAction>;

export type BoxTariffsActions = setBoxTariffsListType | creteOrUpdateBoxTariffsType | removeReviewActionType
