import {
  EmailTemplateActions,
  setEmailTemplateCreateOrUpdateActionType,
  setEmailTemplateListType,
  ADD_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  SET_EMAIL_TEMPLATE_LIST,
  SET_EMAIL_TEMPLATE_COUNT,
  setEmailTemplateCountType,
  SET_EMAIL_TEMPLATE_REMOVE,
  setEmailTemplateRemoveType,
  SET_EMAIL_TEMPLATE_ITEM,
  setEmailTemplateItemType,
  addEmailTemplateActionType,
  updateEmailTemplateActionType,
} from '@modules/template/actions/template';
import { IEmailTemplateReducer } from '@modules/template/types/index';

const defaultState: IEmailTemplateReducer = {
  list: [],
  count: 0,
  item: undefined
};
const EmailTemplateReducer = (
  state: IEmailTemplateReducer = defaultState,
  action: EmailTemplateActions,
): IEmailTemplateReducer => {
  if (action.type === ADD_EMAIL_TEMPLATE) {
    const { list } = state;
    const item = (action as addEmailTemplateActionType).payload;

    return {
      ...state,
      list: [
        item,
        ...list,
      ],
    };
  }
  if (action.type === UPDATE_EMAIL_TEMPLATE) {
    const { list } = state;
    const item = (action as updateEmailTemplateActionType).payload;
    const index = list.findIndex((i) => i.id === item.id);

    if (!!~index) {
      return {
        ...state,
        list: [
          ...list.slice(0, index),
          item,
          ...list.slice(index + 1),
        ],
      };
    }

    return {
      ...state,
      list: [
        item,
        ...list,
      ],
    };
  }

  if (action.type === SET_EMAIL_TEMPLATE_LIST) {
    const list = (action as setEmailTemplateListType).payload;

    return {
      ...state,
      list,
    };
  }

  if (action.type === SET_EMAIL_TEMPLATE_ITEM) {
    const item = (action as setEmailTemplateItemType).payload;

    return {
      ...state,
      item
    };
  }

  if (action.type === SET_EMAIL_TEMPLATE_COUNT) {
    const count = (action as setEmailTemplateCountType).payload;

    return {
      ...state,
      count,
    };
  }

  if (action.type === SET_EMAIL_TEMPLATE_REMOVE) {
    const { count, list } = state;
    const id = (action as setEmailTemplateRemoveType).payload;
    const index = list.findIndex(i => i.id == id);
    return {
      ...state,
      list: [
        ...list.slice(0, index),
        ...list.slice(index + 1),
      ],
      count: count - 1,
    };
  }


  return state;
};

export default EmailTemplateReducer;
