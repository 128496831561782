import axios from 'axios';
import { isEmpty } from 'lodash';
import { apiCall } from './httpClient';
import { AppDispatch } from '@app-types/appStorageType';
import {
  loadDialogsAction,
  loadFilesAction,
  loadMessageAction,
  chatSetUnreadAction,
  readDialogAction,
} from '@modules/chat/actions';
import { FileClass, IDialog, IFile, IMessage, Message, HasUnreadDialog } from '@modules/chat/types';
import { ResultItemRes, ResultListRes } from '@modules/apiClient/type';

export const loadDialogs = () => (dispatch: AppDispatch<ResultListRes<IDialog[]>>) => {
  dispatch(apiCall<object, ResultListRes<IDialog[]>>(`/v1/chat/dialogs/get`, undefined, { method: 'get' }))
    .then(({ list }) => {
      dispatch(loadDialogsAction(list));
    });
};

export const getDialogId = (fromId: number, toId: number) => (dispatch: AppDispatch<ResultListRes<IDialog>>) =>
  dispatch(apiCall<object, ResultItemRes<number>>(`/v1/chat/dialogs/get-dialogs-participants`, { ids: [fromId, toId] }, { method: 'post' }))
    .then(({ item }) => item);

export const loadMessage = (dialogId: number, offset: number) => (dispatch: AppDispatch<ResultListRes<IMessage[]>>) => {
  return dispatch(apiCall<object, ResultListRes<IMessage[]>>(`/v1/chat/message/list`, {
    dialogId,
    offset,
  }, { method: 'post' }))
    .then(({ list }) => {
      const items = list.reverse().map(i => new Message(i));
      dispatch(loadMessageAction({ dialogId, messages: items }));
      dispatch(readDialogAction(dialogId));
    });
};

export const loadMessageFiles = (dialogId: number) => (dispatch: AppDispatch<ResultListRes<IMessage[]>>) => {
  return dispatch(apiCall<object, ResultListRes<IMessage[]>>(`/v1/chat/message/list-files`, {
    dialogId,
  }, { method: 'post' }))
    .then(({ list }) => {
      const items = list.map(i => new FileClass({
        name: i.files.name,
        size: i.files.size,
        url: i.files.url,
        createdAt: i.createdAt,
      }));
      dispatch(loadFilesAction(items));
    });
};

export const sendMessage = (dialogId: number, message: string, files?: IFile) => (dispatch: AppDispatch<ResultListRes<IMessage[]>>) => {
  const file = isEmpty(files) ? null : files;
  return dispatch(apiCall<object, ResultListRes<IMessage[]>>(`/v1/chat/message/send`, {
    dialogId,
    message,
    files: file,
  }, { method: 'post' }));
};

export const hasUnreadDialog = () => (dispatch: AppDispatch<HasUnreadDialog>) =>
  dispatch(apiCall<object, HasUnreadDialog>(`/v1/chat/message/has-unread`))
    .then(({ unread }) => unread && dispatch(chatSetUnreadAction()));

export const readDialog = (dialogId: number) => (dispatch: AppDispatch<HasUnreadDialog>) =>
  dispatch(apiCall<object, HasUnreadDialog>(`/v1/chat/message/read`, { dialogId }))
    .then(() => dispatch(readDialogAction(dialogId)));

export const fileUploader = (files: FileList, path: string = '') => async (dispatch: AppDispatch<ResultListRes<IMessage[]>>) => {
  const file = files[0];

  const formData = new FormData();

  const name = file.name;
  const size = file.size;
  formData.append(
    'file',
    file,
    name,
  );

  formData.set('path', path);


  const key = await axios.post(`${process.env.NEXT_PUBLIC_API_URL_CLIENT}/v1/file/upload`, formData)
    .then((res) => {
      const { data } = res;
      if (data) {
        const { key } = data;
        return key;
      }
    });

  let payload: IFile = {
    size,
    name,
    url: key,
  };

  return payload;
};


export const imageUploader = (files: FileList, path: string = '') => async (dispatch: AppDispatch<ResultListRes<IMessage[]>>) => {
  const file = files[0];

  const formData = new FormData();

  const name = file.name;
  formData.append(
    'file',
    file,
    name,
  );

  formData.set('path', path);

  const key = await axios.post(`${process.env.NEXT_PUBLIC_API_URL_CLIENT}/v1/image/image-upload`, formData)
    .then((res) => {
      const { data } = res;
      if (data) {
        const { key: value } = data;
        return value;
      }
    });

  return key;
};


