import { ContextStorageReducer } from '@modules/context-storage/types';
import {
  ContextStorageActions,
  CREATE_CONTEXT,
  createContextType, REMOVE_CONTEXT, removeContextType,
  SET_CONTEXT_STORAGE,
  setContextStorageType,
  UPDATE_CONTEXT,
  updateContextType,
} from '@modules/context-storage/actions';

const defaultState: ContextStorageReducer = {};

const ContextStorageReducerMethod = (
  state: ContextStorageReducer = defaultState,
  action: ContextStorageActions,
): ContextStorageReducer => {
  if (action.type === SET_CONTEXT_STORAGE) {
    const { context, list } = (action as setContextStorageType).payload;
    return <ContextStorageReducer>{
      ...state,
      [context]: list || [],
    };
  }

  if (action.type === CREATE_CONTEXT) {
    const item = (action as createContextType).payload;
    return <ContextStorageReducer>{
      ...state,
      [item.context]: [
        item,
        ...state[item.context],
      ],
    };
  }

  if (action.type === UPDATE_CONTEXT) {
    const item = (action as updateContextType).payload;

    const index = state[item.context].findIndex(i => i.id === item.id);

    return <ContextStorageReducer>{
      ...state,
      [item.context]: [
        ...state[item.context].slice(0, index),
        item,
        ...state[item.context].slice(index + 1),
      ],
    };
  }

  if (action.type === REMOVE_CONTEXT) {
    const item = (action as removeContextType).payload;

    const index = state[item.context].findIndex(i => i.id === item.id);

    return <ContextStorageReducer>{
      ...state,
      [item.context]: [
        ...state[item.context].slice(0, index),
        ...state[item.context].slice(index + 1),
      ],
    };
  }

  return state;
};

export default ContextStorageReducerMethod;
