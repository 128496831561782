import { loadServerTranslates } from '@commands/translate';
import { ResultListRes } from '@modules/apiClient/type';
import IUser from '@modules/auth/types/Users';
import { ITranslate } from '@modules/translate/types/translate';
import App, { AppContext } from 'next/app';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const serverTranslates = async (lang: string, props: AppContext, user?: IUser, isPublic: boolean = false): Promise<ResultListRes<ITranslate>> => {
  const pageProps = await App.getInitialProps(props);
  pageProps.pageProps.isAdmin = user?.role === 'admin';
  pageProps.pageProps.user = user;
  pageProps.pageProps.userId = user?.id;
  pageProps.pageProps.isPublic = isPublic;

  const WithAppContext: any = props.AppTree;
  const test = ReactDOMServer.renderToString(
    <WithAppContext {...pageProps} />,
  );
  let keys: string[] = ['currency.slug', 'selectText3'];
  const res = test.match(/____([a-zA-Z0-9\.\_\-]+)____/gmi);
  if (!!res && res.length) {
    for (let i = 0; i < res.length; i++) {
      const tVal = res[i];
      const key = /____([a-zA-Z0-9\.\_\-]+)____/gmi.exec(tVal);
      if (key) {
        keys.push(key[1]);
      }
    }
  }
  const { list } = await loadServerTranslates(lang, keys);
  return list as ResultListRes<ITranslate>;
}

export default serverTranslates;