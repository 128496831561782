import { CREATE_OR_UPDATE_WEBINARS, REMOVE_WEBINARS, SET_LIST_WEBINARS } from '@modules/information/webinars/actions';
import {
  CreateOrUpdateWebinarType, IWebinar, RemoveWebinarType,
  SetWebinarsListType,
  WebinarsAction,
} from '@modules/information/webinars/types/information';

const WebinarReducer = (
  state: IWebinar[] = [],
  action: WebinarsAction,
  ): IWebinar[] => {
    if (action.type === SET_LIST_WEBINARS) {
      return (action as SetWebinarsListType).payload
    }

    if (action.type === CREATE_OR_UPDATE_WEBINARS) {
      const item = (action as CreateOrUpdateWebinarType).payload;
      const index = state.findIndex(i => i.id === item.id);

      if (!!~index) {
        return [
          ...state.slice(0, index),
          item,
          ...state.slice(index + 1),
        ]
      }

      return [
        item,
        ...state,
      ]
    }

    if (action.type === REMOVE_WEBINARS) {
      const item = (action as RemoveWebinarType).payload
      const index = state.findIndex(i => i.id === item)

      return [
        ...state.slice(0, index),
        ...state.slice(index + 1),
      ]
    }

    return state;
  }
;

export default WebinarReducer;
