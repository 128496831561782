import { CommonAction } from '@modules/common/actions';
import { IWikipediaItems, IWikipediaSections } from "@modules/information/wikipedia/types";

export const ADD_SECTION = 'ADD_SECTION';
export const SET_WIKI_ITEM = 'SET_WIKI_ITEM';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SHOW_ITEMS = 'SHOW_ITEMS';
export const SHOW_TREE = 'SHOW_TREE';
export const REFRESH_LAST_SECT = 'REFRESH_LAST_SECT';
export const LOAD_ITEMS_IN_SECTION = 'LOAD_ITEMS_IN_SECTION';
export const SHOW_SECTION = 'SHOW_SECTION';

export type AddSectionAction = ReturnType<typeof addSectionAction>;
export type AddItemAction = ReturnType<typeof addItemAction>;
export type ShowTreeAction = ReturnType<typeof showTreeAction>;
export type ShowItemsList = ReturnType<typeof showItemsList>;
export type RemoveSectionAction = ReturnType<typeof removeSectionAction>;
export type RemoveItemAction = ReturnType<typeof removeItemAction>;
export type RefreshLastSectionAction = ReturnType<typeof refreshLastSectionAction>
export type LoadItemsInSectionAction = ReturnType<typeof loadItemsInSectionAction>
export type ShowSectionList = ReturnType<typeof showSectionList>;
export type setWikiItemType = ReturnType<typeof setWikiItem>;

export type WikipediaAction = setWikiItemType | LoadItemsInSectionAction | AddSectionAction | ShowTreeAction | AddItemAction | RemoveSectionAction | RemoveItemAction | ShowItemsList | RefreshLastSectionAction | ShowSectionList;

export const addSectionAction = CommonAction<IWikipediaSections>(ADD_SECTION);
export const setWikiItem = CommonAction<IWikipediaItems>(SET_WIKI_ITEM);
export const addItemAction = CommonAction<IWikipediaItems>(ADD_ITEM);
export const showTreeAction = CommonAction<IWikipediaSections[]>(SHOW_TREE);
export const showItemsList = CommonAction<IWikipediaItems[]>(SHOW_ITEMS);
export const removeSectionAction = CommonAction<number[]>(REMOVE_SECTION);
export const removeItemAction = CommonAction<number[]>(REMOVE_ITEM);
export const refreshLastSectionAction = CommonAction<IWikipediaSections>(REFRESH_LAST_SECT);
export const loadItemsInSectionAction = CommonAction<IWikipediaItems[]>(LOAD_ITEMS_IN_SECTION);
export const showSectionList = CommonAction<IWikipediaSections[]>(SHOW_SECTION);


