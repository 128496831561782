import { combineReducers } from 'redux';
import ProductReducer from '@modules/investments/products/reducers';
import { IProductReducer } from '@modules/investments/products/types/product';
import { InvestmentsCategory } from '@modules/investments/category/types/category';
import InvestmentCategoryMethod, {
  CategoryMethodReducer, ICategoryMethod,
} from '@modules/investments/category/reducers';
import { IPurchaseReducer } from '@modules/investments/purchases/types/purchaseReducer';
import PurchaseReducerMethod from '@modules/investments/purchases/reducers';

export interface CombineInvestmentsReducer {
  products: IProductReducer
  category: InvestmentsCategory[],
  current: ICategoryMethod,
  purchase: IPurchaseReducer
}

export const InvestmentsReducer = combineReducers<CombineInvestmentsReducer>({
  products: ProductReducer,
  category: InvestmentCategoryMethod,
  current: CategoryMethodReducer,
  purchase: PurchaseReducerMethod
});
