import { CommonAction } from '@modules/common/actions';
import { BaseUser } from '@modules/user/types';

export const SET_USERS = 'SET_USERS';
export const SET_USER = 'SET_USER';
export const SET_STUDENTS = 'SET_STUDENTS';
export const APPEND_USER = 'APPEND_USER';
export const TEACHERS_COUNT = 'TEACHERS_COUNT';
export const USERS_COUNT = 'USERS_COUNT';
export const CLEAR_TEACHERS = 'CLEAR_TEACHERS';

export const setUsersAction = CommonAction<BaseUser[]>(SET_USERS);
export const setUserAction = CommonAction<BaseUser>(SET_USER);
export const setStudentsAction = CommonAction<BaseUser[]>(SET_STUDENTS);
export const appendUserAction = CommonAction<BaseUser>(APPEND_USER);
export const teachersCountAction = CommonAction<number>(TEACHERS_COUNT);
export const usersCountAction = CommonAction<number>(USERS_COUNT);
export const clearTeachersAction = CommonAction<void>(CLEAR_TEACHERS);

export type setUsersType = ReturnType<typeof setUsersAction>;
export type setUserType = ReturnType<typeof setUserAction>;
export type appendUserType = ReturnType<typeof appendUserAction>;
export type teacherCountType = ReturnType<typeof teachersCountAction>;
export type usersCountType = ReturnType<typeof usersCountAction>;
export type setStudentsActionType = ReturnType<typeof setStudentsAction>;
export type clearTeachersActionType = ReturnType<typeof clearTeachersAction>;

export type UsersActions = clearTeachersActionType | setUsersType | setUserType | appendUserType | teacherCountType | usersCountType | setStudentsActionType;
