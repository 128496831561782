import { News } from '@modules/information/news/types/news';
import { CommonAction } from '@modules/common/actions';
export const SET_NEWS = 'SET_NEWS';
export const SET_NEWS_ITEM = 'SET_NEWS_ITEM';
export const ADD_NEWS = 'ADD_NEWS';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const REMOVE_NEWS = 'REMOVE_NEWS';
export const GET_NEWS_COUNT = 'GET_NEWS_COUNT';
export const SET_ADMIN_NEWS = 'SET_ADMIN_NEWS';

export const setNewsList = CommonAction<News[]>(SET_NEWS);
export const setNewsItem = CommonAction<News>(SET_NEWS_ITEM);
export const createNewsAction = CommonAction<News>(ADD_NEWS);
export const updateNewsAction = CommonAction<News>(UPDATE_NEWS);
export const removeNews = CommonAction<number>(REMOVE_NEWS);
export const getNewsCount = CommonAction<number>(GET_NEWS_COUNT);
export const setAdminNews = CommonAction<News[]>(SET_ADMIN_NEWS);
