import EmailLayoutReducer from '@modules/template/reducers/layout';
import EmailTemplateReducer from '@modules/template/reducers/template';
import { ICombineEmailReducer } from '@modules/template/types/index';
import { combineReducers } from 'redux';

const CombineEmailReducer = combineReducers<ICombineEmailReducer>({
  template: EmailTemplateReducer,
  layout: EmailLayoutReducer,
});

export default CombineEmailReducer;

