import { IStageReducer } from '@modules/stages/types';
import {
  SET_STAGE_PRETOKEN,
  SET_STAGE_SHARES,
  setStagePretokenType,
  setStageSharesType,
  SharesAction,
} from '@modules/stages/actions';

const defaultSettings: IStageReducer = {
  shares: [],
  goods: [],
  pretoken: []
};

const StagesReducer = (
  state: IStageReducer = defaultSettings,
  action: SharesAction,
): IStageReducer => {
  if (action.type === SET_STAGE_SHARES) {
    return {
      ...state,
      shares: (action as setStageSharesType).payload,
    };
  }

  if (action.type === SET_STAGE_PRETOKEN) {
    return {
      ...state,
      pretoken: (action as setStagePretokenType).payload,
    };
  }

  return state;
};

export default StagesReducer;
