import { CommonAction } from '@modules/common/actions';
import { IBlog } from '@modules/information/blog/types';

export const SET_BLOG_LIST = 'SET_BLOG_LIST';
export const SET_BLOG_ITEM = 'SET_BLOG_ITEM';
export const CREATE_BLOG = 'CREATE_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const SET_BLOG_COUNT = 'SET_BLOG_COUNT';

export const setBlogListAction = CommonAction<IBlog[]>(SET_BLOG_LIST);
export const setBlogItemAction = CommonAction<IBlog>(SET_BLOG_ITEM);
export const createBlogAction = CommonAction<IBlog>(CREATE_BLOG);
export const updateBlogAction = CommonAction<IBlog>(UPDATE_BLOG);
export const deleteBlogAction = CommonAction<number>(DELETE_BLOG);
export const setBlogCountAction = CommonAction<number>(SET_BLOG_COUNT);

export type SetBlogListType = ReturnType<typeof setBlogListAction>;
export type SetBlogItemType = ReturnType<typeof setBlogItemAction>;
export type CreateBlogType = ReturnType<typeof createBlogAction>;
export type UpdateBlogType = ReturnType<typeof updateBlogAction>;
export type DeleteBlogType = ReturnType<typeof deleteBlogAction>;
export type SetBlogCountType = ReturnType<typeof setBlogCountAction>;

export type BlogActions =
  SetBlogListType |
  SetBlogItemType |
  CreateBlogType |
  UpdateBlogType |
  DeleteBlogType |
  SetBlogCountType;