import { CommonAction } from '@modules/common/actions';
import { FileClass, IDialog, IMessageDialogPayload, Message } from '@modules/chat/types';

export const LOAD_MESSAGE = 'LOAD_MESSAGE';
export const LOAD_FILES = 'LOAD_FILES';
export const LOAD_DIALOGS = 'LOAD_DIALOGS';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const CHAT_SET_UNREAD = 'CHAT_SET_UNREAD';
export const CHAT_READ_DIALOG = 'CHAT_READ_DIALOG';

export const loadMessageAction = CommonAction<IMessageDialogPayload>(LOAD_MESSAGE);
export const loadFilesAction = CommonAction<FileClass[]>(LOAD_FILES);
export const newMessageAction = CommonAction<Message>(NEW_MESSAGE);
export const loadDialogsAction = CommonAction<IDialog[]>(LOAD_DIALOGS);
export const chatSetUnreadAction = CommonAction<void>(CHAT_SET_UNREAD);
export const readDialogAction = CommonAction<number>(CHAT_READ_DIALOG);

export type loadMessageType = ReturnType<typeof loadMessageAction>;
export type loadFilesType = ReturnType<typeof loadFilesAction>;
export type loadDialogsType = ReturnType<typeof loadDialogsAction>;
export type newMessageType = ReturnType<typeof newMessageAction>;
export type chatSetUnreadType = ReturnType<typeof chatSetUnreadAction>;
export type readDialogType = ReturnType<typeof readDialogAction>;

export type ChatActions = readDialogType | chatSetUnreadType | loadMessageType | loadDialogsType | newMessageType | loadFilesType
