import { hasUnreadDialog } from '@commands/chat';
import { CommonApp } from '@modules/common/models';
import { AppStorageType } from '@app-types/appStorageType';
import { loadStatusUser } from '@commands/status';
import { loadDocument } from '@commands/auth';
import { loadInterestsPop } from '@commands/personal';
import { loadScheduleStatistic } from '@commands/schedule';

export class ClientApp extends CommonApp<AppStorageType> {
  init = async () => {
    this.dispatch(loadStatusUser())
    this.dispatch(loadDocument())
    this.dispatch(loadScheduleStatistic())
    this.dispatch(loadInterestsPop())
    this.dispatch(hasUnreadDialog())
    // this.dispatch(loadUserStatus());
  }
}
