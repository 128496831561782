import { apiCall } from './httpClient';
import { AppDispatch, AppStorageType } from '@app-types/appStorageType';
import {
  ExportTranslateRes,
  ILang,
  LangObjectEditRes,
  LangObjectRes,
} from '@modules/translate/types/lang';
import {
  setCache, setTranslateList,
} from '@modules/translate/actions';
import { ITranslate, TranslateObjectReq, TransObjectRes } from '@modules/translate/types/translate';
import { CacheObjectRes } from '@modules/translate/types/cache';
import {
  ITranslateListAdmin,
  TranslateListAdminObjectRes, TranslateListItemAdminObjectRes,
} from '@modules/translate/types/translateListAdmin';
import saveFile from '../helpers/saveFile';
import {
  createLangItem,
  createTranslateListItemAdmin, removeTranslateItem,
  setLanguageListAdmin, setTranslateCount,
  setTranslateListAdmin, updateLangItem,
  updateTranslateListItemAdmin,
} from '@modules/translate/actions/actionAdmin';
import { BaseCommandProps } from '@app-types/CommonTypes';
import { ResultItemRes, ResultListRes } from '@modules/apiClient/type';
import httpServer from '@commands/httpServer';
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
import debounce from 'lodash/debounce';
import { parseCookies } from 'nookies';

export const loadCache = () => (dispatch: AppDispatch<CacheObjectRes>) => {
  dispatch(apiCall<object, CacheObjectRes>('/v1/translate/cache', undefined, { method: 'get', context: 'translate' }))
    .then((res) => dispatch(setCache(res?.cache)));
};

export const loadTranslateAdmin = (currentPage: number, pageSize: number) => (dispatch: AppDispatch<TranslateListAdminObjectRes>, getState: () => AppStorageType) => {
  dispatch(loadLangsListWithSource()).then(() => {
    const state: AppStorageType = getState();
    const { source } = state.translate.admin;
    dispatch(apiCall<object, TranslateListAdminObjectRes>('/v1/translate/translate-admin', { currentPage, pageSize }, {
      method: 'post',
      baseURL: source.current,
    }))
      .then(({ list, count }) => {
        dispatch(setTranslateListAdmin(list));
        dispatch(setTranslateCount(count));
      });
  });
};

export const loadLangsListWithSource = () => (dispatch: AppDispatch<LangObjectRes>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const { source } = state.translate.admin;
  return dispatch(apiCall<object, LangObjectRes>('/v1/translate/lang', undefined, {
    method: 'get',
    baseURL: source.current,
  }))
    .then((res) => dispatch(setLanguageListAdmin(res?.list)));
};

export const createOrUpdateTranslate = (value: TranslateObjectReq) => (dispatch: AppDispatch<TranslateListItemAdminObjectRes>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const { source } = state.translate.admin;

  if (value.id) {
    return dispatch(apiCall<TranslateObjectReq, TranslateListItemAdminObjectRes>('/v1/translate/translate-update', value, {
      method: 'put',
      baseURL: source.current,
    }))
      .then((res) => dispatch(updateTranslateListItemAdmin(value)));
  }
  return dispatch(apiCall<TranslateObjectReq, TranslateListItemAdminObjectRes>('/v1/translate/translate', value, {
    method: 'post',
    baseURL: source.current,
  }))
    .then((res) => dispatch(createTranslateListItemAdmin(res.item)));
};

export const createOrUpdateLang = (value: ILang) => (dispatch: AppDispatch<LangObjectEditRes>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const { source } = state.translate.admin;

  if (value.id) {
    return dispatch(apiCall<ILang, LangObjectEditRes>('/v1/translate/lang-update', value, {
      method: 'put',
      baseURL: source.current,
    }))
      .then((res) => dispatch(updateLangItem(value)));
  }
  return dispatch(apiCall<ILang, LangObjectEditRes>('/v1/translate/lang-create', value, {
    method: 'post',
    baseURL: source.current,
  }))
    .then((res: LangObjectEditRes) => dispatch(createLangItem(res.lang)));
};

export const importTranslate = (value: object) => (dispatch: AppDispatch<BaseCommandProps>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const { source } = state.translate.admin;
  dispatch(apiCall<object, BaseCommandProps>('/v1/translate/translate-import', value, {
    method: 'post',
    baseURL: source.current,
  }));
};

export const exportTranslate = () => (dispatch: AppDispatch<ExportTranslateRes>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const { admin } = state.translate;
  dispatch(apiCall<object, ExportTranslateRes>('/v1/translate/translate-export', admin.export, {
    method: 'post',
    isFile: true,
    baseURL: admin.source.current,
  }))
    .then(({ list }) => {
      const data = JSON.stringify(list);
      saveFile(data, 'i18n.json', 'application/json');
    });
};


export const searchTranslate = (value: string, currentPage: number, pageSize: number) => (dispatch: AppDispatch<TranslateListAdminObjectRes>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const { source } = state.translate.admin;

  dispatch(apiCall<object, TranslateListAdminObjectRes>('/v1/translate/translate-search', {
    query: value,
    currentPage,
    pageSize,
  }, {
    method: 'post',
    baseURL: source.current,
  }))
    .then(({ list, count }) => {
      dispatch(setTranslateListAdmin(list));
      dispatch(setTranslateCount(count));
    });
};

export const removeTranslate = (value: number) => (dispatch: AppDispatch<BaseCommandProps>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const { source } = state.translate.admin;

  return dispatch(apiCall<object, BaseCommandProps>('/v1/translate/translate-remove', { id: value }, {
    method: 'post',
    baseURL: source.current,
  }))
    .then((res) => dispatch(removeTranslateItem(value)));
};

export const saveLanguageUser = (code: string) => (dispatch: AppDispatch<BaseCommandProps>) => dispatch(apiCall<object, BaseCommandProps>('/v1/user/save-language-user', { code }, { method: 'post' }));

export const loadTransInputValue = (key: string) => (dispatch: AppDispatch<object>) =>
  dispatch(apiCall<object, TransObjectRes>(`/v1/translate/input/${key}`, undefined, { method: 'get' }))
    .then((res: TransObjectRes) => res.values);

export const getTranslateByKey = (key: string) => (dispatch: AppDispatch<ResultItemRes<ITranslateListAdmin>>) =>
  dispatch(apiCall<object, ResultItemRes<ITranslateListAdmin>>(`/v1/translate/get-by-key/${key}`, undefined, { method: 'get' }))
    .then(({ item }) => item);

export const exportTranslateAll = () => (dispatch: AppDispatch<object>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const { source } = state.translate.admin;

  dispatch(apiCall<object, any>('/v1/translate/translateExportAll', undefined, {
    method: 'get',
    isFile: true,
    baseURL: source.current,
  }))
    .then((res) => {
      const data = JSON.stringify(res);
      saveFile(data, 'i18n.json', 'application/json');
    });
};


const keys: Set<string> = new Set<string>();
const keysInProgress: Set<string> = new Set<string>();

export const loadListBySlug = debounce((dispatch: AppDispatch<object>, getCode: () => string) => {
  keys.forEach(i => keysInProgress.add(i))

  dispatch(apiCall<object, ResultListRes<ITranslate>>(
    '/v1/translate/list-by-slugs', {
      code: getCode(),
      keys: Array.from(keysInProgress),
    },
    { method: 'post' },
  )).then(({ list }) => {
    dispatch(setTranslateList(list));

    const loadedKeys = Object.keys(list);
    forEach(loadedKeys, i => {
      if (keysInProgress.has(i)) {
        keysInProgress.delete(i)
      }

      if (keys.has(i)) {
        keys.delete(i)
      }
    });
  });
}, 200);


export const loadServerListBySlug = async (code: string, keysTwo: string[] = [], isServer: boolean = true) => {
  if (Array.from(keys).length == 0 && keysTwo.length == 0) {
    return Promise.resolve({ list: {} });
  }

  const keysValue = uniq([...Array.from(keys), ...keysTwo]);
  return await httpServer<object, ResultListRes<ITranslate>>(
    '',
    '/v1/translate/list-by-slugs', {
      code,
      keys: keysValue,
    },
    { method: 'post' },
    isServer,
  );
};


export const loadTranslates = (key: string) => (dispatch: AppDispatch<object>, getState: () => AppStorageType) => {
  if (keys.has(key)) {
    return;
  }

  keys.add(key);

  if (typeof window === 'object') {
    loadListBySlug(dispatch, () => {
      const { langValue: _lang } = parseCookies();

      let lang = _lang;
      if (typeof window === 'object') {
        const state: AppStorageType = getState();
        const { currentLanguage } = state.translate.user;
        lang = currentLanguage
      }

      return lang;
    });
  }
};

export const loadServerTranslates = async (lang: string, keys: string[] = [], isServer: boolean = true) => await loadServerListBySlug(lang, keys, isServer);
