import { InvestmentsCategory, IInvestmentsCategory } from '@modules/investments/category/types/category';

export interface IBoxTariff {
  id: number,
  title: string,
  description: string,
  langs: string[],
  sort: number,
  categoryId: number,
  subcategory: string,
  category: IInvestmentsCategory,
  deleted: boolean
  displayCard: boolean
  createdAt: Date,
}

export class BoxTariff implements IBoxTariff {
  public id: number;
  public title: string;
  public displayCard: boolean;
  public deleted: boolean;
  public description: string;
  public sort: number;
  public subcategory: string;
  public langs: string[];
  public categoryId: number;
  public category: InvestmentsCategory;
  public createdAt: Date;

  constructor(obj: IBoxTariff) {
    this.id = obj.id;
    this.title = obj.title;
    this.description = obj.description;
    this.langs = obj.langs;
    this.categoryId = obj.categoryId;
    this.subcategory = obj.subcategory;
    this.sort = obj.sort;
    this.displayCard = obj.displayCard;
    this.deleted = obj.deleted;
    this.createdAt = obj.createdAt;
    this.category = obj.category && new InvestmentsCategory(obj.category);
  }
}
