import { createStore, applyMiddleware, AnyAction } from 'redux';
import rootReducer from './index';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { AppStorageType } from '@app-types/appStorageType';
const loggerMiddleware = createLogger();
const initialState = {};
import { rootSaga } from '../sagas';
import createSagaMiddleware from 'redux-saga';
import { AuthContext } from '@contexts/auth';

let middleware;

const sagaMiddleware = createSagaMiddleware({
  context: {
    AuthContext
  }
});

if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(thunkMiddleware, sagaMiddleware);
} else {
  middleware = applyMiddleware(thunkMiddleware, loggerMiddleware, sagaMiddleware);
}

export default createStore<AppStorageType, AnyAction, {}, {}>(rootReducer, initialState as AppStorageType, middleware);

sagaMiddleware.run(rootSaga);
