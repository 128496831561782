import {
  SET_COUNT_TRANSACTION,
  SET_TRANSACTIONS_LIST, setCountTransactionType,
  setTransactionsListType,
  TransactionsAction,
  UPDATE_TRANSACTIONS, updateTransactionsType,
} from '@modules/transactions/actions';
import { IReducerTransaction } from '@modules/transactions/types/reducer';


const TransactionsReducer = (
  state: IReducerTransaction = {
    count: 0,
    list: {},
  },
  action: TransactionsAction,
): IReducerTransaction => {
  if (action.type === SET_TRANSACTIONS_LIST) {
    const { account, token, list } = (action as setTransactionsListType).payload;
    return <IReducerTransaction>{
      ...state,
      list: {
        [account!]: {
          [token!]: list,
        },
      },
    };
  }

  if (action.type === UPDATE_TRANSACTIONS) {
    const { list } = state;
    const { token, account, item } = (action as updateTransactionsType).payload;
    const oldList = list[account] && list[account][token] || [];

    return <IReducerTransaction>{
      ...state,
      list: {
        ...list,
        [account]: {
          [token]: [
            item,
            ...oldList,
          ],
        },
      },
    };
  }

  if (action.type === SET_COUNT_TRANSACTION) {
    return {
      ...state,
      count: (action as setCountTransactionType).payload,
    };
  }

  return state;
};

export default TransactionsReducer;
