import { CommonAction } from '@modules/common/actions';
import { InvestmentsCategory } from '@modules/investments/category/types/category';

export const SET_CATEGORY = 'SET_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';
export const SET_INVESTMENTS_CATEGORY = 'SET_INVESTMENTS_CATEGORY';
export const CREATE_OR_UPDATE_CATEGORY = 'CREATE_OR_UPDATE_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';

export const setInvestmentsCategoryAction = CommonAction<InvestmentsCategory[]>(SET_INVESTMENTS_CATEGORY);
export const setCategoryAction = CommonAction<InvestmentsCategory>(SET_CATEGORY);
export const clearCategoryAction = CommonAction<void>(CLEAR_CATEGORY);
export const addCategoryAction = CommonAction<InvestmentsCategory>(CREATE_OR_UPDATE_CATEGORY);
export const removeCategoryAction = CommonAction<number>(REMOVE_CATEGORY);
