import { Subject } from 'rxjs';
import { CommonReducer, ContextType, ILoadingEvent, INotifMessage } from '@modules/common/types';
import { CommonAction, ON_USER_DATA_CHANGED, onUserDataChangedType } from '@modules/common/actions';
import { User } from '@modules/auth/types/Users';
import { CATCH_ERROR, SET_LOADING_CONTEXT, REMOVE_LOADING_CONTEXT, SUCCESS_MESSAGE } from '@modules/common/actions';

const CommonReducerMethod = (
  state: CommonReducer = {
    contexts: {} as ContextType,
    error: new Subject<INotifMessage>(),
    success: new Subject<INotifMessage>(),
    loading: new Subject<ILoadingEvent>(),
    onUserChanged: new Subject<User>(),
  },
  action: CommonAction,
) => {
  if (action.type === CATCH_ERROR) {
    state.error.next(action.payload as INotifMessage);
  }

  if (action.type === SUCCESS_MESSAGE) {
    state.success.next(action.payload as INotifMessage);
  }

  if (action.type === SET_LOADING_CONTEXT) {
    const context = action.payload as string;
    const size = (state.contexts[context] || 0) + 1;

    state.loading.next({
      kind: 'set',
      context,
      size,
    });

    return {
      ...state,
      contexts: {
        ...state.contexts,
        [context]: size,
      }
    };
  }

  if (action.type === REMOVE_LOADING_CONTEXT) {
    const context = action.payload as string;
    const size = Math.max((state.contexts[context] || 0) - 1, 0);

    state.loading.next({
      kind: 'remove',
      context,
      size,
    });

    return {
      ...state,
      contexts: {
        ...state.contexts,
        [context]: size,
      }
    };
  }

  if (action.type === ON_USER_DATA_CHANGED) {
    state.onUserChanged.next((action as onUserDataChangedType).payload)
  }

  return state;
};

export default CommonReducerMethod;
