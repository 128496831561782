import {
  EmailLayoutActions,
  SET_EMAIL_LAYOUT_CREATE_OR_UPDATE,
  setEmailLayoutListType,
  SET_EMAIL_LAYOUT_LIST,
  SET_EMAIL_LAYOUT_COUNT,
  setEmailLayoutCountType,
  SET_EMAIL_LAYOUT_REMOVE,
  setEmailLayoutRemoveType, setEmailLayoutCreateOrUpdateType,
} from '@modules/template/actions/layout';
import { IEmailLayoutReducer } from '@modules/template/types/index';

const defaultState: IEmailLayoutReducer = {
  list: [],
  count: 0,
};
const EmailLayoutReducer = (
  state: IEmailLayoutReducer = defaultState,
  action: EmailLayoutActions,
): IEmailLayoutReducer => {
  if (action.type === SET_EMAIL_LAYOUT_CREATE_OR_UPDATE) {
    const { list } = state;
    const item = (action as setEmailLayoutCreateOrUpdateType).payload;
    const index = list.findIndex((i) => i.id === item.id);

    if (!!~index) {
      return {
        ...state,
        list: [
          ...list.slice(0, index),
          item,
          ...list.slice(index + 1),
        ],
      };
    }

    return {
      ...state,
      list: [
        item,
        ...list,
      ],
    };
  }

  if (action.type === SET_EMAIL_LAYOUT_LIST) {
    const list = (action as setEmailLayoutListType).payload;

    return {
      ...state,
      list,
    };
  }

  if (action.type === SET_EMAIL_LAYOUT_COUNT) {
    const count = (action as setEmailLayoutCountType).payload;

    return {
      ...state,
      count,
    };
  }

  if (action.type === SET_EMAIL_LAYOUT_REMOVE) {
    const { count, list } = state;
    const id = (action as setEmailLayoutRemoveType).payload;
    const index = list.findIndex(i => i.id == id);
    return {
      ...state,
      list: [
        ...list.slice(0, index),
        ...list.slice(index + 1),
      ],
      count: count - 1,
    };
  }


  return state;
};

export default EmailLayoutReducer;
