import { BoxTariff, IBoxTariff } from '@modules/box-tariffs/types/index';
import { IInvestmentsCategory, InvestmentsCategory } from '@modules/investments/category/types/category';
import {
  setLessonForPurchase, setProductsListAdminAction,
  setProductsListLesson,
} from '../actions/index';
import {
  IStageLesson,
  StageLesson,
} from '@modules/investments/products/types/stage';

export interface IProduct {
  id: number,
  title: string,
  url: string,
  description: string,
  sort: number,
  show: boolean,
  companyId: number,
  categoryId: number,
  subjectId: number,
  extra: IProductExtra,
  deleted: boolean,
  category: IInvestmentsCategory,
  subject?: IBoxTariff;
  stages?: IStageLesson[]
}

export interface IProductExtra {
  handler: string;
}

export class Product implements IProduct {
  public readonly id: number;
  public url: string;
  public description: string;
  public show: boolean;
  public sort: number;
  public title: string;
  public deleted: boolean;
  public extra: IProductExtra;
  public companyId: number;
  public categoryId: number;
  public subjectId: number;
  public subject?: BoxTariff;

  constructor(obj: IProduct) {
    this.id = obj.id;
    this.url = obj.url;
    this.description = obj.description;
    this.show = obj.show;
    this.sort = obj.sort;
    this.title = obj.title;
    this.extra = obj.extra;
    this.deleted = obj.deleted;
    this.companyId = obj.companyId;
    this.category = obj.category;
    this.categoryId = obj.categoryId;
    this.subjectId = obj.subjectId;
    this.subject = obj.subject && new BoxTariff(obj.subject);
  }

  category: IInvestmentsCategory;
}

export class StageProduct<Stage extends IStageLesson> extends Product {
  public stages: Stage[];
  private stagesSortedByLessons: Stage[];

  constructor(obj: IProduct, stages: Stage[]) {
    super(obj);
    this.stages = stages;
    this.stagesSortedByLessons = [...stages].sort((a,b) => a.countLesson-b.countLesson);
  }

  getStagesByLessonCount = () => this.stagesSortedByLessons;

  getFilteredByCountry = (countryCode: string) => {
    return this.stagesSortedByLessons.filter(s => s.lang === countryCode);
  }

  stagesForCountryExist = (countryCode: string) => {
    return this.stagesSortedByLessons.findIndex(s => s.lang === countryCode) !== -1;
  }

  getAvailableCountryCodes = () => {
    return Object.keys(this.stages.reduce<{[key: string]: boolean}>((acc, stage) => {
      acc[stage.lang] = true;
      return acc;
    }, {}));
  }

  sameStageForCountry = (stage: Stage, countryCode: string) => {
    return this.stages.find(s => s.countLesson === stage.countLesson && s.lang === countryCode);
  }

  get isAvailable() {
    return this.stages.length > 0;
  }

  isAvailableForCountry = (countryCode: string) => {
    return this.stagesForCountryExist(countryCode);
  }
  get isTrial() {
    return this.category.slug === 'trial';
  }

  isSale = () => {
    const items = this.stages.filter(s => !!s.oldPrice);
    return !!items.length;
  };

  get getFirstStageId(): number | undefined {
    if (!this.stagesSortedByLessons || !this.stagesSortedByLessons.length) {
      return 0;
    }

    return this.stagesSortedByLessons[0].id;
  }

  get getFirstStage(): Stage | undefined {
    if (!this.stagesSortedByLessons || !this.stagesSortedByLessons.length) {
      return;
    }

    return this.stagesSortedByLessons[0];
  }
  getFirstStageForCountry = (countryCode: string): Stage | undefined => {
    const stages = this.getFilteredByCountry(countryCode);
    return stages && stages[0];
  }

  get getHandler(): string {
    return this.extra?.handler;
  }
}

export interface IProductByCategory {
  id: number,
  title: string
  slug: string,
  button_text: string,
  sort: number,
  deleted: boolean
  products: StageProduct<StageLesson>[]
  category: IInvestmentsCategory
}

export class ProductByCategory implements IProductByCategory {
  public readonly id: number;
  public title: string;
  public slug: string;
  public sort: number;
  public button_text: string;
  public deleted: boolean;
  public products: StageProduct<StageLesson>[];
  public category: InvestmentsCategory;

  constructor(obj: IProductByCategory) {
    this.id = obj.id;
    this.title = obj.title;
    this.slug = obj.slug;
    this.sort = obj.sort;
    this.button_text = obj.button_text;
    this.deleted = obj.deleted;
    this.products = obj.products;
    this.category = obj.category && new InvestmentsCategory(obj.category);
  }

  get isTrial() {
    return this.category.isTrial();
  }

  getForCountryCode = (countryCode: string) => {
    return this.products.filter(p => p.isAvailableForCountry(countryCode));
  }
}

export type setProductsLesson = ReturnType<typeof setProductsListLesson>;
export type setLessonForPurchaseType = ReturnType<typeof setLessonForPurchase>;
export type setProductsListAdminType = ReturnType<typeof setProductsListAdminAction>;

export type ProductAction = setLessonForPurchaseType | setProductsLesson | setProductsListAdminType

export interface IConfirmPurchase {
  product: StageProduct<StageLesson>,
  stage: StageLesson,
  subjectId: number
}

export interface IProductReducer {
  lesson: ProductByCategory[];
  item: IConfirmPurchase;
  listAdmin: StageProduct<StageLesson>[];
}

export interface IProductResp extends IProduct {
  stages: IStageLesson[];
}
