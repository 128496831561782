import { InitiatedStatistic, IScheduleReducer } from '@modules/schedule';
import {
  SCHEDULE_SET_LESSON_STATISTIC,
  ScheduleSetStatisticType,
  ScheduleAction,
  SCHEDULE_SET_LESSON_STATISTIC_PRODUCT,
  ScheduleSetProductType,
  SCHEDULE_SET_TEACHER_TIMETABLE,
  SetTeacherTimetableType,
  SCHEDULE_CLEAR_TEACHER_TIMETABLE,
  SCHEDULE_SET_UTC_OFFSET,
  SetUtcOffsetType,
  SCHEDULE_SET_NEXT_LESSON,
  SetLessonNextLessonType,
  SCHEDULE_REPLACE_TEACHER_SLOTS,
  ReplaceTeacherSlotsType,
  SCHEDULE_SET_CURRENT_LESSON,
  SetLessonCurrentLessonType,
  SCHEDULE_DETACH_SLOT,
  DetachSlotType,
  SHOW_SCHEDULE_AUTOFILL,
  ShowScheduleAutofill,
} from '@modules/schedule/actions';
import { Schedule } from '@modules/schedule/schedule-type';
import Tz from '@utils/tzTime';
import { Subject } from 'rxjs';

const defaultState: IScheduleReducer = {
  currentLessonSubject: new Subject<boolean>(),
  version: 1,
  statistic: {
    available: 0,
    total: 0,
    finished: 0,
    product: undefined,
    purchase: undefined,
    progress: 0,
  } as InitiatedStatistic,
  timetable: new Schedule([]),
  timezone: Tz.offset(),
  nextLesson: undefined,
  currentLesson: undefined,
  showAutofill: false,
};

const ScheduleReducerMethod = (
  state: IScheduleReducer = defaultState,
  action: ScheduleAction,
): IScheduleReducer => {
  if (action.type === SCHEDULE_SET_LESSON_STATISTIC) {
    const statistic = (action as ScheduleSetStatisticType).payload;

    return {
      ...state,
      statistic,
    };
  }

  if (action.type === SCHEDULE_SET_LESSON_STATISTIC_PRODUCT) {
    const product = (action as ScheduleSetProductType).payload;

    return {
      ...state,
      statistic: new InitiatedStatistic({
        ...state.statistic,
        product,
      }),
    };
  }


  if (action.type === SCHEDULE_CLEAR_TEACHER_TIMETABLE) {
    return {
      ...state,
      timetable: new Schedule([]),
    };
  }

  if (action.type === SCHEDULE_SET_TEACHER_TIMETABLE) {
    return {
      ...state,
      timetable: (action as SetTeacherTimetableType).payload || [],
    };
  }

  if (action.type === SCHEDULE_REPLACE_TEACHER_SLOTS) {
    const slots = (action as ReplaceTeacherSlotsType).payload;
    const timetable = state.timetable;
    timetable.replaceList(slots);

    return {
      ...state,
      timetable,
      version: state.version + 1,
    };
  }

  if (action.type === SCHEDULE_SET_UTC_OFFSET) {
    const timeZone = (action as SetUtcOffsetType).payload;

    const timetable = state.timetable;
    timetable.setOffset(timeZone);

    return {
      ...state,
      timetable,
      timezone: (action as SetUtcOffsetType).payload,
    };
  }

  if (action.type === SCHEDULE_SET_NEXT_LESSON) {
    return {
      ...state,
      nextLesson: (action as SetLessonNextLessonType).payload,
    };
  }

  if (action.type === SCHEDULE_SET_CURRENT_LESSON) {
    return {
      ...state,
      currentLesson: (action as SetLessonCurrentLessonType).payload,
    };
  }

  if (action.type === SCHEDULE_DETACH_SLOT) {
    const timetable = state.timetable;
    timetable.removeItem((action as DetachSlotType).payload);
    return {
      ...state,
      timetable,
      version: state.version + 1,
    };
  }

  if (action.type === SHOW_SCHEDULE_AUTOFILL) {
    return {
      ...state,
      showAutofill: (action as ShowScheduleAutofill).payload,
    };
  }

  return state;
};

export default ScheduleReducerMethod;
