import range from 'lodash/range';
import sortedUniqBy from 'lodash/sortedUniqBy';
import Tz from '@utils/tzTime';

const roles = {
  initiator: 'initiator',
  initiated: 'initiated',
};

export const statuses = {
  unset: -1,
  free: 0,
  scheduled: 1,
  started: 2,
  finished: 3,
  canceled: 128,
  brake: 255,
};

export interface IScheduleSlotData {
  token?: string
  sc_token?: string
}

export interface IScheduleSlot {
  id: number,
  initiated: number,
  initiator: number,
  status: number,
  extra: object,
  date: number,
  data: IScheduleSlotData,
  tzDate: number,
  duration: number
}

export class ScheduleSlot implements IScheduleSlot {
  public id: number;
  public initiated: number;
  public initiator: number;
  public status: number;
  public extra: object;
  public date: number;
  public data: IScheduleSlotData;
  public tzDate: number;
  public duration: number;

  constructor(obj: IScheduleSlot) {
    this.id = obj.id;
    this.initiated = obj.initiated;
    this.initiator = obj.initiator;
    this.status = obj.status;
    this.extra = obj.extra;
    this.date = obj.date;
    this.data = obj.data;
    this.tzDate = obj.tzDate;
    this.duration = obj.duration;
  }

  get isFree() {
    return this.status === statuses.free;
  }

  get getDuration(): number {
    return calculateDuration(this.duration);
  }

  get isPastSlot(): boolean {
    return Tz.utc().unix() > this.date;
  }

  get isAvailableToSchedule(): boolean {
    return (Tz.utc().unix() + unixHour) > this.date;
  }

  get isBeforeToday(): boolean {
    return Tz.endDay().unix() < this.date;
  }

  get isReadyToStart(): boolean {
    return (Tz.time().unix() + (unixDivider / 2)) > this.date;
  }

  get isNotStartedSlot(): boolean {
    return this.status === statuses.scheduled;
  }

  get isStartedSlot(): boolean {
    return this.status === statuses.started;
  }

  get isFinishedSlot(): boolean {
    return this.status === statuses.finished;
  }

  get isCanceledSlot(): boolean {
    return this.status === statuses.canceled;
  }

  // When student cancel the slot, the slot will be in avaiable status
  get isCancellationWithRefill(): boolean {
    return (Tz.utc().unix() + unixHour) < this.date;
  }
}

export const calculateDuration = (slotSize: number): number => {
  return Math.round(slotSize * slotMinuteSize);
};

export const slotMinuteSize = 30;
export const unixDay = 86400;
export const unixHour = 3600;
export const unixWeek = 604800;
export const unixDivider = 1800;
export type IntervalSlots = { [day: number]: ScheduleSlot[] }

export class Schedule {
  private offset: number;
  public list: ScheduleSlot[];
  private _slotsByDay: IntervalSlots;

  constructor(list: ScheduleSlot[], offset?: number) {
    this.list = [];
    this.offset = offset ?? Tz.offset();
    this._slotsByDay = {} as IntervalSlots;
    this.setList(list);
  }

  setOffset(val: number) {
    this.offset = val;
    this.makeSlots();
  }

  setList(list: ScheduleSlot[]) {
    this.list = sortedUniqBy(this.list.concat(list), i => i.date);
    this.makeSlots();
  }

  replaceList(list: ScheduleSlot[]) {
    const l = this.list.filter(i => !list.find(j => j.date === i.date));
    this.list = l.concat(list);
    this.makeSlots();
  }

  removeItem(id: number) {
    this.list = this.list.filter(i => i.id !== id);
    this.makeSlots();
  }

  makeWorkslot(id: number) {
    const slot = this.list.find(i => i.id !== id);
    if (!slot) {
      return;
    }

    this.list = this.list.filter(i => i.id !== id);

    for (let i = 0; i < slot.duration; i++) {
      this.list.push(new ScheduleSlot({} as IScheduleSlot));
    }

    this.makeSlots();
  }

  private makeSlots = () => {
    this._slotsByDay = this
      .list
      .map(i => {
        i.tzDate = i.date + this.offset;
        return i;
      })
      .reduce((acc: IntervalSlots, cur: ScheduleSlot) => {
        const day = (cur.tzDate - cur.tzDate % unixDay);
        acc = {
          ...acc,
          [day]: [
            ...(acc[day] || []).filter(i => i.id != cur.id),
            cur,
          ],
        };

        return acc;
      }, {});
  };

  get slotsByDay(): IntervalSlots {
    return this._slotsByDay;
  }

  get isEmpty() {
    return this.list.length === 0;
  }

  static getWeekStartTime = (offset: number = 0): number => {
    return Tz.utc().startOf('isoWeek').unix() + unixWeek * offset;
  };

  getWeekRange = (offset: number = 0): number[] => {
    const startWeek = Schedule.getWeekStartTime(offset);
    return range(7).map((i: number) => startWeek + i * unixDay);
  };

  getDaySlots = (unixDayValue: number, extra?: ScheduleSlot, offset?: number): [ScheduleSlot[], ScheduleSlot | undefined] => {
    const _offset = offset || this.offset;
    const res: ScheduleSlot[] = [];
    let count = Math.round(unixDay / unixDivider);
    let index = 0;
    let resExtra: ScheduleSlot | undefined;

    // TODO: Inject as constructor parameters
    // Optimize
    const fromDate = unixDayValue + parseInt(process.env.NEXT_PUBLIC_SCHEDULE_START_TIME || '21600', 10);
    const toDate = unixDayValue + parseInt(process.env.NEXT_PUBLIC_SCHEDULE_SCHEDULE_FINISH_TIME || '79200', 10);

    while (index < count) {
      const date = unixDayValue + index * unixDivider;

      let slot;

      if (index === 0 && extra && extra.duration > 1) {
        const duration = (date - extra.tzDate) / unixDivider;
        slot = new ScheduleSlot({
          status: extra.status,
          date: date - _offset,
          tzDate: date,
          duration,
        } as IScheduleSlot);
      } else {
        slot = this.list.find(i => i.tzDate === date) || new ScheduleSlot({
          status: -1,
          date: date - _offset,
          tzDate: date,
          duration: 1,
        } as IScheduleSlot);
      }

      if ((index + slot.duration) > count) {
        resExtra = new ScheduleSlot(slot);
        slot.duration = (index + slot.duration) - count;
      }

      if (fromDate <= date && toDate >= date) {
        res.push(slot);
      }

      index = Math.min(index + Math.max(slot.duration, 1), count);
    }

    return [res, resExtra];
  };

  static isToday = (unixDate: number | string): boolean => {
    const val = typeof unixDate === 'string' ? parseInt(unixDate, 10) : unixDate;
    return Tz.startDay().diff(Tz.unix(val), 'days') === 0;
  };

  // weekOffset = 0,1,2,3 - offset from current week

  slotsByWeek = (weekOffset: number = 0, dayOffsetUnix: number = 0): IntervalSlots => {
    let extraTime: ScheduleSlot | undefined;
    return this
      .getWeekRange(weekOffset)
      .reduce((acc, cur) => {
        const [slots, extra] = this.getDaySlots(cur, extraTime, dayOffsetUnix);
        extraTime = extra;

        return {
          ...acc,
          [cur]: slots,
        };
      }, {});
  };
}
