import CombineEmailReducer from '@modules/template/reducers/combine';
import { combineReducers } from 'redux';
import { AppStorageType } from '@app-types/appStorageType';
import authReducer from '@modules/auth/reducers';
import commonReducer from '@modules/common/reducers';
import NotificationReducer from '@modules/notification/reducers';
import { TranslateAdminReducer } from '@modules/translate/reducers';
import AccountsReducer from '@modules/accounts/reducer';
import RolesReducer from '@modules/roles/reducers';
import CasbinReducer from '@modules/privacy/reducers';
import TwoFaReducer from '@modules/twoFa/reducers';
import DepositReducer from '@modules/deposit/reducers';
import TransactionsReducer from '@modules/transactions/reducers';
import { InformationsReducer } from '@modules/information/InforamtionReducer';
import StagesReducer from '@modules/stages/reducers';
import { InvestmentsReducer } from '@modules/investments/CombineReducer';
import ContextStorageReducerMethod from '@modules/context-storage/reducers';
import { PersonalReducer } from '@modules/personal/types';
import NotesReducerMethod from '@modules/notes/reducer';
import StatusReducerMethod from '@modules/status/reducers';
import ScheduleReducerMethod from '@modules/schedule/reducer';
import UserReducerMethod from '@modules/user/reducers';
import ChatReducerMethod from '@modules/chat/reducers';
import CountryCurrencyReducerMethod from '@modules/country-corrency/reducers';
import AdminScheduleReducerMethod from '@modules/admin/reducers';
import PromocodeReducerMethod from '@modules/promocode/reducer';
import ReviewReducerMethod from '@modules/review/reducers';
import ListReducerMethod from '@modules/lists/reducers';
import BoxReducerMethod from '@modules/box-tariffs/reducers';
import CompanyReducerMethod from '@modules/company/reducers';
import TeacherStageReducer from '@modules/teacher-stage/reducers';
import TeacherPriorityReducerMethod from '@modules/teacher-priority/reducers';

const rootReducer = combineReducers<AppStorageType>({
  auth: authReducer,
  common: commonReducer,
  deposit: DepositReducer,
  accounts: AccountsReducer,
  translate: TranslateAdminReducer,
  information: InformationsReducer,
  transactions: TransactionsReducer,
  notification: NotificationReducer,
  stages: StagesReducer,
  roles: RolesReducer,
  privacy: CasbinReducer,
  twoFa: TwoFaReducer,
  investments: InvestmentsReducer,
  contextStorage: ContextStorageReducerMethod,
  personal: PersonalReducer,
  notes: NotesReducerMethod,
  status: StatusReducerMethod,
  schedule: ScheduleReducerMethod,
  adminSchedule: AdminScheduleReducerMethod,
  users: UserReducerMethod,
  chat: ChatReducerMethod,
  country: CountryCurrencyReducerMethod,
  promocode: PromocodeReducerMethod,
  review: ReviewReducerMethod,
  list: ListReducerMethod,
  boxTariffs: BoxReducerMethod,
  company: CompanyReducerMethod,
  teacherStage: TeacherStageReducer,
  teacherPriority: TeacherPriorityReducerMethod,
  email: CombineEmailReducer,
});

export default rootReducer;
