import {
  CasbinAction,
  LoadCasbinRulesAction,
  LOAD_CASBIN_RULES,
  addOrUpdateCasbinAction,
  removeCasbinAction,
  REMOVE_CASBIN_RULE,
  LOAD_SEARCHED_RULES,
  loadSearchedRules, ADD_OR_UPDATE_CASBIN_RULE,
} from '@modules/privacy/actions';
import { CasbinRules, ICasbinReducer } from '@modules/privacy/types';

const defaultState: ICasbinReducer = {
  casbinRules: [] as CasbinRules[],
  searchedRules: [] as CasbinRules[],
}

const CasbinReducer = (
  state: ICasbinReducer = defaultState,
  action: CasbinAction,
): ICasbinReducer => {
  if (action.type === LOAD_CASBIN_RULES) {
    return {
      ...state,
      casbinRules: (action as LoadCasbinRulesAction).payload,
    }
  }

  if (action.type === ADD_OR_UPDATE_CASBIN_RULE) {
    const { casbinRules, searchedRules } = state;
    const item = (action as addOrUpdateCasbinAction).payload;
    const index = casbinRules.findIndex(i => i.id === item.id);
    const indexSearch = searchedRules.findIndex(i => i.id === item.id);

    if (!!~index || !!~indexSearch) {
      return {
        ...state,
        casbinRules: [
          ...casbinRules.slice(0, index),
          item,
          ...casbinRules.slice(index + 1),
        ],
        searchedRules: !!~indexSearch ? [
          ...searchedRules.slice(0, indexSearch),
          item,
          ...searchedRules.slice(indexSearch + 1),
        ] : searchedRules
      }
    }

    return {
      ...state,
      casbinRules: [
        item,
        ...casbinRules,
      ],
    }
  }

  if (action.type === REMOVE_CASBIN_RULE) {
    const { casbinRules, searchedRules } = state;
    const itemId = (action as removeCasbinAction).payload;
    const index = casbinRules.findIndex(i => i.id === itemId);
    const indexSearch = searchedRules.findIndex(i => i.id === itemId);

    return {
      ...state,
      casbinRules: [
        ...casbinRules.slice(0, index),
        ...casbinRules.slice(index + 1),
      ],
      searchedRules: [
        ...searchedRules.slice(0, indexSearch),
        ...searchedRules.slice(indexSearch + 1),
      ],
    }
  }

  if (action.type === LOAD_SEARCHED_RULES) {
    return {
      ...state,
      searchedRules: (action as loadSearchedRules).payload,
    }
  }

  return state;
};

export default CasbinReducer;
