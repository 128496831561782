const config = {
  'public': {
    'successRegisterPage': '/auth/success-sign-up',
    'redirectPage': '/auth/sign-in',
    'clear': '/auth/sign-in',
    'myDay': '/private/main',
  },
  'private': {
    'redirectPage': '/private',
  },
  'admin': {
    'redirectPage': '/private',
  },
  'email-confirm': '/auth/confirm',
  'notFoundPage': '/404',
  'blockedRedirectPage': '/auth/blocked',
}
export default config;

export const getPrivatePath = (role: string) => role === 'admin'
  ? config.admin.redirectPage
  : config.private.redirectPage;
