import { CommonAction } from '@modules/common/actions';
import { Exchanger } from '@modules/accounts/model/exchanger';

export const SET_EXCHANGER_LIST = 'SET_EXCHANGER_LIST';
export const CREATE_EXCHANGER = 'CREATE_EXCHANGER';
export const REMOVE_EXCHANGER = 'REMOVE_EXCHANGER';

export const SetExchangersList = CommonAction<Exchanger[]>(SET_EXCHANGER_LIST);
export const createExchanger = CommonAction<Exchanger>(CREATE_EXCHANGER);
export const removeExchangerItem = CommonAction<Exchanger>(REMOVE_EXCHANGER);

export type SetExchangersType = ReturnType<typeof SetExchangersList>;
export type createExchangerType = ReturnType<typeof createExchanger>;
export type removeExchangerType = ReturnType<typeof removeExchangerItem>;

export type ActionExchanger = SetExchangersType | createExchangerType | removeExchangerType
