import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { ContextWrapperProps } from './redux';

const ContextWrapper: FunctionComponent<PropsWithChildren<ContextWrapperProps>> = (props) => {
  const {
    children,
    contexts,
    setContexts,
  } = props;

  useEffect(() => {
    setContexts(contexts);
  }, []);

  return (
    <>
      {children}
    </>
  );
}

export default ContextWrapper;