import { CommonAction } from '@modules/common/actions';
import { Account } from '../../model/account';
import { IAccountBalance, UserBalance } from '@modules/accounts/type/account';

export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const CREATE_ACCOUNTS = 'CREATE_ACCOUNTS';
export const SET_ACCOUNTS_LIST = 'SET_ACCOUNTS_LIST';
export const UPDATE_BALANCE_VALUE = 'UPDATE_BALANCE_VALUE';
export const SET_SHARES_VALUE = 'SET_SHARES_VALUE';
export const SET_SPECIFIC_BALANCE = 'SET_SPECIFIC_BALANCE';

export const SetAccounts = CommonAction<UserBalance[]>(SET_ACCOUNTS);
export const CreateAccounts = CommonAction<Account>(CREATE_ACCOUNTS);
export const SetAccountsList = CommonAction<Account[]>(SET_ACCOUNTS_LIST);
export const updateBalance = CommonAction<IAccountBalance>(UPDATE_BALANCE_VALUE);
export const setSharesValue = CommonAction<number>(SET_SHARES_VALUE);
export const setSpecificBalanceAction = CommonAction<UserBalance[]>(SET_SPECIFIC_BALANCE);

export type SetAccountsType = ReturnType<typeof SetAccounts>;
export type CreateAccountsType = ReturnType<typeof CreateAccounts>;
export type SetAccountsListType = ReturnType<typeof SetAccountsList>;
export type UpdateBalanceType = ReturnType<typeof updateBalance>;
export type SetSharesValueType = ReturnType<typeof setSharesValue>;
export type SetSpecificBalanceAction = ReturnType<typeof setSpecificBalanceAction>

export type AccountsAction = SetAccountsType | CreateAccountsType | SetAccountsListType | UpdateBalanceType | SetSharesValueType | SetSpecificBalanceAction;

