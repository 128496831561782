import {
  IConfirmPurchase,
  IProductReducer,
  ProductAction, setLessonForPurchaseType,
  setProductsLesson, setProductsListAdminType,
  StageProduct,
} from '@modules/investments/products/types/product';
import {
  SET_LESSON_FOR_PURCHASE,
  SET_PRODUCTS_LIST_ADMIN,
  SET_PRODUCTS_LIST_LESSON,
} from '@modules/investments/products/actions';

const defaultState: IProductReducer = {
  lesson: [],
  item: {} as IConfirmPurchase,
  listAdmin: []
};

const ProductReducerMethod = (
  state: IProductReducer = defaultState,
  action: ProductAction,
): IProductReducer => {
  if (action.type === SET_PRODUCTS_LIST_LESSON) {
    return {
      ...state,
      lesson: ((action as setProductsLesson).payload || []).sort((a,b) => a.sort-b.sort),
    };
  }

  if (action.type === SET_LESSON_FOR_PURCHASE) {
    return {
      ...state,
      item: (action as setLessonForPurchaseType).payload || [],
    };
  }

  if (action.type === SET_PRODUCTS_LIST_ADMIN) {
    return {
      ...state,
      listAdmin: (action as setProductsListAdminType).payload || [],
    };
  }

  return state;
};

export default ProductReducerMethod;
