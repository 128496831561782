import { useContext } from 'react';
import { AuthContext } from '@contexts/auth';
import { User } from '@modules/auth/types/Users';

const useSession = () => {
  const { user, userId, update } = useContext(AuthContext);

  if (user) {
    return {
      user: new User(user),
      userId,
      update,
    }
  }

  return {
    user: undefined,
    userId: undefined,
    update: () => {}
  }
}

export default useSession;
