import { CommonAction } from '@modules/common/actions';
import { IRole, ISearchedRoles } from '@modules/roles/types';
import IUser from "@modules/auth/types/Users";

export const LOAD_USER_ROLES_ACTION = 'LOAD_USER_ROLES_ACTION';
export const LOAD_SEARCHED_USER_ROLE = 'LOAD_SEARCHED_USER_ROLE';
export const ADD_OR_UPDATE_USER_ROLE_ACTION = 'ADD_OR_UPDATE_USER_ROLE_ACTION';
export const DELETE_USER_ROLE_ACTION = 'DELETE_USER_ROLE_ACTION';
export const LOAD_ROLES_ACTION = 'LOAD_ROLES_ACTION';

export const loadUserRoleAction = CommonAction<IUser[]>(LOAD_USER_ROLES_ACTION);
export const loadSearchedUserRole = CommonAction<ISearchedRoles[]>(LOAD_SEARCHED_USER_ROLE);
export const addOrUpdateUserRoleAction = CommonAction<IUser>(ADD_OR_UPDATE_USER_ROLE_ACTION);
export const deleteUserRoleAction = CommonAction<number>(DELETE_USER_ROLE_ACTION);
export const loadRolesAction = CommonAction<IRole[]>(LOAD_ROLES_ACTION);

export type LoadUserRoleAction = ReturnType<typeof loadUserRoleAction>;
export type LoadSearchedUserRoleAction = ReturnType<typeof loadSearchedUserRole>;
export type AddOrUpdateUserRoleAction = ReturnType<typeof addOrUpdateUserRoleAction>;
export type DeleteUserRoleAction = ReturnType<typeof deleteUserRoleAction>;
export type LoadRolesAction = ReturnType<typeof loadRolesAction>;

export type RolesAction = LoadUserRoleAction | LoadSearchedUserRoleAction | AddOrUpdateUserRoleAction | DeleteUserRoleAction | LoadRolesAction;
