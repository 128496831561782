export const setCookie = (key: string, value: string) => {
  if (!process.browser || !document || !value) {
    return;
  }

  document.cookie = `${key}=${value}; path=/;Max-Age=${parseInt(process.env.NEXT_PUBLIC_TOKEN_DURATION || '2629743', 10)}`;
};

export const removeCookie = (key: string) => {
  if (!process.browser) {
    return;
  }

  if (!document) {
    return;
  }

  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};
