import { CommonAction } from '@modules/common/actions';
import { ICompany } from '@modules/company/types';

export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
export const CREATE_OR_UPDATE_COMPANY = 'CREATE_OR_UPDATE_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';


export const setCompanyListAction = CommonAction<ICompany[]>(SET_COMPANY_LIST);
export const createOrUpdateCompanyAction = CommonAction<ICompany>(CREATE_OR_UPDATE_COMPANY);
export const removeCompanyAction = CommonAction<number>(REMOVE_COMPANY);

export type setCompanyListType = ReturnType<typeof setCompanyListAction>;
export type createOrUpdateCompanyType = ReturnType<typeof createOrUpdateCompanyAction>;
export type removeCompanyType = ReturnType<typeof removeCompanyAction>;

export type CompanyActions = setCompanyListType | createOrUpdateCompanyType | removeCompanyType
