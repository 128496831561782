import { IPromocodeReducer } from '@modules/promocode/types';
import {
  CREATE_PROMOCODE, CreatePromocodeActionType, DELETE_PROMOCODE, DeletePromocodeActionType,
  LOAD_PROMOCODE,
  LoadPromocodeActionType,
  PromocodeActions, UPDATE_PROMOCODE, UpdatePromocodeActionType,
} from '@modules/promocode/actions';

const defaultState: IPromocodeReducer = {
  list: [],
}

const PromocodeReducerMethod = (
  state: IPromocodeReducer = defaultState,
  action: PromocodeActions,
): IPromocodeReducer => {
  if (action.type === LOAD_PROMOCODE) {
    const list = (action as LoadPromocodeActionType).payload;

    return {
      ...state,
      list,
    }
  }

  if (action.type === CREATE_PROMOCODE) {
    const { list } = state;
    const item = (action as CreatePromocodeActionType).payload;

    return {
      ...state,
      list: [
        item,
        ...list,
      ]
    }
  }

  if (action.type === UPDATE_PROMOCODE) {
    const { list } = state;
    const item = (action as UpdatePromocodeActionType).payload;

    const index = list.findIndex(i => i.id === item.id);

    return {
      ...state,
      list: [
        ...list.slice(0, index),
        item,
        ...list.slice(index + 1),
      ],
    }
  }

  if (action.type === DELETE_PROMOCODE) {
    const { list } = state;
    const id = (action as DeletePromocodeActionType).payload;

    const index = list.findIndex(i => i.id === id);

    return {
      ...state,
      list: [
        ...list.slice(0, index),
        ...list.slice(index + 1),
      ],
    }
  }

  return state;
};

export default PromocodeReducerMethod;
