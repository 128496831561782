import { CommonAction } from '@modules/common/actions';
import { EmailTemplate } from '@modules/template/types';

export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const SET_EMAIL_TEMPLATE_CREATE_OR_UPDATE = 'SET_EMAIL_TEMPLATE_CREATE_OR_UPDATE'
export const SET_EMAIL_TEMPLATE_LIST = 'SET_EMAIL_TEMPLATE_LIST'
export const SET_EMAIL_TEMPLATE_COUNT = 'SET_EMAIL_TEMPLATE_COUNT'
export const SET_EMAIL_TEMPLATE_REMOVE = 'SET_EMAIL_TEMPLATE_REMOVE'
export const SET_EMAIL_TEMPLATE_ITEM = 'SET_EMAIL_TEMPLATE_ITEM'

export const addEmailTemplateAction = CommonAction<EmailTemplate>(ADD_EMAIL_TEMPLATE);
export const updateEmailTemplateAction = CommonAction<EmailTemplate>(UPDATE_EMAIL_TEMPLATE);
export const setEmailTemplateCreateOrUpdateAction = CommonAction<EmailTemplate>(SET_EMAIL_TEMPLATE_CREATE_OR_UPDATE);
export const setEmailTemplateListAction = CommonAction<EmailTemplate[]>(SET_EMAIL_TEMPLATE_LIST);
export const setEmailTemplateCountAction = CommonAction<number>(SET_EMAIL_TEMPLATE_COUNT);
export const setEmailTemplateRemoveAction = CommonAction<number>(SET_EMAIL_TEMPLATE_REMOVE);
export const setEmailTemplateItemAction = CommonAction<EmailTemplate | undefined>(SET_EMAIL_TEMPLATE_ITEM);

export type addEmailTemplateActionType = ReturnType<typeof addEmailTemplateAction>;
export type updateEmailTemplateActionType = ReturnType<typeof updateEmailTemplateAction>;
export type setEmailTemplateCreateOrUpdateActionType = ReturnType<typeof setEmailTemplateCreateOrUpdateAction>;
export type setEmailTemplateListType = ReturnType<typeof setEmailTemplateListAction>;
export type setEmailTemplateCountType = ReturnType<typeof setEmailTemplateCountAction>;
export type setEmailTemplateRemoveType = ReturnType<typeof setEmailTemplateRemoveAction>;
export type setEmailTemplateItemType = ReturnType<typeof setEmailTemplateItemAction>;

export type EmailTemplateActions = updateEmailTemplateActionType | addEmailTemplateActionType | setEmailTemplateCreateOrUpdateActionType  | setEmailTemplateListType | setEmailTemplateCountType | setEmailTemplateRemoveType | setEmailTemplateItemType
