import {
  CREATE_OR_UPDATE_REVIEW,
  CREATE_OR_UPDATE_REVIEW_TEACHERS,
  creteOrUpdateReviewTeachersType,
  creteOrUpdateReviewType,
  REMOVE_REVIEW,
  REMOVE_REVIEW_TEACHERS,
  removeReviewActionType,
  removeReviewTeachersActionType,
  ReviewActions,
  SET_REVIEW_LIST,
  SET_REVIEW_TEACHERS_LIST,
  setReviewListType,
  setReviewTeachersListType,
} from '@modules/review/actions';
import { IReviewTeachers, IReviews, IReview } from '@modules/review/types';

const defaultState: IReviews = {
  students: [],
  teachers: [],
};
const ReviewReducerMethod = (
  state: IReviews = defaultState,
  action: ReviewActions,
): IReviews => {
  if (action.type === SET_REVIEW_TEACHERS_LIST) {
    const teachers = (action as setReviewTeachersListType).payload || [];

    return {
      ...state,
      teachers,
    };
  }

  if (action.type === SET_REVIEW_LIST) {
    const students = (action as setReviewListType).payload || [];

    return {
      ...state,
      students,
    };
  }

  if (action.type === CREATE_OR_UPDATE_REVIEW) {
    const { students } = state;
    const item = (action as creteOrUpdateReviewType).payload;
    const index = students.findIndex((i: IReview) => i.id === item.id);

    if (!!~index) {
      return {
        ...state,
        students: [
          ...students.slice(0, index),
          item,
          ...students.slice(index + 1),
        ],
      };
    }

    return {
      ...state,
      students: [
        item,
        ...students
      ],
    };
  }

  if (action.type === CREATE_OR_UPDATE_REVIEW_TEACHERS) {
    const { teachers } = state;
    const item = (action as creteOrUpdateReviewTeachersType).payload;
    const index = teachers.findIndex((i: IReviewTeachers) => i.id === item.id);

    if (!!~index) {
      return {
        ...state,
        teachers: [
          ...teachers.slice(0, index),
          item,
          ...teachers.slice(index + 1),
        ],
      };
    }

    return {
      ...state,
      teachers: [
        item,
        ...teachers,
      ],
    };
  }

  if (action.type === REMOVE_REVIEW) {
    const { students } = state;
    const id = (action as removeReviewActionType).payload;
    const item = students.find((i: IReview) => i.id === id);

    if (!item) {
      return state;
    }

    const index = students.findIndex((i: IReview) => i.id === id);

    return {
      ...state,
      students: [
        ...students.slice(0, index),
        ...students.slice(index + 1),
      ],
    };
  }

  if (action.type === REMOVE_REVIEW_TEACHERS) {
    const { teachers } = state;
    const id = (action as removeReviewTeachersActionType).payload;
    const item = teachers.find((i: IReviewTeachers) => i.id === id);

    if (!item) {
      return state;
    }

    const index = teachers.findIndex((i: IReviewTeachers) => i.id === id);

    return {
      ...state,
      teachers: [
        ...teachers.slice(0, index),
        ...teachers.slice(index + 1),
      ],
    };
  }

  return state;
};

export default ReviewReducerMethod;
