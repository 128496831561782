import { AdminScheduleReducer } from '@modules/admin/types/reducerTypes';
import {
  AdminAction,
  SelectTeacherType, SET_ADMIN_TIMEZONE_ADMIN_SCHEDULE,
  SET_SELECTED_STUDENT_ADMIN_SCHEDULE,
  SET_SELECTED_TEACHER_ADMIN_SCHEDULE, SetAdminTimezoneType, CLEAR_ADMIN_SCHEDULE,
} from '@modules/admin/actions';

const defaultState: AdminScheduleReducer = {
  teacher: undefined,
  student: undefined,
  timezone: undefined,
}

const AdminScheduleReducerMethod = (
  state: AdminScheduleReducer = defaultState,
  action: AdminAction,
): AdminScheduleReducer => {
  if (action.type === CLEAR_ADMIN_SCHEDULE) {
    return {
      teacher: undefined,
      student: undefined,
      timezone: undefined,
    }
  }

  if (action.type === SET_SELECTED_TEACHER_ADMIN_SCHEDULE) {
    const teacher = (action as SelectTeacherType).payload;
    if (teacher === state.teacher) {
      return state;
    }

    return {
      ...state,
      teacher,
    };
  }

  if (action.type === SET_SELECTED_STUDENT_ADMIN_SCHEDULE) {
    const student = (action as SelectTeacherType).payload;
    if (student === state.student) {
      return state;
    }

    return {
      ...state,
      student,
    };
  }

  if (action.type === SET_ADMIN_TIMEZONE_ADMIN_SCHEDULE) {
    return {
      ...state,
      timezone: (action as SetAdminTimezoneType).payload
    };
  }

  return state;
}

export default AdminScheduleReducerMethod;
