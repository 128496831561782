import { User } from '@modules/auth/types/Users';
import { Document, IDocument } from '@modules/auth/types';
import { Status } from '@modules/status/types';
import moment, { Moment } from 'moment';
import Timezone from '@modules/timezone/types';
import Tz from '@utils/tzTime';
import { IPersonal } from '@modules/personal/types';
import { InitiatedStatistic } from '@modules/schedule';
import { IPurchase, Purchase } from '@modules/investments/purchases/types/purchase';
import { StageLesson } from '@modules/investments/products/types/stage';
import get from 'lodash/get';

export interface IBaseUser {
  user: User,
  document: Document,
  status: Status,
  online: IOnline
  statistic?: InitiatedStatistic,
}

export interface ITeacher extends IBaseUser {
  classesHeld?: number;
}

export interface IStudent extends IBaseUser {
  purchase?: IPurchase;
}

export abstract class BaseUser implements IBaseUser {
  document: Document;
  online: Online;
  status: Status;
  user: User;
  statistic?: InitiatedStatistic;

  protected constructor(obj: IBaseUser) {
    this.document = obj.document;
    this.online = obj.online;
    this.status = obj.status;
    this.user = obj.user;
    this.statistic = obj.statistic;
  }

  get isNoCityAndCountry(): boolean {
    return !this.document?.city && !this.document?.country;
  }

  get city(): string {
    return this.document?.city || '';
  }

  get country(): string {
    return this.document?.country || '—';
  }

  get isOnline(): boolean {
    return this.online?.online || false;
  }

  get canStartLesson(): boolean {
    return true;
  }

  get langLevel(): string {
    return this.document?.langLevel?.title || '';
  }

  get isLangLevel(): boolean {
    return !!this.document.langLevel
  }

  get lastVisit(): string {
    if (!this.online || !this.online?.lastVisit) {
      return 'Offline';
    }

    return Tz.unix(this.online?.lastVisit).fromNow();
  }

  get getTimezone(): string {
    if (!this.document) {
      return '—';
    }

    if (!this.document.timezone) return '—';

    return new Timezone(this.document.timezone).UtcViwe;
  }

  get fullName(): string | undefined {
    if (!this.user) {
      return undefined;
    }

    return this.user?.fullName;
  }

  // TODO: Here is type bug
  get interests(): IPersonal[] {
    return this.document?.interests as IPersonal[] || [];
  }

  get localTime(): moment.Moment {
    return Tz.timeWithTz(new Timezone(this.document.timezone));
  }

  get timeOffset(): number {
    const t = new Timezone(this.document.timezone);
    return t.offset;
  }

  get bithdayAgo(): string {
    if (!this.document?.unixBithday) {
      return '';
    }

    return Tz.unix(this.document!.unixBithday).toNow(true);
  }
}

export class Student extends BaseUser {
  public purchase?: Purchase<StageLesson>;

  constructor(obj: IBaseUser, purchase?: Purchase<StageLesson>) {
    super(obj);
    this.purchase = purchase || undefined;
  }

  get isGeneral(): boolean {
    if (!this.purchase) {
      return false;
    }

    return this.purchase.isGeneral;
  }

  get isTrial(): boolean {
    if (!this.purchase) {
      return false;
    }

    return this.purchase.isTrial
  }

  get showTypeLesson(): string {
    if (!this.purchase) {
      return '';
    }

    if (this.isTrial) {
      return 'purchase.type.trial';
    }

    if (this.isGeneral) {
      return 'purchase.type.general';
    }

    return '';
  }

}

export class Teacher extends BaseUser {
  classesHeld?: number;

  constructor(obj: IBaseUser, classesHeld?: number) {
    super(obj);
    this.classesHeld = classesHeld;
  }

}

export class Client extends BaseUser {
  constructor(obj: IBaseUser) {
    super(obj);
  }
}

export interface IOnline {
  userId: number,
  online: boolean,
  lastVisit: number,
}

export class Online implements IOnline {
  public userId: number;
  public online: boolean;
  public lastVisit: number;

  constructor(obj: IOnline) {
    this.userId = obj.userId;
    this.online = obj.online;
    this.lastVisit = obj.lastVisit;
  }
}

export interface IUserReducer<T extends BaseUser> {
  list: T[],
  item: T,
  students: T[],
  teachersCount: number,
  count: number,
}
