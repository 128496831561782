import { InvestmentsCategory } from '@modules/investments/category/types/category';
import { IPurchase, Purchase } from '@modules/investments/purchases/types/purchase';
import { StageLesson } from '@modules/investments/products/types/stage';
import { BaseCommandProps } from '@app-types/CommonTypes';
import { calculateDuration, Schedule, ScheduleSlot } from './schedule-type';
import { IProduct, StageProduct } from '@modules/investments/products/types/product';
import { Subject } from 'rxjs';
import { TranslateFunc } from '@modules/translate/types/translate';

export interface IInitiatedStatistic {
  available: number,
  finished: number,
  totalFinished: number,
  total: number,
  canceled: number,
  left: number,
  purchase: Purchase<StageLesson> | undefined
  product: StageProduct<StageLesson> | undefined
  progress: number,
  duration: number,
  first: boolean,
}

export interface IInitiatedStatisticResp extends BaseCommandProps, IInitiatedStatistic {
}

export interface IScheduleReducer {
  currentLessonSubject: Subject<boolean>
  version: number
  statistic: InitiatedStatistic,
  timetable: Schedule,
  timezone: number,
  nextLesson: ScheduleSlot | undefined,
  currentLesson: ScheduleSlot | undefined,
  showAutofill?: boolean
}

export class ScheduleReducer implements IScheduleReducer {
  currentLessonSubject: Subject<boolean>;
  version: number;
  nextLesson: ScheduleSlot | undefined;
  currentLesson: ScheduleSlot | undefined;
  statistic: InitiatedStatistic;
  timetable: Schedule;
  timezone: number;

  constructor(obj: IScheduleReducer) {
    this.currentLessonSubject = new Subject<boolean>();
    this.version = obj.version;
    this.nextLesson = obj.nextLesson;
    this.currentLesson = obj.currentLesson;
    this.statistic = obj.statistic;
    this.timetable = obj.timetable;
    this.timezone = obj.timezone;
  }

  get shouldScheduleDisplay() {
    return !!this.statistic.assignedTeacherId;
  }

  get nextInitiated(): number | undefined {
    return this.currentLesson?.initiated || this.nextLesson?.initiated;
  }
}

export class InitiatedStatistic implements IInitiatedStatistic {
  static InitialProgress = 0;
  static SetLessonProgress = 1;
  static TestLessonCompleteProgress = 2;
  static AssignedPaidLessonProgress = 3;

  available: number;
  finished: number;
  totalFinished: number;
  product: StageProduct<StageLesson> | undefined;
  purchase: Purchase<StageLesson> | undefined;
  total: number;
  progress: number;
  duration: number;
  canceled: number;
  left: number;
  first: boolean;

  constructor(obj: IInitiatedStatistic) {
    const product = obj.product as IProduct;

    this.available = obj.available;
    this.finished = obj.finished;
    this.totalFinished = obj.totalFinished;
    this.product = product && new StageProduct<StageLesson>(product, (product.stages || []).map(stage => new StageLesson(stage)));
    this.purchase = obj.purchase && new Purchase<StageLesson>(obj.purchase as IPurchase, obj.purchase?.stage as StageLesson, obj.purchase.category && new InvestmentsCategory(obj.purchase.category));
    this.total = obj.total;
    this.progress = obj.progress;
    this.duration = obj.duration;
    this.canceled = obj.canceled;
    this.left = obj.left;
    this.first = obj.first;
  }
  get leftLessons(): number {
    return this.total - this.left;
  }

  get assignedTeacherId(): number | undefined {
    return this.purchase?.extra?.teacherId;
  }

  get isPurchaseMade(): boolean {
    return !!this.purchase;
  }

  get isTrail(): boolean {
    return this.first;
  }
  get isTrailAndPurchased(): boolean {
    return this.first && this.isPurchaseMade;
  }

  get isLessonAvailable() {
    return this.available > 0;
  }

  get isNeedDisplayWizard() {
    return this.product && this.progress < InitiatedStatistic.AssignedPaidLessonProgress;
  }

  get isInitialProgress() {
    return this.first && !this.purchase;
  }

  get isInitialProgressPassed() {
    return this.progress > InitiatedStatistic.InitialProgress;
  }

  get isScheduleProgress() {
    return this.first && this.purchase && !this.purchase.teacherId;
  }

  get isSetLessonProgress() {
    return this.first && this.purchase && this.purchase.teacherId && this.finished == 0;
  }

  get isTestLessonCompleteProgress() {
    return this.first && this.purchase && this.purchase.teacherId && this.finished == 1;
  }

  get isAssignedPaidLessonProgress() {
    return this.progress == InitiatedStatistic.AssignedPaidLessonProgress;
  }

  get lessonDuration() {
    return calculateDuration(this.duration);
  }

  get productHandler() {
    return this.product?.getHandler;
  }

  getPurchaseTitle = (translate: TranslateFunc) => {
    if (!!this.product?.title) {
      if (!!this.purchase?.extra?.categoryTitleCard) {
        return `${translate(this.product.title, undefined, undefined, true)} | ${translate(this.purchase.extra.categoryTitleCard, undefined, undefined, true)}`;
      }
      return `${translate(this.product.title, undefined, undefined, true)}`;
    }

    return 'No title';
  };
}
