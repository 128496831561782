import {
  Document,
  IChangeAlias, IChangeEmail,
  IChangePassword,
  IDocumentRes,
  IDocumentUserProfile, IForgotSubmitFormParams, IOnSubmitUserLockForm,
  IRegistration,
  IRegistrationRes, IRestorePassword,
  ISignIn, ISignInRegToken,
  ISignInRes, ITokenRes, IVerifyRes, UserIdReq,
} from '@modules/auth/types';
import httpClient, { apiCall } from './httpClient';
import { AppDispatch, AppStorageType } from '@app-types/appStorageType';
import { setDocument, setDocumentUser } from '@modules/auth/actions';
import dateTransformer from '@commands/transformer/dateTransformer';
import { ImpersonateGet, ImpersonateGetResp } from '@modules/impersonate/types';
import { ResultItemRes, ResultListRes } from '@modules/apiClient/type';
import { BaseCommandProps } from '@app-types/CommonTypes';
import { parseCookies, setCookie } from 'nookies';
import { IPersonalSubmit } from '@modules/document/types';
import Timezone from '@modules/timezone/types';
import Tz from '@utils/tzTime';
import { setUtcOffsetTimetable } from '@modules/schedule/actions';
import { ConnectToSocket } from '@modules/sockets/actions';
import { onUserDataChanged } from '@modules/common/actions';
import IUser, { User } from '@modules/auth/types/Users';
import { appendUserAction, setUserAction } from '@modules/user/actions';
import { Client, IBaseUser, Online, Student } from '@modules/user/types';
import { Status } from '@modules/status/types';
import { InitiatedStatistic } from '@modules/schedule';
//TODO: interface fixed plz
export const login = (data: ISignIn, lang: string, authToken?: string) => (dispatch: AppDispatch<ISignInRes & ISignInRegToken>, getState: () => AppStorageType): Promise<{ token: string, blocked: boolean, regToken: string }> => {
  const state: AppStorageType = getState();
  const country = state.country.country;
  return dispatch(httpClient<ISignIn & { authToken: string }, ISignInRes & ISignInRegToken>('/v1/user/login', {
    ...data,
    country,
    lang,
    authToken: authToken && authToken || '',
  }, { context: 'common' }))
    .then((res: void | ISignInRes & ISignInRegToken) => {
      if (!res) {
        return {
          token: '',
          blocked: false,
          regToken: '',
        };
      }

      const { token, profile, regToken } = res;
      const blocked: boolean = profile && profile.blocked || false;
      if (!regToken) {
        dispatch(ConnectToSocket(token));
      }
      return { token, blocked, regToken, role: profile?.role };
    });
};
export const refreshToken = () => (_: AppDispatch<object>) =>
  _(httpClient<object, ITokenRes>(`/v1/user/refresh-token`, undefined, { context: '' }));

export const getUserToken = (value: ImpersonateGet) => (dispatch: AppDispatch<ImpersonateGet>) => {
  return dispatch(apiCall<ImpersonateGet, ImpersonateGetResp>('/v1/user/get-token', value, { method: 'post' }));
};

export const registration = (data: IRegistration) => (dispatch: AppDispatch<IRegistrationRes>) => {
  const { langValue } = parseCookies();
  return dispatch(httpClient<IRegistration, IRegistrationRes>('/v1/user/register', {
    ...data,
    lang: langValue,
  }, { context: 'common' }));
};

export const verifyEmailForRegistration = (token: string) => (_: AppDispatch<IVerifyRes>) => {
  return _(httpClient<object, IVerifyRes>(`/v1/verify-email/${token}`, undefined, { method: 'get' }));
};

export const loadDocument = () => (dispatch: AppDispatch<IDocumentRes>) => {
  dispatch(apiCall<object, IDocumentRes>(
    `/v1/document/get`,
    undefined,
    {
      method: 'get',
      // transformResponse: [dateTransformer(['issueDate', 'birthday'])],
    },
  ))
    .then((res) => {
      if (!res.doc) {
        return;
      }
      const { timezone } = res.doc;
      Tz.tz = new Timezone(timezone);

      dispatch(setUtcOffsetTimetable(Tz.offset()));
      dispatch(setDocument(new Document(res.doc)));
    });
};

export const _loadUserDocument = (userId: number) => (dispatch: AppDispatch<IDocumentUserProfile>) => {
  return dispatch(apiCall<object, IDocumentUserProfile>(
    `/v1/document/admin/${userId}`,
    undefined,
    {
      method: 'get',
      transformResponse: [dateTransformer(['issueDate', 'birthday'])],
    },
  ))
    .then(({ userDoc }) => dispatch(setDocumentUser(userDoc)));
};

export const removeUser = () => (dispatch: AppDispatch<any>) => {
  return dispatch(apiCall<object, any>(`/v1/user/remove`, undefined, { method: 'get' }));
};

export const removeUserAdmin = (id: number) => (dispatch: AppDispatch<any>) => {
  return dispatch(apiCall<object, any>(`/v1/user/remove-admin`, { id }, { method: 'post' }));
};

export const changeUserDocument = (values: IPersonalSubmit) => (dispatch: AppDispatch<IDocumentRes>) => {
  return dispatch(apiCall<IPersonalSubmit, IDocumentRes>(`/v1/document/update`, values, { method: 'post' }))
    .then(() => {
      dispatch(onUserDataChanged(new User({
        firstName: values.firstName,
        surname: values.surname,
        phone: values.phone,
      } as IUser)));
      dispatch(loadDocument());
    });
};

export const changeSpecificUserDocument = (values: IPersonalSubmit) => (dispatch: AppDispatch<IDocumentRes>) => {
  return dispatch(apiCall<IPersonalSubmit, IDocumentRes>(`/v1/document/admin/update`, values, { method: 'post' }))
    .then(() => {
      dispatch(apiCall<object, ResultItemRes<IBaseUser>>(`/v1/user/get/${values.userId}`, undefined, { method: 'get' }))
        .then(({ item }) => {
          const value = new Client({
            user: new User(item.user),
            status: new Status(item.status),
            document: new Document(item.document),
            online: item.online && new Online(item.online),
            statistic: item.statistic && new InitiatedStatistic(item.statistic),
          });

          dispatch(appendUserAction(value));
          dispatch(setUserAction(value));
        });
    });
};

export const updateUserDocumentsLock = (values: IOnSubmitUserLockForm) => (dispatch: AppDispatch<IOnSubmitUserLockForm>) => {
  return dispatch(apiCall<IOnSubmitUserLockForm, IDocumentRes>(`/v1/user/admin/blocked`, values, { method: 'post' }));
};

export const changeUserPassword = (values: IChangePassword) => (dispatch: AppDispatch<IChangePassword>) => {
  return dispatch(apiCall<IChangePassword, BaseCommandProps>(`/v1/user/change-password`, values, { method: 'post' }));
};

export const blockUser = (userId: number) => (dispatch: AppDispatch<UserIdReq>) => {
  return dispatch(apiCall<UserIdReq, BaseCommandProps>(`/v1/user/admin/block`, { userId }, { method: 'post' }));
};

export const unblockUser = (userId: number) => (dispatch: AppDispatch<UserIdReq>) => {
  return dispatch(apiCall<UserIdReq, BaseCommandProps>(`/v1/user/admin/unblock`, { userId }, { method: 'post' }));
};

export const changeUserPasswordByAdmin = (values: IChangePassword) => (dispatch: AppDispatch<IChangePassword>) => {
  const { newPassword: password, userId } = values;
  return dispatch(apiCall<object, BaseCommandProps>(`/v1/user/admin/change-password`, {
    password,
    userId,
  }, { method: 'post' }));
};

export const changeUserAlias = (values: IChangeAlias) => (dispatch: AppDispatch<IChangeAlias>) => {
  return dispatch(apiCall<IChangeAlias, BaseCommandProps>(`/v1/user/change-alias`, values, { method: 'post' }));
};

export const changeUserEmail = (values: IChangeEmail) => (dispatch: AppDispatch<IChangeEmail>) => {
  return dispatch(apiCall<IChangeEmail, BaseCommandProps>(`/v1/user/change-email`, values, { method: 'post' }));
};

export const changeUserEmailForAdmin = (values: IChangeEmail) => (dispatch: AppDispatch<IChangeEmail>) => {
  return dispatch(apiCall<IChangeEmail, BaseCommandProps>(`/v1/user/admin/change-email`, values, { method: 'post' }));
};

export const resendingCodeVerifyEmail = (token: string, lang: string, email: string) => (dispatch: AppDispatch<number>) => {
  return dispatch(apiCall<object, ResultItemRes<string>>(`/v1/user/re-sending-code-verify-email`, {
    token,
    lang,
    email,
  }, { method: 'post' })).then(({ item }) => setCookie(null, 'auth_token', item, { path: '/' }));
};

export const forgotPassword = (value: IForgotSubmitFormParams) => (_: AppDispatch<IForgotSubmitFormParams>) => {
  return _(apiCall<object, ResultListRes<IForgotSubmitFormParams>>(`/v1/user/password/get-token`, value, { method: 'post' }));
};

export const resetPassword = (value: IRestorePassword) => (_: AppDispatch<IRestorePassword>) => {
  return _(apiCall<object, ResultListRes<IRestorePassword>>(`/v1/user/password/restore`, value, { method: 'post' }));
};

export const verifyUserForAdmin = (userId: number) => (_: AppDispatch<IRestorePassword>) => {
  return _(apiCall<object, BaseCommandProps>(`/v1/user/verify-email/${userId}`, undefined, { method: 'get' }));
};
