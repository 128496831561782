import { CountryCurrencyReducer, ICountryCurrency } from '@modules/country-corrency/types';
import {
  CountryCurrencyActions,
  CREATE_COUNTRY_CURRENCY,
  createCountryCurrencyType, REMOVE_COUNTRY_CURRENCY, removeCountryCurrencyType,
  SET_COUNTRY,
  SET_COUNTRY_CURRENCY,
  SET_COUNTRY_CURRENCY_LIST,
  setCountryCurrencyListType,
  setCountryCurrencyType,
  setCountryType,
  UPDATE_COUNTRY_CURRENCY,
  updateCountryCurrencyType,
} from '@modules/country-corrency/actions';

const defaultState: CountryCurrencyReducer = {
  list: [],
  current: {} as ICountryCurrency,
  country: 'en',
};

const CountryCurrencyReducerMethod = (
  state: CountryCurrencyReducer = defaultState,
  action: CountryCurrencyActions,
): CountryCurrencyReducer => {
  if (action.type === SET_COUNTRY_CURRENCY_LIST) {
    return {
      ...state,
      list: (action as setCountryCurrencyListType).payload || [],
    };
  }

  if (action.type === SET_COUNTRY_CURRENCY) {
    return {
      ...state,
      current: (action as setCountryCurrencyType).payload || {},
    };
  }

  if (action.type === SET_COUNTRY) {
    return {
      ...state,
      country: (action as setCountryType).payload,
    };
  }

  if (action.type === CREATE_COUNTRY_CURRENCY) {
    const { list } = state;
    const item = (action as createCountryCurrencyType).payload;

    return {
      ...state,
      list: [
        item,
        ...list,
      ]
    }
  }

  if (action.type === UPDATE_COUNTRY_CURRENCY) {
    const { list } = state;
    const item = (action as updateCountryCurrencyType).payload;

    const index = list.findIndex(i => i.id === item.id);

    return {
      ...state,
      list: [
        ...list.slice(0, index),
        item,
        ...list.slice(index + 1),
      ],
    }
  }

  if (action.type === REMOVE_COUNTRY_CURRENCY) {
    const { list } = state;
    const id = (action as removeCountryCurrencyType).payload;

    const index = list.findIndex(i => i.id === id);

    return {
      ...state,
      list: [
        ...list.slice(0, index),
        ...list.slice(index + 1),
      ],
    }
  }

  return state;
};

export default CountryCurrencyReducerMethod;
