import { call, fork, all, takeEvery } from 'redux-saga/effects';
import { ConnectToSocket, SOCKET_CONNECT } from '@modules/sockets/actions';
import { parseCookies } from 'nookies';
import { createSocket } from './createSocket';
import { watchLogout } from './handlers/logout';
import { messageHandler } from './handlers/message';
import { messageNotifyHandler } from './handlers/notifyNewMessage';
import { translateHandler } from './handlers/translate';

type ConnectSocketAction = ReturnType<typeof ConnectToSocket>;

const connectSockets = function* (action: ConnectSocketAction) {
  const { payload: token } = action;
  const socket: SocketIOClient.Socket = yield call(createSocket, token);

  yield all([
    fork(watchLogout, socket),
    fork(messageHandler, socket),
    fork(messageNotifyHandler, socket),
    fork(translateHandler, socket),
  ]);
}

export const eventsSaga = function* eventSage() {
  const cookies = parseCookies();
  const { token } = cookies;
  if (token) {
    yield connectSockets(<ConnectSocketAction>{ type: SOCKET_CONNECT, payload: token })
  }

  yield takeEvery('SOCKET_CONNECT', connectSockets)
}
