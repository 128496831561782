import { AuthReducer } from '@modules/auth/types/reducerTypes';
import {
  AuthAction,
  SET_DOCUMENT, SET_DOCUMENT_USER, SET_VERIFY_DOC,
  SetDocumentAction, SetDocumentUserAction, SetVerifyDoc,
} from '@modules/auth/actions';
import { CLEAR_ALL } from '@modules/common/actions';
import { IDocument, IProfile } from '@modules/auth/types';
const isEmpty = require('lodash/isEmpty');

const defaultState: AuthReducer = {
  document: undefined,
  document_user: {} as IDocument & IProfile,
  documentVerify: false,
}

const AuthReducerMethod = (
  state: AuthReducer = defaultState,
  action: AuthAction,
): AuthReducer => {

  if (action.type === SET_DOCUMENT) {
    return {
      ...state,
      document: (action as SetDocumentAction).payload
    };
  }

  if (action.type === SET_DOCUMENT_USER) {
    if (isEmpty((action as SetDocumentUserAction).payload)) {
      return {
        ...state,
        document_user: (action as SetDocumentUserAction).payload,
      }
    }
    return {
      ...state,
      document_user: (action as SetDocumentUserAction).payload
    }
  }

  if (action.type === CLEAR_ALL) {
    return {
      ...state,
      document: undefined
    };
  }

  if (action.type === SET_VERIFY_DOC) {
    return {
      ...state,
      documentVerify: (action as SetVerifyDoc).payload
    };
  }

  return state;
}

export default AuthReducerMethod;
