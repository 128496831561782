import { CommonAction } from '@modules/common/actions';
import { ICountryCurrency } from '@modules/country-corrency/types';

export const SET_COUNTRY_CURRENCY_LIST = 'SET_COUNTRY_CURRENCY_LIST';
export const SET_COUNTRY_CURRENCY = 'SET_COUNTRY_CURRENCY';
export const SET_COUNTRY = 'SET_COUNTRY';
export const CREATE_COUNTRY_CURRENCY = 'CREATE_COUNTRY_CURRENCY';
export const UPDATE_COUNTRY_CURRENCY = 'UPDATE_COUNTRY_CURRENCY';
export const REMOVE_COUNTRY_CURRENCY = 'REMOVE_COUNTRY_CURRENCY';

export const setCountryCurrencyListAction = CommonAction<ICountryCurrency[]>(SET_COUNTRY_CURRENCY_LIST);
export const setCountryCurrencyAction = CommonAction<ICountryCurrency>(SET_COUNTRY_CURRENCY);
export const setCountryAction = CommonAction<string>(SET_COUNTRY);
export const createCountryCurrencyAction = CommonAction<ICountryCurrency>(CREATE_COUNTRY_CURRENCY);
export const updateCountryCurrencyAction = CommonAction<ICountryCurrency>(UPDATE_COUNTRY_CURRENCY);
export const removeCountryCurrencyAction = CommonAction<number>(REMOVE_COUNTRY_CURRENCY);

export type setCountryCurrencyListType = ReturnType<typeof setCountryCurrencyListAction>;
export type setCountryCurrencyType = ReturnType<typeof setCountryCurrencyAction>;
export type setCountryType = ReturnType<typeof setCountryAction>;
export type createCountryCurrencyType = ReturnType<typeof createCountryCurrencyAction>;
export type updateCountryCurrencyType = ReturnType<typeof updateCountryCurrencyAction>;
export type removeCountryCurrencyType = ReturnType<typeof removeCountryCurrencyAction>;

export type CountryCurrencyActions =
  setCountryCurrencyListType
  | setCountryCurrencyType
  | setCountryType
  | createCountryCurrencyType
  | updateCountryCurrencyType
  | removeCountryCurrencyType;
