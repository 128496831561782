import { CommonAction } from '@modules/common/actions';
import { IPersonal } from '@modules/personal/types';

export const LOAD_PERSONAL_INTERESTS = 'LOAD_PERSONAL_INTERESTS';
export const SET_INTERESTS_POP = 'SET_INTERESTS_POP';

export const setPersonalInterestsList = CommonAction<IPersonal[]>(LOAD_PERSONAL_INTERESTS);
export const setInterestsPop = CommonAction<boolean>(SET_INTERESTS_POP);

export type LoadPersonalInterestsType = ReturnType<typeof setPersonalInterestsList>;
export type SetInterestsPopType = ReturnType<typeof setInterestsPop>;

export type PersonalInterestsActions = LoadPersonalInterestsType | SetInterestsPopType;