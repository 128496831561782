import { IChatReducer } from '@modules/chat/types';
import {
  ChatActions,
  LOAD_DIALOGS, LOAD_FILES,
  LOAD_MESSAGE,
  loadDialogsType, loadFilesType,
  loadMessageType,
  NEW_MESSAGE, newMessageType, CHAT_SET_UNREAD, CHAT_READ_DIALOG, readDialogType,
} from '@modules/chat/actions';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

const defaultState: IChatReducer = {
  unread: false,
  dialogs: [],
  messages: {},
  files: [],
};

const ChatReducerMethod = (
  state: IChatReducer = defaultState,
  action: ChatActions,
): IChatReducer => {
  if (action.type === LOAD_DIALOGS) {
    return {
      ...state,
      dialogs: (action as loadDialogsType).payload || [],
    };
  }

  if (action.type === CHAT_READ_DIALOG) {
    const dialogId = (action as readDialogType).payload;
    const index = state.dialogs.findIndex(i => i.dialogId === dialogId);

    if (index !== -1) {
      const dialog = state.dialogs[index];
      if (!dialog.unread) {
        return state;
      }

      state.dialogs[index].unread = false;

      const unread = state.dialogs.some(i => i.unread);

      return {
        ...state,
        dialogs: [
          ...state.dialogs.slice(0, index),
          {
            ...dialog,
            unread: false,
          },
          ...state.dialogs.slice(index + 1),
        ],
        unread
      };
    }

    return state;
  }

  if (action.type === CHAT_SET_UNREAD) {
    return {
      ...state,
      unread: true,
    };
  }

  // TODO: Optimize plz
  if (action.type === LOAD_MESSAGE) {
    const { dialogId, messages } = (action as loadMessageType).payload;
    return {
      ...state,
      messages: {
        ...state.messages,
        [dialogId as number]: sortBy(uniqBy([
          ...(state.messages[dialogId] || []),
          ...messages,
        ], 'id'), (m) => m.id),
      },
    };
  }

  if (action.type === NEW_MESSAGE) {
    const item = (action as newMessageType).payload;
    return {
      ...state,
      messages: {
        ...state.messages,
        [item.dialogId]: sortBy(uniqBy([
          ...(state.messages[item.dialogId] || []),
          item,
        ], 'id'), (m) => m.id),
      },
    };
  }

  if (action.type === LOAD_FILES) {
    const files = (action as loadFilesType).payload;

    return {
      ...state,
      files,
    };
  }

  return state;
};

export default ChatReducerMethod;
