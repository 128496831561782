import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '@modules/translate/actions';
import { AppStorageType } from '@app-types/appStorageType';
import { TranslateFunction, TranslateFuncType } from '@modules/translate/components/TranslateFunction';
import { ITranslate, ITranslateContext, TransModifier, TransProps } from '@modules/translate/types/translate';
import { ILang } from '@modules/translate/types/lang';
import useSession from '@hooks/useSessions';
import { setEditTranslateKeyAction } from '@modules/translate/actions/actionAdmin';
import { TranslateContext } from '@contexts/translate';
import { loadTranslates } from '@commands/translate';

interface UseLocalProps {
  currentLanguage: string,
  translates: ITranslate,
  langs: ILang[]
  langsAdmin: ILang[]
  translate: (trans: string | TransProps, modifier?: TransModifier, strOnly?: boolean) => string
  edit: boolean
}

const useLocale = (): [TranslateFuncType, string, ITranslate, ILang[], (code: string) => void, ILang[], boolean] => {
  const dispatch = useDispatch();
  const { user } = useSession();
  const { translatesServer } = useContext<ITranslateContext>(TranslateContext);
  const _setLanguage: (code: string) => void = useCallback((code: string) => dispatch(setLanguage(code)), []);
  const setEditKey: (key: string) => void = useCallback((key: string) => dispatch(setEditTranslateKeyAction(key)), []);
  const loadTranslateAction: (key: string) => void = useCallback((key: string) => dispatch(loadTranslates(key)), []);


  const methods = useSelector<AppStorageType, UseLocalProps>(state => {
    const edit = user?.isAdmin && state.translate.admin.editTranslate;
    const __translates = { ...(state.translate.user.translates || {}), ...(translatesServer || {}) };

    const tr = (key: string, ignoreSkeleton: string, params?: boolean) => {
      if (!__translates[key]) {
        loadTranslateAction(key);
        if (typeof window !== 'object') {
          return `____${key}____`;
        }
        if (ignoreSkeleton || params) {
          return key;
        }
        const c =  <span className="loading-translate-key-item">{key}</span>;
        return {...c, toString: () => "Loading"};
      }

      return __translates[key];
    };

    const translate = useMemo(() => TranslateFunction(tr, edit, setEditKey), [edit, __translates]);
    return ({
      currentLanguage: state.translate.user.currentLanguage,
      translates: state.translate.user.translates,
      langs: state.translate.user.langs,
      langsAdmin: state.translate.admin.langsAdmin,
      translate,
      edit,
    });
  });

  return [methods.translate, methods.currentLanguage, methods.translates, methods.langs, _setLanguage, methods.langsAdmin, methods.edit];
};


export default useLocale;
