import { INoteReducer } from '@modules/notes/types';
import {
  CREATE_NOTES,
  CreateNotesActionType, DELETE_NOTES, DeleteNotesActionType,
  LOAD_NOTES,
  LoadNotesActionType,
  NotesActions, UPDATE_NOTES, UpdateNotesActionType,
} from '@modules/notes/actions';
import moment from 'moment';

const defaultState: INoteReducer = {
  list: {},
}

const NotesReducerMethod = (
  state: INoteReducer = defaultState,
  action: NotesActions,
): INoteReducer => {
  if (action.type === LOAD_NOTES) {
    const list = (action as LoadNotesActionType).payload;

    return {
      ...state,
      list,
    }
  }

  if (action.type === CREATE_NOTES) {
    const item = (action as CreateNotesActionType).payload;

    const key = moment(item.createdAt * 1000).utcOffset(0).startOf('month').unix()

    const group = state.list[key];

    let newList = state.list

    if (group) {
      newList[key] = [
        item,
        ...newList[key],
      ]

      return {
        ...state,
        list: newList,
      }
    }

    newList = {
      [key]: [ item ],
      ...newList
    }

    return {
      ...state,
      list: newList,
    }
  }

  if (action.type === UPDATE_NOTES) {
    const item = (action as UpdateNotesActionType).payload;

    const key = moment(item.createdAt * 1000).utcOffset(0).startOf('month').unix()

    let newList = state.list

    const index = newList[key].findIndex(i => i.id === item.id);

    newList[key] = [
      ...newList[key].slice(0, index),
      item,
      ...newList[key].slice(index + 1),
    ]

    return {
      ...state,
      list: newList,
    }
  }

  if (action.type === DELETE_NOTES) {
    const item = (action as DeleteNotesActionType).payload;

    const key = moment(item.createdAt * 1000).utcOffset(0).startOf('month').unix()

    const group = state.list[key];
    const index = group.findIndex(i => i.id === item.id);

    const newList = state.list

    newList[key] = [
      ...group.slice(0, index),
      ...group.slice(index + 1),
    ]

    return {
      ...state,
      list: newList,
    }
  }

  return state;
};

export default NotesReducerMethod;
