import { combineReducers } from 'redux';
import PersonalInterestsReducerMethod from '@modules/personal/reducers/interests';
import PersonalLangsReducerMethod from '@modules/personal/reducers/langs';

export interface IPersonal {
  id: number,
  title: string,
  meta: {
    image: string,
  }
}

export interface IPersonalReducer {
  list: IPersonal[],
  interestsPop?: boolean,
}

export interface IPersonalCombinedReducer {
  langs: IPersonalReducer,
  interests: IPersonalReducer,
}

export const PersonalReducer = combineReducers<IPersonalCombinedReducer>({
  langs: PersonalLangsReducerMethod,
  interests: PersonalInterestsReducerMethod,
});