import { CommonAction } from '@modules/common/actions';
import { IList } from '@modules/lists/types';

export const SET_LIST_VALUE = 'SET_LIST_VALUE';

export const setListValueAction = CommonAction<IList>(SET_LIST_VALUE);

export type setListValueActionType = ReturnType<typeof setListValueAction>;

export type ListActions = setListValueActionType;
