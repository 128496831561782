import dynamic from 'next/dynamic';
import { FunctionComponent, useEffect, useState } from 'react';
import { ComponentProps } from '@containers/App/user/classWrap/redux';
const ClassView = dynamic(() => import('@containers/App/user/class'), { ssr: false });

const ClassWrap: FunctionComponent<ComponentProps> = ({ startSubject }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const sub = startSubject.subscribe((val) => setVisible(val));
    return () => {
      sub.unsubscribe();
    }
  }, [])

  if (!visible) {
    return null;
  }

  return <ClassView />;
}

export default ClassWrap;
