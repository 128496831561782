import { ITwoFaReducer } from '@modules/twoFa/types';
import {
  SET_EXIST_EMAIL_TWO_FA,
  SET_EXIST_TWO_FA_GOOGLE, SET_GOOGLE_QR_URL, setExistTwoFaEmailType,
  setExistTwoFaGoogleType,
  setGoogleQrUrlType,
  TwoFaActions,
} from '@modules/twoFa/actions';

const defaultState: ITwoFaReducer = {
  url: undefined,
  visible: false,
  exist: false,
  checkEmail: false,
};

const TwoFaReducer = (
  state: ITwoFaReducer = defaultState,
  action: TwoFaActions,
): ITwoFaReducer => {
  if (action.type === SET_EXIST_TWO_FA_GOOGLE) {
    return {
      ...state,
      exist: (action as setExistTwoFaGoogleType).payload
    };
  }

  if (action.type === SET_GOOGLE_QR_URL) {
    return {
      ...state,
      url: (action as setGoogleQrUrlType).payload
    };
  }
  if (action.type === SET_EXIST_EMAIL_TWO_FA) {
    return {
      ...state,
      checkEmail: (action as setExistTwoFaEmailType).payload
    };
  }

  return state;
};

export default TwoFaReducer;
