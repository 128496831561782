import { IDocument, IProfile } from '@modules/auth/types';
import { clearData, CommonAction } from '@modules/common/actions';

export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_DOCUMENT_USER = 'SET_DOCUMENT_USER';
export const SET_VERIFY_DOC = 'SET_VERIFY_DOC';

export type AuthAction = SetDocumentAction | SetDocumentUserAction | SetVerifyDoc;

export const setDocument = CommonAction<IDocument>(SET_DOCUMENT)
export const setDocumentUser = CommonAction<IDocument & IProfile>(SET_DOCUMENT_USER)
export const setVerifyDoc = CommonAction<boolean>(SET_VERIFY_DOC)

export type SetDocumentAction = ReturnType<typeof setDocument>
export type SetDocumentUserAction = ReturnType<typeof setDocumentUser>
export type SetVerifyDoc = ReturnType<typeof setVerifyDoc>

export const logout = clearData;
