import Timezone, { ITimezone } from '@modules/timezone/types';
import moment, { Moment } from 'moment';

export default class Tz {
  static tz: Timezone | undefined;

  static setTz(tz: ITimezone, lang: string) {
    moment.locale(lang);
    Tz.tz = new Timezone(tz);
  }

  static offset() {
    if (!Tz.tz) {
      return moment().utcOffset() * 60;
    }

    return Tz.tz.unixOffset;
  }

  static mOffset() {
    if (!Tz.tz) {
      return moment().utcOffset();
    }

    return Tz.tz.offset;
  }

  static pastDate(date: number) {
    return Tz.utc().unix() > date;
  }

  static time() {
    return moment().utcOffset(Tz.mOffset());
  }

  static timeWithTz(tz: Timezone) {
    return moment().utcOffset(tz.offset);
  }

  static startDay() {
    return moment().utcOffset(Tz.mOffset()).startOf('day').add(Tz.mOffset(), 'minutes').utc();
  }

  static endDay() {
    return moment().utcOffset(Tz.mOffset()).endOf('day');
  }

  static utc() {
    return moment().utc();
  }

  static unixUtc(time: number) {
    return moment.unix(time).utc();
  }

  static unix(time: number): Moment {
    return moment.unix(time).utcOffset(Tz.offset() / 60);
  }

  static calendar(to: number): string {
    return Tz.unix(to).calendar(Tz.time()).split(' ')[0];
  }
}
