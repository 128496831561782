import { CommonAction } from '@modules/common/actions';
import { IContextStorage, IPayloadContextStorage } from '@modules/context-storage/types';

export const SET_CONTEXT_STORAGE = 'SET_CONTEXT_STORAGE';
export const CREATE_CONTEXT = 'CREATE_CONTEXT';
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT';
export const REMOVE_CONTEXT = 'REMOVE_CONTEXT';

export const setContextStorageActionOne = CommonAction<IPayloadContextStorage<any>>(SET_CONTEXT_STORAGE);
export const createContextAction = CommonAction<IContextStorage<any>>(CREATE_CONTEXT);
export const updateContextAction = CommonAction<IContextStorage<any>>(UPDATE_CONTEXT);
export const removeContextAction = CommonAction<IContextStorage<any>>(REMOVE_CONTEXT);

export const setContextStorageAction = <P>(value: IPayloadContextStorage<P>) => {
  return CommonAction<IPayloadContextStorage<P>>(SET_CONTEXT_STORAGE)(value);
};

export type setContextStorageType = ReturnType<typeof setContextStorageActionOne>;
export type createContextType = ReturnType<typeof createContextAction>;
export type updateContextType = ReturnType<typeof updateContextAction>;
export type removeContextType = ReturnType<typeof removeContextAction>;

export type ContextStorageActions = setContextStorageType | createContextType | updateContextType | removeContextType;
