interface ITree<T> {
  id?: number,
  pid: number | null,
  children: T[],
  key: string,
}

export const createTree = <T extends ITree<T>>(listSection: T[]): T[] => {
  let list: T[] = [];
  let listWithPid: T[] = [];

  (listSection || []).map(i => {
    if (!i.pid) {
      list = [...list, i];
    } else {
      listWithPid = [...listWithPid, i];
    }
  });

  create(list, listWithPid);
  createKeys(list);

  return list;
}

const create = <T extends ITree<T>>(list: T[], listWithPid: T[]) => {
  if (!list) return;

  (list || []).map(i => {
    let emptyArr: T[] = [];
    (listWithPid || []).map(x => {
      if (i.id === x.pid) {
        i.children = [
          ...(i.children || []),
          x,
        ];
        emptyArr.push(x);
      }
    });

    (emptyArr || []).map(itemArr => {
      let index = listWithPid.indexOf(itemArr);
      listWithPid.splice(index, 1);
    });

    if (i.children) {
      create(i.children, listWithPid);
    }
  });
};

const createKeys = <T extends ITree<T>>(list: T[], ind: number = 0, keyPrev: string = '0') => {
  list.forEach((item, index) => {
    if (item.children) {
      item.key = String(item.id);
      createKeys(item.children, index, item.key);
    }
    item.key = String(item.id);
  });
};
