import { TeacherStage } from '@modules/teacher-stage/types';
import {
  CREATE_TEACHER_STAGE, createTeacherStageType, REMOVE_TEACHER_STAGE, removeTeacherStageType,
  SET_TEACHER_STAGE_LIST,
  setTeacherStageListType,
  TeacherStageActions,
} from '@modules/teacher-stage/actions';

const TeacherStageReducer = (
  state: TeacherStage[] = [],
  action: TeacherStageActions,
): TeacherStage[] => {
  if (action.type === SET_TEACHER_STAGE_LIST) {
    return (action as setTeacherStageListType).payload || [];
  }

  if (action.type === CREATE_TEACHER_STAGE) {
    const item = (action as createTeacherStageType).payload;

    return [
      item,
      ...state,
    ];
  }

  if (action.type === REMOVE_TEACHER_STAGE) {
    const id = (action as removeTeacherStageType).payload;
    const item = state.find(i => i.id === id);

    if (!item) {
      return state;
    }

    const index = state.findIndex(i => i.id === id);

    return [
      ...state.slice(0, index),
      ...state.slice(index + 1),
    ];
  }

  return state;
};

export default TeacherStageReducer;
