import moment, { Moment } from 'moment';

export interface ITimezone {
  hours: string,
  minutes: string
}

class Timezone {
  private value: ITimezone;
  private UTC?: string;

  constructor(value: ITimezone) {
    this.value = { hours: value?.hours || '0', minutes: value?.minutes || '0' };
    this.UTC = undefined;
  }

  get unixOffset(): number {
    const h = Number(this.value.hours);
    const m = Number(this.value.minutes);
    return Math.sign(h) * (Math.abs(h * 3600) + Math.abs(m * 60));
  }

  get offset(): number {
    const h = Number(this.value.hours);
    const m = Number(this.value.minutes);
    return Math.sign(h) * (Math.abs(h * 60) + Math.abs(m));
  }

  get time(): Moment {
    return moment().utcOffset(this.unixOffset);
  }

  get UtcViwe(): string {
    return `UTC ${Math.sign(Number(this.value.hours)) === 1 ? '+' + parseInt(this.value.hours) : this.value.hours}${parseInt(this.value.minutes) > 0 ? ':' + this.value.minutes : ''}`;
  }

  // returnTimeZone = (timeZoneOffset: ITimezone) => {
  //   if (timeZoneOffset) {
  //     timeZoneOffset = JSON.parse(timeZoneOffset);
  //   } else {
  //     timeZoneOffset = { hours: 0, minutes: 0 };
  //   }
  //   return timeZoneOffset;
  // }


  getLocalTime = (lang: string = 'en') => {
    let hours = parseInt(String(new Date().getUTCHours())) + parseInt(this.value.hours);
    let minutes = parseInt(String(new Date().getUTCMinutes())) + parseInt(this.value.minutes);
    const date = new Date(new Date().setHours(hours, minutes));

    let options = {
      hour: 'numeric' as 'numeric',
      minute: 'numeric' as 'numeric',
    };
    return new Intl.DateTimeFormat(lang !== 'en' ? 'ru-Ru' : 'en-En', options).format(date);
  };

  getRangeTime = (time: number, duration: number) => {
    const start = moment.unix(time).format('HH:mm');
    const finish = moment.unix(time).add(duration * 30, 'minutes').format('HH:mm');
    return { start, finish };
  };

  getDate = (date: number, format: string = 'YYYY-MM-DD') => {
    return moment.unix(date).format(format);
  };

}

export default Timezone;
