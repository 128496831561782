import { Deposit, ICurrency } from '@modules/deposit/types';
import { CommonAction } from '@modules/common/actions';

export const SET_DEPOSIT_LIST = 'SET_DEPOSIT_LIST';
export const SET_DEPOSIT_COUNT = 'SET_DEPOSIT_COUNT';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_ORDER = 'SET_ORDER';
export const UPDATE_DEPOSIT_LIST = 'UPDATE_DEPOSIT_LIST';

export const setDepositList = CommonAction<Deposit[]>(SET_DEPOSIT_LIST);
export const setDepositCount = CommonAction<number>(SET_DEPOSIT_COUNT);
export const setCurrency = CommonAction<ICurrency>(SET_CURRENCY);
export const setOrder = CommonAction<string>(SET_ORDER);
export const setItemDepositList = CommonAction<Deposit>(UPDATE_DEPOSIT_LIST);

export type SetDepositListType = ReturnType<typeof setDepositList>
export type SetDepositCountType = ReturnType<typeof setDepositCount>
export type SetCurrencyType = ReturnType<typeof setCurrency>
export type SetOrderType = ReturnType<typeof setOrder>
export type setItemDepositListType = ReturnType<typeof setItemDepositList>


export type DepositAction =
  SetDepositListType
  | SetCurrencyType
  | SetOrderType
  | SetDepositCountType
  | setItemDepositListType
