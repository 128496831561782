import { ILang } from '@modules/translate/types/lang';
import { ITranslateListAdmin } from '@modules/translate/types/translateListAdmin';
import { CommonAction } from '@modules/common/actions';

export const SET_LANGUAGE_LIST_ADMIN = 'SET_LANGUAGE_LIST_ADMIN';
export const SET_TRANSLATE_LIST_ADMIN = 'SET_TRANSLATE_LIST_ADMIN';
export const CREATE_LANG_ITEM = 'CREATE_LANG_ITEM';
export const CREATE_TRANSLATE_LIST_ITEM_ADMIN = 'CREATE_TRANSLATE_LIST_ITEM_ADMIN';
export const EXPORT_TRANSLATE_ITEM_ADMIN = 'EXPORT_TRANSLATE_ITEM_ADMIN';
export const REMOVE_EXPORT_TRANSLATE_ITEM_ADMIN = 'REMOVE_EXPORT_TRANSLATE_ITEM_ADMIN';
export const UPDATE_LANG_ITEM = 'UPDATE_LANG_ITEM';
export const UPDATE_TRANSLATE_LIST_ITEM_ADMIN = 'UPDATE_TRANSLATE_LIST_ITEM_ADMIN';
export const SET_BASE_URL = 'SET_BASE_URL';
export const REMOVE_TRANSLATE = 'REMOVE_TRANSLATE';
export const SET_TRANSLATE_COUNT = 'SET_TRANSLATE_COUNT';
export const SET_EDIT_TRANSLATE = 'SET_EDIT_TRANSLATE';
export const SET_EDIT_TRANSLATE_KEY = 'SET_EDIT_TRANSLATE_KEY';
export const SET_LANGUAGE_ADMIN_LIST = 'SET_LANGUAGE_ADMIN_LIST';

export interface SetLanguageListAdminAction {
  type: string,
  payload: {
    langs: ILang[]
  },
}

export interface SetTranslateListAdminAction {
  type: string,
  payload: {
    list: ITranslateListAdmin[]
  },
}


export interface UpdateTranslateListItemAdminAction {
  type: string,
  payload: {
    item: ITranslateListAdmin
  },
}

export interface CreateTranslateListItemAdminAction {
  type: string,
  payload: {
    item: ITranslateListAdmin
  },
}

export interface ExportTranslateListAdminAction {
  type: string,
  payload: {
    item: number
  },
}

export interface RemoveExportTranslateListAdminAction {
  type: string,
  payload: {
    item: number
  },
}

export interface CreateLangItemAction {
  type: string,
  payload: {
    item: ILang
  },
}

export interface UpdateLangItemAction {
  type: string,
  payload: {
    item: ILang
  },
}

export interface SetBaseUrlAction {
  type: string,
  payload: {
    url: string
  },
}

export type TranslateActionAdmin =
  SetLanguageListAdminAction
  | SetTranslateListAdminAction
  | SetBaseUrlAction
  | UpdateTranslateListItemAdminAction
  | CreateTranslateListItemAdminAction
  | ExportTranslateListAdminAction
  | CreateLangItemAction
  | RemoveTranslateItem
  | setTranslateCountAction
  | setEditTranslateKeyType
  | setEditTranslateType
  | setLanguageListAdminType;


export const setLanguageListAdmin = (langs: ILang[]) => ({
  type: SET_LANGUAGE_LIST_ADMIN,
  payload: { langs },
});

export const setTranslateListAdmin = (list: ITranslateListAdmin[]) => ({
  type: SET_TRANSLATE_LIST_ADMIN,
  payload: { list },
});


export const exportTranslateItem = (item: number) => ({
  type: EXPORT_TRANSLATE_ITEM_ADMIN,
  payload: { item },
});

export const removeExportTranslateItem = (item: number) => ({
  type: REMOVE_EXPORT_TRANSLATE_ITEM_ADMIN,
  payload: { item },
});

export const createLangItem = (item: ILang) => ({
  type: CREATE_LANG_ITEM,
  payload: { item },
});

export const updateLangItem = (item: ILang) => ({
  type: UPDATE_LANG_ITEM,
  payload: { item },
});


export const setBaseUrl = (url: string) => ({
  type: SET_BASE_URL,
  payload: { url },
});


export const updateTranslateListItemAdmin = (item: ITranslateListAdmin) => ({
  type: UPDATE_TRANSLATE_LIST_ITEM_ADMIN,
  payload: { item },
});

export const createTranslateListItemAdmin = (item: ITranslateListAdmin) => ({
  type: CREATE_TRANSLATE_LIST_ITEM_ADMIN,
  payload: { item },
});

export const removeTranslateItem = CommonAction<number>(REMOVE_TRANSLATE);
export const setLanguageListAdminAction = CommonAction<ILang[]>(SET_LANGUAGE_ADMIN_LIST);
export const setTranslateCount = CommonAction<number>(SET_TRANSLATE_COUNT);
export const setEditTranslateAction = CommonAction<boolean>(SET_EDIT_TRANSLATE);
export const setEditTranslateKeyAction = CommonAction<string | undefined>(SET_EDIT_TRANSLATE_KEY);

export type setTranslateCountAction = ReturnType<typeof setTranslateCount>
export type RemoveTranslateItem = ReturnType<typeof removeTranslateItem>;
export type setLanguageListAdminType = ReturnType<typeof setLanguageListAdminAction>;
export type setEditTranslateKeyType = ReturnType<typeof setEditTranslateKeyAction>;
export type setEditTranslateType = ReturnType<typeof setEditTranslateAction>;
