import { IBlog, IBlogReducer } from '@modules/information/blog/types';
import {
  BlogActions, CREATE_BLOG, CreateBlogType, DELETE_BLOG, DeleteBlogType, SET_BLOG_COUNT,
  SET_BLOG_ITEM,
  SET_BLOG_LIST, SetBlogCountType,
  SetBlogItemType,
  SetBlogListType, UPDATE_BLOG, UpdateBlogType,
} from '@modules/information/blog/actions';

const defaultState: IBlogReducer = {
  list: [],
  item: {} as IBlog,
  count: 0,
};

const BlogReducerMethod = (
  state: IBlogReducer = defaultState,
  action: BlogActions,
): IBlogReducer => {
  if (action.type === SET_BLOG_LIST) {
    const items = (action as SetBlogListType).payload;

    return {
      ...state,
      list: [
        ...items || [],
      ],
    };
  }

  if (action.type === SET_BLOG_ITEM) {
    const item = (action as SetBlogItemType).payload;

    return {
      ...state,
      item,
    };
  }

  if (action.type === CREATE_BLOG) {
    const item = (action as CreateBlogType).payload;

    return {
      ...state,
      list: [
        item,
        ...state.list,
      ],
    };
  }

  if (action.type === UPDATE_BLOG) {
    const item = (action as UpdateBlogType).payload;
    const index = state.list.findIndex(i => i.id === item.id);

    return {
      ...state,
      list: [
        ...state.list.slice(0, index),
        item,
        ...state.list.slice(index + 1),
      ],
    };
  }

  if (action.type === DELETE_BLOG) {
    const item = (action as DeleteBlogType).payload;
    const index = state.list.findIndex(i => i.id === item);

    return {
      ...state,
      list: [
        ...state.list.slice(0, index),
        ...state.list.slice(index + 1),
      ],
    };
  }

  if (action.type === SET_BLOG_COUNT) {
    const count = (action as SetBlogCountType).payload;

    return {
      ...state,
      count,
    };
  }

  return state;
}

export default BlogReducerMethod;