import {
  SET_LANGUAGE_LIST,
  SET_LANGUAGE,
  TranslateAction,
  SetLanguageListAction,
  SetLanguageAction,
  SET_TRANSLATE_LIST,
  SetTranslateListAction,
  SetCachetAction,
  SET_CACHE, UPDATE_TRANSLATE_LIST_SOCKET, updateTranslateListType,
} from '@modules/translate/actions';
import { TranslateReducer } from '@modules/translate/types/transReducer';
import cloneDeep from 'lodash/cloneDeep';

const defaultState: TranslateReducer = {
  currentLanguage: 'en',
  langs: [],
  translates: {},
  cache: 0,
};

const UserReducer = (
  state: TranslateReducer = defaultState,
  action: TranslateAction,
): TranslateReducer => {
  if (action.type === SET_LANGUAGE) {
    return {
      ...state,
      currentLanguage: (action as SetLanguageAction).payload.code,
    };
  }

  if (action.type === SET_LANGUAGE_LIST) {
    return {
      ...state,
      langs: (action as SetLanguageListAction).payload.langs,
    };
  }

  if (action.type === SET_TRANSLATE_LIST) {
    return {
      ...state,
      translates: {
        ...(action as SetTranslateListAction).payload.translates,
        ...state.translates,
      },
    };
  }

  if (action.type === SET_CACHE) {
    return {
      ...state,
      cache: (action as SetCachetAction).payload.cache,
    };
  }

  if (action.type === UPDATE_TRANSLATE_LIST_SOCKET) {
    const { translates, currentLanguage, ...fields } = state;
    const item = (action as updateTranslateListType).payload;
    const objValue = item.values.find(i => i.code === currentLanguage);

    if (!objValue) {
      return state;
    }

    const newState = Object.assign({}, { ...fields, currentLanguage });
    const newTranslates = Object.assign(translates, { [item.key]: objValue.value });
    return Object.assign(newState, { translates: newTranslates });
  }

  return state;
};

export default UserReducer;
