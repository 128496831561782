import io from "socket.io-client";

export const createSocket = (token: string): Promise<SocketIOClient.Socket> => {
  const socket = io.connect(process.env.NEXT_PUBLIC_SOCKET_URL || '', {
    reconnection: true,
    autoConnect: true,
    rememberUpgrade: true,
  });

  return new Promise<SocketIOClient.Socket>((resolve) => {
    socket.on('connect', () => {
      socket.emit('add user', { token });
      resolve(socket);
    });

    socket.on('disconnect', (message: any) => {
      console.log('socket disconnected from messages sockets', message);
    });

    socket.on('connect_error', (error: string) => {
      console.log('socket connect_error from messages sockets', error);
    });

    socket.on('error', (error: string) => {
      console.log('socket error', error);
    });
  });
}
