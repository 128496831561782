import { takeEvery } from 'redux-saga/effects';
import { CLEAR_ALL } from '@modules/common/actions';

const logoutHandler = function* (socket: SocketIOClient.Socket) {
  socket.disconnect();
}

export const watchLogout = function* (socket: SocketIOClient.Socket) {
  takeEvery(CLEAR_ALL, logoutHandler, socket);
}
