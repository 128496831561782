import { PurchaseAction, LOAD_PURCHASE_LESSON, LOAD_PURCHASE_HISTORY } from '@modules/investments/purchases/actions';
import { IPurchaseReducer } from '@modules/investments/purchases/types/purchaseReducer';
import { setPurchaseLessonAction, setPurchaseHistoryAction } from '@modules/investments/purchases/types/purchase';

const defaultState: IPurchaseReducer = {
  lesson: [],
  history: [],
};

const PurchaseReducerMethod = (
  state: IPurchaseReducer = defaultState,
  action: PurchaseAction,
): IPurchaseReducer => {
  if (action.type === LOAD_PURCHASE_LESSON) {
    return {
      ...state,
      lesson: (action as setPurchaseLessonAction).payload,
    };
  }
  if (action.type === LOAD_PURCHASE_HISTORY) {
    return {
      ...state,
      history: (action as setPurchaseHistoryAction).payload,
    };
  }

  return state;
};

export default PurchaseReducerMethod;
