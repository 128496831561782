import {
  addCategoryType,
  InvestmentsCategory,
  InvestmentsCategoryAction, removeCategoryType,
  setInvestmentsCategoryType, setCategoryActionType, CategoryAction,
} from '@modules/investments/category/types/category';
import {
  CREATE_OR_UPDATE_CATEGORY,
  REMOVE_CATEGORY,
  SET_INVESTMENTS_CATEGORY,
  SET_CATEGORY,
  CLEAR_CATEGORY,
} from '@modules/investments/category/actions';

export interface ICategoryMethod {
  item?: InvestmentsCategory;
}

const defaultState: ICategoryMethod = {
  item: {} as InvestmentsCategory,
};

export const CategoryMethodReducer = (
  state: ICategoryMethod = defaultState,
  action: CategoryAction,
): ICategoryMethod => {
  if (action.type === SET_CATEGORY) {
    return { item: (action as setCategoryActionType).payload };
  }

  if (action.type === CLEAR_CATEGORY) {
    return { item: undefined };
  }

  return state;
};

const InvestmentCategoryMethod = (
  state: InvestmentsCategory[] = [],
  action: InvestmentsCategoryAction,
): InvestmentsCategory[] => {
  if (action.type === SET_INVESTMENTS_CATEGORY) {
    return (action as setInvestmentsCategoryType).payload || [];
  }

  if (action.type === CREATE_OR_UPDATE_CATEGORY) {
    const item = (action as addCategoryType).payload;
    const itemFind = state.find(i => i.id === item.id);

    if (!itemFind) {
      return [
        (action as addCategoryType).payload,
        ...state,
      ];
    }
    const index = state.findIndex(i => i.id === item.id);

    return [
      ...state.slice(0, index),
      item,
      ...state.slice(index + 1),
    ];
  }

  if (action.type === REMOVE_CATEGORY) {
    const id = (action as removeCategoryType).payload;
    const item = state.find(i => i.id === id);

    if (!item) {
      return state;
    }

    const index = state.findIndex(i => i.id === id);

    return [
      ...state.slice(0, index),
      ...state.slice(index + 1),
    ];
  }

  return state;
};

export default InvestmentCategoryMethod;
