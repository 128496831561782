import { CommonAction } from '@modules/common/actions';
import { InitiatedStatistic } from '@modules/schedule';
import { Schedule, ScheduleSlot } from '@modules/schedule/schedule-type';
import { StageProduct } from '@modules/investments/products/types/product';
import { StageLesson } from '@modules/investments/products/types/stage';

export const SCHEDULE_SET_LESSON_STATISTIC = 'SCHEDULE_SET_LESSON_STATISTIC';
export const SCHEDULE_SET_LESSON_STATISTIC_PRODUCT = 'SCHEDULE_SET_LESSON_STATISTIC_PRODUCT';
export const SCHEDULE_CLEAR_TEACHER_TIMETABLE = 'SCHEDULE_CLEAR_TEACHER_TIMETABLE';
export const SCHEDULE_SET_UTC_OFFSET = 'SCHEDULE_SET_UTC_OFFSET';
export const SCHEDULE_SET_TEACHER_TIMETABLE = 'SCHEDULE_SET_TEACHER_TIMETABLE';
export const SCHEDULE_SET_NEXT_LESSON = 'SCHEDULE_SET_NEXT_LESSON';
export const SCHEDULE_SET_CURRENT_LESSON = 'SCHEDULE_SET_CURRENT_LESSON';
export const SCHEDULE_REPLACE_TEACHER_SLOTS = 'SCHEDULE_REPLACE_TEACHER_SLOTS';
export const SCHEDULE_DETACH_SLOT = 'SCHEDULE_DETACH_SLOT';
export const SHOW_SCHEDULE_AUTOFILL = 'SHOW_SCHEDULE_AUTOFILL';

export const setLessonStatistic = CommonAction<InitiatedStatistic>(SCHEDULE_SET_LESSON_STATISTIC);
export const setLessonStatisticProduct = CommonAction<StageProduct<StageLesson>>(SCHEDULE_SET_LESSON_STATISTIC_PRODUCT);
export const clearTeacherTimetable = CommonAction<void>(SCHEDULE_CLEAR_TEACHER_TIMETABLE);
export const setUtcOffsetTimetable = CommonAction<number>(SCHEDULE_SET_UTC_OFFSET);
export const setTeacherTimetable = CommonAction<Schedule>(SCHEDULE_SET_TEACHER_TIMETABLE);
export const replaceTeacherSlots = CommonAction<ScheduleSlot[]>(SCHEDULE_REPLACE_TEACHER_SLOTS);
export const setLessonNextLesson = CommonAction<ScheduleSlot | undefined>(SCHEDULE_SET_NEXT_LESSON);
export const setLessonCurrentLesson = CommonAction<ScheduleSlot | undefined>(SCHEDULE_SET_CURRENT_LESSON);
export const detachSlot = CommonAction<number>(SCHEDULE_DETACH_SLOT);

export const showScheduleAutofill = (data: boolean) => ({
  type: SHOW_SCHEDULE_AUTOFILL,
  payload: data,
});

export type ScheduleSetStatisticType =
  ReturnType<typeof setLessonStatistic>;
export type ScheduleSetProductType =
  ReturnType<typeof setLessonStatisticProduct>;
export type ClearTeacherTimetableType =
  ReturnType<typeof clearTeacherTimetable>;
export type SetTeacherTimetableType =
  ReturnType<typeof setTeacherTimetable>;
export type SetUtcOffsetType =
  ReturnType<typeof setUtcOffsetTimetable>;
export type SetLessonNextLessonType =
  ReturnType<typeof setLessonNextLesson>;
export type SetLessonCurrentLessonType =
  ReturnType<typeof setLessonCurrentLesson>;
export type ReplaceTeacherSlotsType =
  ReturnType<typeof replaceTeacherSlots>;
export type DetachSlotType =
  ReturnType<typeof detachSlot>;
export type ShowScheduleAutofill =
  ReturnType<typeof showScheduleAutofill>;

export type ScheduleAction =
  DetachSlotType
  | SetLessonCurrentLessonType
  | ReplaceTeacherSlotsType
  | SetLessonNextLessonType
  | SetUtcOffsetType
  | ClearTeacherTimetableType
  | SetTeacherTimetableType
  | ScheduleSetStatisticType
  | ScheduleSetProductType
  | ShowScheduleAutofill;
