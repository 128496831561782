import { CommonAction } from '@modules/common/actions';

export const SET_SELECTED_TEACHER_ADMIN_SCHEDULE = 'SET_SELECTED_TEACHER_ADMIN_SCHEDULE';
export const SET_SELECTED_STUDENT_ADMIN_SCHEDULE = 'SET_SELECTED_STUDENT_ADMIN_SCHEDULE';
export const SET_ADMIN_TIMEZONE_ADMIN_SCHEDULE = 'SET_ADMIN_TIMEZONE_ADMIN_SCHEDULE';
export const CLEAR_ADMIN_SCHEDULE = 'CLEAR_ADMIN_SCHEDULE';

export const selectTeacher = CommonAction<number>(SET_SELECTED_TEACHER_ADMIN_SCHEDULE)
export const selectStudent = CommonAction<number>(SET_SELECTED_STUDENT_ADMIN_SCHEDULE)
export const clearAdminSchedule = CommonAction<void>(CLEAR_ADMIN_SCHEDULE)
export const setTimezone = CommonAction<number>(SET_ADMIN_TIMEZONE_ADMIN_SCHEDULE)

export type SelectTeacherType = ReturnType<typeof selectTeacher>
export type SelectStudentType = ReturnType<typeof selectStudent>
export type SetAdminTimezoneType = ReturnType<typeof setTimezone>
export type ClearAdminScheduleType = ReturnType<typeof clearAdminSchedule>

export type AdminAction = ClearAdminScheduleType | SelectTeacherType | SelectStudentType | SetAdminTimezoneType;
