import { BaseCommandProps } from '@app-types/CommonTypes';
import { IReactSelectChange } from '@modules/common/types';
import { IPersonal } from '@modules/personal/types';
import { ITimezone } from '@modules/timezone/types';
import { ILangLevel } from '@modules/context-storage/types';
import { parseYouTubeLink } from '@utils/youtube';
import moment from 'moment';

export interface IProfile {
  alias?: string
  parent_id?: number
  email: string
  role: string
  verified: boolean
  firstName?: string
  middleName?: string
  surname?: string
  phone?: string
  lang: string
  telegram?: string
  weight?: number
  createdAt?: string,
  deleted: boolean
  blocked: boolean
  path?: number[]
}

export interface ISignIn {
  email: string;
  password: string;
  remember?: boolean;
  lang?: string;
  country: string
}

export interface IChangePassword {
  userId?: number;
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

export interface UserIdReq {
  userId: number;
}

export interface IChangeAlias {
  alias: string;
  code: string;
}

export interface IChangeEmail {
  email: string;
  userId?: number;
}

export interface ITokenRes extends BaseCommandProps {
  token: string;
}

export interface ISignInRes extends ITokenRes {
  profile: IProfile;
}

export interface IRegistration {
  email: string
  password: string
  password_new_repeated?: string
  parent?: string
  goal: string,
  city: string,
  lang: string,
  firstName: string,
  phone: string,

  organization: string,
  timezone: object,
  tariff: string,
  role: string,
  country: string,
}

export interface IRegistrationRes extends BaseCommandProps {
  code: string;
}

export interface IDocument {
  id?: number,
  userId?: number,
  name: string,
  surname: string,
  image: string,
  birthday: string,
  sex: string | IReactSelectChange<string, string>,
  country: string,
  city: string,
  timezone: ITimezone,
  nativeLangs: IPersonal[] | number[],
  extraLangs: IPersonal[] | number[],
  interests: IPersonal[] | number[],
  video: string,
  shortVideo: string
  langType: string,
  expectations: string,
  experience: string,
  qualification: string,
  about: string,
  goal: string,
  langLevel: ILangLevel
}

export class Document implements IDocument {
  about: string;
  birthday: string;
  city: string;
  country: string;
  expectations: string;
  experience: string;
  extraLangs: IPersonal[] | number[];
  goal: string;
  id?: number;
  image: string;
  interests: IPersonal[] | number[];
  langLevel: ILangLevel;
  langType: string;
  name: string;
  nativeLangs: IPersonal[] | number[];
  qualification: string;
  sex: string | IReactSelectChange<string, string>;
  shortVideo: string;
  surname: string;
  timezone: ITimezone;
  userId?: number;
  video: string;

  constructor(obj: IDocument) {
    this.about = obj.about;
    this.birthday = obj.birthday;
    this.city = obj.city;
    this.country = obj.country;
    this.expectations = obj.expectations;
    this.experience = obj.experience;
    this.extraLangs = obj.extraLangs;
    this.goal = obj.goal;
    this.id = obj.id;
    this.image = obj.image;
    this.interests = obj.interests;
    this.langLevel = obj.langLevel;
    this.langType = obj.langType;
    this.name = obj.name;
    this.nativeLangs = obj.nativeLangs;
    this.qualification = obj.qualification;
    this.sex = obj.sex;
    this.shortVideo = obj.shortVideo;
    this.surname = obj.surname;
    this.timezone = obj.timezone;
    this.userId = obj.userId;
    this.video = obj.video;
  }

  get avatar() {
    return this.image || 'https://langco.s3.eu-west-2.amazonaws.com/users/default.png';
  }

  get videoShortExist() {
    return this.shortVideo && this.shortVideo.length > 0 && this.shortVideo != '[]'
  }

  get videoExist() {
    // XXX: temporary solution
    return this.video && this.video.length > 0 || this.videoShortExist;
  }

  get videoShort() {
    if (this.videoShortExist) {
      return this.shortVideo;
    }

    return '';
  }

  get videoPreviewId() {
    return parseYouTubeLink(this.video) || "";
  }

  get videoLink() {
    const code = parseYouTubeLink(this.video || this.shortVideo);

    if (code) {
      return `https://www.youtube.com/embed/${code}`;
    }

    return this.video;
  }

  get unixBithday() {
    return this.birthday && moment(this.birthday).unix();
  }

  get interestExist() {
    return this.interests.length;
  }
}

export interface IDocumentSettings extends IProfile, IDocument {
}

export interface IDocumentRes extends BaseCommandProps {
  doc: IDocument;
}

export interface IDocumentUserProfile extends BaseCommandProps {
  userDoc: IDocument & IProfile;
}

export interface IVerifyRes extends BaseCommandProps {
  url: string;
}

export interface IOnSubmitUserLockForm {
  blocked: boolean;
  userId: number;
}

export interface ISignInRegToken {
  regToken: string;
}

export interface IForgotSubmitFormParams {
  email: string;
}

export interface IRestorePassword {
  pwdOne: string;
  pwdTwo: string;
  token: string;
}
