import { AppDispatch } from '@app-types/appStorageType';
import { apiCall } from '@commands/httpClient';
import { ResultItemRes } from '@modules/apiClient/type';
import { IFullStatus, IStatus, Status } from '@modules/status/types';
import { setStatusAction } from '@modules/status/actions';

export const loadStatusUser = () => (dispatch: AppDispatch<ResultItemRes<IFullStatus>>) => {
  return dispatch(apiCall<object, ResultItemRes<IFullStatus>>(`/v1/status`, undefined, { method: 'get' }))
    .then(({ item }) => {
      if (item) {
        const status = new Status(item.params);
        dispatch(setStatusAction(status));
      }
    });
};
