import { AppDispatch, AppStorageType } from '@app-types/appStorageType';
import { apiCall } from '@commands/httpClient';
import { IInitiatedStatisticResp, InitiatedStatistic } from '@modules/schedule';
import { IScheduleSlot, Schedule, ScheduleSlot } from '@modules/schedule/schedule-type';
import {
  detachSlot,
  replaceTeacherSlots, setLessonCurrentLesson,
  setLessonNextLesson,
  setLessonStatistic,
  setTeacherTimetable,
} from '@modules/schedule/actions';
import { ResultItemRes, ResultListRes } from '@modules/apiClient/type';
import { BaseCommandProps } from '@app-types/CommonTypes';
import Tz from '@utils/tzTime';
import { ITimeIntervalSubmitForm } from '@containers/App/user/schedule/modals/TimeIntervalTeacher/types';

export const loadScheduleStatistic = () => (dispatch: AppDispatch<IScheduleSlot[]>) => {
  dispatch(apiCall<object, IInitiatedStatisticResp>(`/v1/schedule/initiated/stats`, undefined, {
    method: 'post',
    silent: true,
  }))
    .then((resp) => {
      resp && dispatch(setLessonStatistic(new InitiatedStatistic(resp)));
    });
};

export const loadTeacherTimetableFromStartDay = (userId: number) => (dispatch: AppDispatch<IScheduleSlot[]>) => {
  dispatch(currentLesson());
  dispatch(apiCall<object, ResultListRes<IScheduleSlot[]>>(`/v1/schedule/list/initiator`, {
    userId,
    date: Tz.startDay().unix(),
  }, { method: 'post' }))
    .then(({ list }) => {
      const items = (list || []).map(i => new ScheduleSlot(i));
      const item = new Schedule(items);
      dispatch(setTeacherTimetable(item));
    });
};

export const loadTeacherTimetable = (userId: number, offset: number, userOffset?: number) => (dispatch: AppDispatch<IScheduleSlot[]>) => {
  dispatch(apiCall<object, ResultListRes<IScheduleSlot[]>>('/v1/schedule/list/initiator', {
    userId,
    date: Schedule.getWeekStartTime(offset),
  }, { method: 'post' }))
    .then(({ list }) => {
      const items = (list || []).map(i => new ScheduleSlot(i));
      const schedule = new Schedule(items);

      if (userOffset || userOffset === 0) {
        schedule.setOffset(userOffset * 60);
      }

      dispatch(setTeacherTimetable(schedule));
    });
};

export const assignTeacher = (id: number) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot[]>>) => {
  dispatch(apiCall<object, ResultListRes<void>>(`/v1/schedule/initiated/attach`, { id }, {
    method: 'post',
  }))
    .then(() => {
      dispatch(loadScheduleStatistic());
    });
};

export const attachLesson = (id: number) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot[]>>) => {
  dispatch(apiCall<object, ResultListRes<IScheduleSlot[]>>(`/v1/schedule/initiated/attach-slot`, { id }, { method: 'post' }))
    .then(({ list }) => {
      const items = (list || []).map(i => new ScheduleSlot(i));
      dispatch(replaceTeacherSlots(items));
      dispatch(loadScheduleStatistic());
    });
};

export const createWorkslot = (date: number, count: number) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot[]>>) => {
  dispatch(apiCall<object, ResultListRes<IScheduleSlot[]>>(`/v1/schedule/initiator`, {
    date,
    duration: count,
  }, { method: 'post' }))
    .then(({ list }) => {
      const items = (list || []).map(i => new ScheduleSlot(i));
      dispatch(replaceTeacherSlots(items));
    });
};

export const autoCreateWorkSlot = (date: ITimeIntervalSubmitForm[]) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot[]>>) => {
  dispatch(apiCall<object, ResultListRes<IScheduleSlot[]>>(`/v1/schedule/initiator/list`,
    { date, duration: 1 },
    { method: 'post' }))
    .then(async ({ list }) => {
      await date.forEach((i) => {
        if (i.day.active) {
          i.day.idSlots.forEach((i) => dispatch(detachSlot(i)));
        }
      });
      const items = (list || []).map(i => new ScheduleSlot(i));
      await dispatch(replaceTeacherSlots(items));
    });
};

export const autoCreateWorkSlotAdmin = (date: ITimeIntervalSubmitForm[], userId: number) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot[]>>) => {
  dispatch(apiCall<object, ResultListRes<IScheduleSlot[]>>(`/v1/schedule/initiator/admin/list`,
    { date, duration: 1, userId },
    { method: 'post' }))
    .then(async ({ list }) => {
      await date.forEach((i) => {
        if (i.day.active) {
          i.day.idSlots.forEach((i) => dispatch(detachSlot(i)));
        }
      });
      const items = (list || []).map(i => new ScheduleSlot(i));
      await dispatch(replaceTeacherSlots(items));
    });
};

export const nextLesson = () => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>) => {
  dispatch(apiCall<object, ResultItemRes<IScheduleSlot>>(`/v1/schedule/next-lesson`, undefined, { method: 'post' }))
    .then((res) => {
      res.item && dispatch(setLessonNextLesson(new ScheduleSlot(res.item))) || dispatch(setLessonNextLesson(undefined));
    });
};

export const displayCurrentLesson = (value: boolean) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  if (state.schedule.currentLesson) {
    state.schedule.currentLessonSubject.next(value);
  } else {
    state.schedule.currentLessonSubject.next(false);
  }
};

export const startLesson = () => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  if (state.schedule.currentLesson) {
    dispatch(apiCall<object, ResultItemRes<IScheduleSlot>>(`/v1/schedule/lesson/start`, { id: state.schedule.currentLesson.id }, {
      method: 'post',
    })).then(res => {
      res.item && dispatch(setLessonCurrentLesson(new ScheduleSlot(res.item)));
    });
  } else {
    state.schedule.currentLessonSubject.next(false);
  }
};

export const finishLesson = () => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  if (state.schedule.currentLesson) {
    dispatch(apiCall<object, ResultItemRes<IScheduleSlot>>(`/v1/schedule/lesson/finish`, { id: state.schedule.currentLesson.id }, {
      method: 'post',
    })).then((res) => {
      res.item && dispatch(replaceTeacherSlots([new ScheduleSlot(res.item)]));
      dispatch(setLessonNextLesson(undefined));
      dispatch(setLessonCurrentLesson(undefined));

      dispatch(loadScheduleStatistic());
      dispatch(nextLesson());
      dispatch(currentLesson());
    });
  }

  state.schedule.currentLessonSubject.next(false);
};

export const cancelLessonByInitiated = (id: number) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>) => {
  return dispatch(apiCall<object, BaseCommandProps>(`/v1/schedule/initiated/cancel-lesson`, { id }, {
    method: 'post',
  }))
    .then(() => {
      dispatch(detachSlot(id));
      dispatch(setLessonNextLesson(undefined));
      dispatch(setLessonCurrentLesson(undefined));

      dispatch(loadScheduleStatistic());
      dispatch(nextLesson());
      dispatch(currentLesson());
    });
};

export const cancelLessonByInitiator = (id: number) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>) => {
  return dispatch(apiCall<object, BaseCommandProps>(`/v1/schedule/initiator/cancel-lesson`, { id }, {
    method: 'post',
  }))
    .then(() => {
      dispatch(detachSlot(id));
      dispatch(loadScheduleStatistic());
    });
};

export const currentLesson = () => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  if (state.schedule.currentLesson) {
    return state.schedule.currentLesson;
  }

  dispatch(apiCall<object, ResultItemRes<IScheduleSlot>>(`/v1/schedule/current-lesson`, undefined, { method: 'post', silent: true }))
    .then((res) => {
      if (res && res.item) {
        dispatch(setLessonCurrentLesson(new ScheduleSlot(res.item)));
      }
    });
};


export const onBreakSlot = (id: number) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>) => {
  return dispatch(apiCall<object, ResultItemRes<IScheduleSlot>>(`/v1/schedule/initiator/break`, { id }, { method: 'post' }));
};


export const attachSlot = (token: string) => (dispatch: AppDispatch<ResultListRes<IScheduleSlot>>) => {
  return dispatch(apiCall<object, ResultItemRes<IScheduleSlot>>(`/v1/schedule/initiator/attach-with-email`, { token }, { method: 'post' }));
};
