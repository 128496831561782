import { call, take, put } from 'redux-saga/effects';
import { EventChannel } from '@redux-saga/core';

// TODO: Need type for subscriber field
export function* runActivity<T>(subscriber: any, data: T) {
  const channel: EventChannel<T> = yield call(subscriber, data);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}
