import { IPersonalReducer } from '@modules/personal/types';
import {
  LOAD_PERSONAL_INTERESTS,
  LoadPersonalInterestsType,
  PersonalInterestsActions, SET_INTERESTS_POP, SetInterestsPopType,
} from '@modules/personal/actions/interests';

const defaultState: IPersonalReducer = {
  list: [],
  interestsPop: false,
}

const PersonalInterestsReducerMethod = (
  state: IPersonalReducer = defaultState,
  action: PersonalInterestsActions,
): IPersonalReducer => {
  if (action.type === LOAD_PERSONAL_INTERESTS) {
    const list = (action as LoadPersonalInterestsType).payload;

    return {
      ...state,
      list,
    }
  }

  if (action.type === SET_INTERESTS_POP) {
    const visible = (action as SetInterestsPopType).payload;

    return {
      ...state,
      interestsPop: visible,
    }
  }

  return state;
};

export default PersonalInterestsReducerMethod;
