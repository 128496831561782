import {
  LOAD_USER_ROLES_ACTION,
  RolesAction,
  LoadUserRoleAction,
  LOAD_SEARCHED_USER_ROLE,
  LoadSearchedUserRoleAction,
  ADD_OR_UPDATE_USER_ROLE_ACTION,
  AddOrUpdateUserRoleAction,
  DELETE_USER_ROLE_ACTION,
  DeleteUserRoleAction,
  LOAD_ROLES_ACTION, LoadRolesAction,
} from '@modules/roles/actions';
import { IRolesReducer } from '@modules/roles/types';
import IUser from "@modules/auth/types/Users";

const defaultState: IRolesReducer = {
  userRole: [],
  searchedUserRole: [],
  list: [],
}

const RolesReducer = (
  state: IRolesReducer = defaultState,
  action: RolesAction,
): IRolesReducer => {
  if (action.type === LOAD_USER_ROLES_ACTION) {
    const list = (action as LoadUserRoleAction).payload;
    return {
      ...state,
      userRole: list,
    }
  }

  if (action.type === LOAD_SEARCHED_USER_ROLE) {
    const list = (action as LoadSearchedUserRoleAction).payload;
    return {
      ...state,
      searchedUserRole: list,
    }
  }

  if (action.type === ADD_OR_UPDATE_USER_ROLE_ACTION) {
    const { userRole } = state
    const item = (action as AddOrUpdateUserRoleAction).payload;
    const index = userRole.findIndex((i: IUser) => i.id === item.id);

    if (index !== -1) {
      return {
        ...state,
        userRole: [
          ...userRole.slice(0, index),
          item,
          ...userRole.slice(index + 1),
        ]
      }
    }

    return {
      ...state,
      userRole: [
        item,
        ...userRole
      ],
    }
  }

  if (action.type === DELETE_USER_ROLE_ACTION) {
    const { userRole } = state;
    const id = (action as DeleteUserRoleAction).payload;
    const index = userRole.findIndex(i => i.id === id);

    return {
      ...state,
      userRole: [
        ...userRole.slice(0, index),
        ...userRole.slice(index + 1),
      ],
    }
  }

  if (action.type === LOAD_ROLES_ACTION) {
    const roles = (action as LoadRolesAction).payload;

    return {
      ...state,
      list: roles,
    }
  }

  return state;
};

export default RolesReducer;
