import {
  addCategoryAction, removeCategoryAction,
  setInvestmentsCategoryAction, setCategoryAction, clearCategoryAction,
} from '../actions/index';
import UtcTime from '@helpers/UtcTime';


export interface IInvestmentsCategory {
  id: number,
  title: string,
  subcategory: string,
  slug: string,
  description: string,
  shortDescription: string,
  image: string,
  button_text: string,
  url: string,
  availableFrom: Date,
  sort: number,
  show: boolean,
}

export class InvestmentsCategory implements IInvestmentsCategory {
  static Essential = 'essential';
  static Premier = 'premier';
  static Corporate = 'corporate';

  public readonly id: number;
  public sort: number;
  public title: string;
  public url: string;
  public button_text: string;
  public description: string;
  public shortDescription: string;
  public image: string;
  public slug: string;
  public subcategory: string;
  public availableFrom: Date;
  public show: boolean;

  constructor(obj: IInvestmentsCategory) {
    this.id = obj.id;
    this.sort = obj.sort;
    this.title = obj.title;
    this.button_text = obj.button_text;
    this.url = obj.url;
    this.description = obj.description;
    this.shortDescription = obj.shortDescription || "";
    this.image = obj.image;
    this.slug = obj.slug;
    this.subcategory = obj.subcategory;
    this.show = obj.show;
    this.availableFrom = new Date(obj.availableFrom);
  }

  isTrial = () => {
    return this.slug === 'trial';
  }

  IsAvailable = () => {
    if (new Date(this.availableFrom).getTime() > new Date().getTime()) {
      return false;
    }
    return this;
  };
}

export type setCategoryActionType = ReturnType<typeof setCategoryAction>;
export type clearCategoryActionType = ReturnType<typeof clearCategoryAction>;
export type setInvestmentsCategoryType = ReturnType<typeof setInvestmentsCategoryAction>;
export type addCategoryType = ReturnType<typeof addCategoryAction>;
export type removeCategoryType = ReturnType<typeof removeCategoryAction>;

export type InvestmentsCategoryAction = setInvestmentsCategoryType | addCategoryType | removeCategoryType
export type CategoryAction = setCategoryActionType | clearCategoryActionType;
