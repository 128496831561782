import { fork } from 'redux-saga/effects';
import { eventChannel } from '@redux-saga/core';
import { runActivity } from '../tools';
import { successMessage } from '@modules/common/actions';

const subscribe = (socket: SocketIOClient.Socket) => {
  return eventChannel((emit) => {
    socket.on('notify_new_message', (data: string) => {
      const item = JSON.parse(data);
      // {
      //     "id": 435,
      //     "userId": 517,
      //     "message": "g g",
      //     "dialogId": 100,
      //     "files": null,
      //     "updatedAt": "2023-04-02T19:10:06.917Z",
      //     "createdAt": "2023-04-02T19:10:06.917Z"
      // }
      emit(successMessage({
        title: 'chat.new.message',
        message: item.message,
        payload:
          {
            link: `/private/im/${item.userId}`,
            userId: item.userId,
          }
      }));
    });

    return () => {
      socket.off('notify_new_message');
    };
  });
};

export const messageNotifyHandler = function* (socket: SocketIOClient.Socket) {
  yield fork(runActivity, subscribe, socket);
};
