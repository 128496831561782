import { readDialog } from '@commands/chat';
import { fork } from 'redux-saga/effects';
import { eventChannel } from '@redux-saga/core';
import { runActivity } from '../tools';
import { newMessageAction, chatSetUnreadAction } from '@modules/chat/actions';
import { Message } from '@modules/chat/types';

const subscribe = (socket: SocketIOClient.Socket) => {
  return eventChannel((emit) => {
    socket.on('message', (data: string) => {
      const item = JSON.parse(data);
      const message = new Message(item);
      emit(newMessageAction(message));

      if (!window.location.href.includes(`${message.userId}`)) {
        emit(chatSetUnreadAction());
      } else {
        emit(readDialog(message.dialogId));
      }
    });
    socket.on('message-read', (data: string) => {
      const item = JSON.parse(data);
      // const message = new Message(item);
      // emit(newMessageAction(message));
    });

    return () => {
      socket.off('message');
      socket.off('message-read');
    };
  });
};

export const messageHandler = function* (socket: SocketIOClient.Socket) {
  yield fork(runActivity, subscribe, socket);
};
