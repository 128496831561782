import { AppStorageType } from '@app-types/appStorageType';
import serverTranslates from '@utils/serverTranslates';
import Script from 'next/script';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Head from 'next/head';
// @ts-ignore
import { AppContextType, AppInitialProps } from 'next';
import '@styles/important.css';
import '@styles/normalize.css';
import '@styles/styles.css';
import '@styles/landing/landingStyles.css';
import '@styles/icomoon.css';
import '@styles/components/modal.css';
import '@styles/components/languageSelector.css';
import '@styles/conference.css';
import '../../public/fonts/stylesheet.css';
import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'aos/dist/aos.css';
import { AppContext } from 'next/app';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import httpServer from '../commands/httpServer';
import { CommandWithSession } from '@app-types/CommonTypes';
import { destroyCookie, parseCookies } from 'nookies';
import { Provider } from 'react-redux';
import store from '../app-redux/store';
import { AuthContext, initialAuthValues } from '@contexts/auth';
import appConfig, { getPrivatePath } from '@configs/app';
import redirect from '@helpers/redirect';
import { ResultItemRes, ResultListRes } from '@modules/apiClient/type';
import { ITranslate } from '@modules/translate/types/translate';
import { IAuthContext } from '@modules/auth/types/SessionProps';
import { User } from '@modules/auth/types/Users';
import { defer } from 'lodash';
import TranslateProvider from '@modules/translate/components/TranslateProvider';
import AppProvider from '@components/AppProvider';
import { ClientApp } from '@models/ClientApp';
import { ILang } from '@modules/translate/types/lang';
import 'react-date-picker/dist/DatePicker.css';
import dynamic from 'next/dynamic';
import ClassWrap from '@containers/App/user/classWrap';
import { PortalHandler } from '@helpers/Portal';
import { ICountryCurrency } from '@modules/country-corrency/types';
import ContextWrapper from '../wrappers/contextWrapper';
import get from 'lodash/get';
import { useRouter } from 'next/router';

const ChangeTranslateModalComponent = dynamic(() => import('@components/ChangeTranslate/Modal'), { ssr: false });
const NotificationView = dynamic(() => import('@components/NotificationView'), { ssr: false });
const EditJsonList = dynamic(() => import('@components/EditJsonList'), { ssr: false });


const appInit = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => new ClientApp(dispatch);

function MyApp({ pageProps, Component }: AppContextType & AppInitialProps) {
  const [auth, setAuthData] = useState<IAuthContext>(pageProps);
  const router = useRouter();
  const canonicalLink = router.asPath.split('?');

  const updateAuth = useCallback((u: User) => {
    const user = new User(pageProps.user);
    user.setValues(u);

    defer(() => setAuthData({
      ...auth,
      user,
    }));
  }, [pageProps.user]);

  const authProviderData = useMemo(() => ({
    userId: pageProps.userId,
    user: pageProps.user, ...auth,
    update: updateAuth,
  }), [pageProps.userId, auth, updateAuth]);

  useEffect(() => setAuthData(pageProps), [pageProps.user]);

  return (
    <>
      <Provider store={store}>
        <Head>
          <title>{get(pageProps, `seo.value.title.${pageProps.currentLanguageServer}.value`, '')}</title>
          <meta
            id="meta-description"
            name="description"
            content={get(pageProps, `seo.value.desc.${pageProps.currentLanguageServer}.value`, '') || get(pageProps, ['translatesServer', 'description.domain'], '')}
          />

          <meta name="google" content="notranslate" />
          <meta name="yandex" content="notranslate" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          <meta name="theme-color" content="#FFFFFF" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <meta name="mobile-web-app-capable" content="yes" />
          <link rel="canonical" href={`${process.env.NEXT_PUBLIC_DOMAIN}}/${canonicalLink[0]}`} />
          <link rel="icon" href="/favicon.ico" />
          <link type="image/x-icon" rel="shortcut icon" href="/favicon/favicon.ico" />
          <link type="image/png" rel="icon" sizes="16x16" href="/favicon/favicon-16.png" />
          <link type="image/png" rel="icon" sizes="32x32" href="/favicon/favicon-32.png" />
          <link type="image/png" rel="icon" sizes="96x96" href="/favicon/favicon-96.png" />
          <link type="image/png" rel="icon" sizes="120x120" href="/favicon/favicon-120.png" />
          <link type="image/png" rel="icon" sizes="192x192" href="/favicon/favicon-192.png" />
          <link rel="apple-touch-icon" sizes="57x57" href="/favicon/favicon-57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/favicon/favicon-60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/favicon/favicon-72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/favicon/favicon-76.png" />
          <link rel="apple-touch-icon" sizes="96x96" href="/favicon/favicon-96.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/favicon/favicon-114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/favicon/favicon-120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/favicon/favicon-144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/favicon/favicon-152.png" />
          <link rel="apple-touch-icon" sizes="160x160" href="/favicon/favicon-160.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/favicon/favicon-180.png" />
          <link rel="apple-touch-icon" sizes="256x256" href="/favicon/favicon-256.png" />
          <link rel="apple-touch-icon" sizes="512x512" href="/favicon/favicon-512.png" />
          {get(pageProps, 'seo.value.schema', false) && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: pageProps.seo.value.schema,
              }} />
          )}
        </Head>
        <NotificationView />
        <AuthContext.Provider value={authProviderData}>
          <TranslateProvider
            translatesServer={pageProps.translatesServer}
            currentLanguageServer={pageProps.currentLanguageServer}
            langsServer={pageProps.langsServer}
            langsAdminServer={pageProps.langsAdminServer}
            countryCurrency={pageProps.countryCurrency}
          >
            <AppProvider appInit={appInit} countryCode={pageProps?.country} />
            <div id="startPage" />
            <ContextWrapper contexts={pageProps.contexts}>
              <Component />
            </ContextWrapper>
            <PortalHandler name="modal" />
            <ClassWrap />
            {pageProps.isAdmin && <EditJsonList />}
          </TranslateProvider>
          {pageProps.isAdmin && <ChangeTranslateModalComponent />}
        </AuthContext.Provider>
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=G-MJXV45DZRF"
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-MJXV45DZRF');
        `}
        </Script>
      </Provider>
    </>
  );
}

MyApp.getInitialProps = async (props: AppContext) => {
  const { ctx, router } = props;
  const isServer = !!ctx?.res;

  if (
    (!ctx.asPath?.startsWith(router.asPath) && isServer) ||
    ctx.asPath?.startsWith('/health') ||
    ctx.asPath?.startsWith('/_next') ||
    ctx.asPath === '/worker.js'
  ) {
    return { pageProps: initialAuthValues };
  }

  let translates: ResultListRes<ITranslate> | undefined;
  let lang: string | undefined = undefined;

  let { token, langValue: cookieLang } = parseCookies(ctx);

  let path = router.asPath;
  const indexQuery = router.asPath.indexOf('?');
  if (indexQuery !== -1) {
    path = router.asPath.substring(0, indexQuery);
  }

  try {
    // Check permission
    const result = await httpServer<object, CommandWithSession>(
      token || '',
      '/v1/user/ping',
      { url: path },
      { method: 'get' },
      isServer,
    );

    const role = result?.user?.role || '';

    if (role !== 'admin') {
      if (result?.user && result?.user?.blocked) {
        destroyCookie(ctx, 'token', { path: '/' });
        await redirect(ctx, appConfig.blockedRedirectPage);
        return {};
      }

      if (role === 'teacher' && path === '/private') {
        await redirect(ctx, appConfig.public.myDay);
        return {};
      }

      if (result.statusCode === 401 || result.statusCode === 500 || result.statusCode === 405) {
        if (result.statusCode === 405) {
          destroyCookie(ctx, 'token', { path: '/' });
        }
        if (!result.isPublic) {
          await redirect(ctx, appConfig.public.redirectPage);
        }
        return {};
      }

      if (result.statusCode === 403) {
        if (path?.includes('admin')) {
          await redirect(ctx, appConfig.private.redirectPage);
        } else {
          await redirect(ctx, appConfig.notFoundPage);
        }
        return {};
      }

      if (result.statusCode === 405) {
        await redirect(ctx, getPrivatePath(role));
        return {};
      }
    }

    if (isServer) {
      // Getting country from cloudflare
      const cfCountryCode = (ctx.req?.headers['cf-ipcountry'] as string) || '';
      const { item: countryCurrency } = await httpServer<object, ResultItemRes<ICountryCurrency>>(
        '',
        `/v1/country-currency`,
        {
          code: cfCountryCode,
        },
        { method: 'get' },
        isServer,
      );

      // Define language
      lang = cookieLang || countryCurrency?.lang || 'en';

      const { list: languagesList } = await httpServer<object, ResultListRes<ILang[]>>(
        '',
        `/v1/translate/lang`,
        undefined,
        { method: 'get' },
        isServer,
      );

      translates = await serverTranslates(lang, props, result?.user, result?.isPublic || false);

      return {
        pageProps: {
          isAdmin: result?.user?.role === 'admin',
          user: result?.user,
          userId: result?.user?.id,
          isPublic: result.isPublic,
          translatesServer: translates || undefined,
          currentLanguageServer: lang,
          langsServer: languagesList || [],
          langsAdminServer: languagesList || [],
          countryCurrency: countryCurrency,
        },
      };
    }

    // TODO: Test it please
    return { pageProps: {
        isAdmin: result?.user?.role === 'admin',
        user: result?.user,
        userId: result?.user?.id,
        isPublic: result.isPublic,
      }
    };
  } catch (e) {
    console.log('app error', e);
    return { pageProps: initialAuthValues };
  }
};


export default MyApp;
