import { ThunkDispatch } from 'redux-thunk';
import { AppStorageType } from '@app-types/appStorageType';
import { AnyAction } from 'redux';
import { IList } from '@modules/lists/types';
import forEach from 'lodash/forEach';
import { setListValueAction } from '@modules/lists/actions';

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => ({
  setContexts: (contexts: IList[]) => forEach(contexts, ({ value, context }) => dispatch(setListValueAction({ context, value: value })))
});

interface IContextWrapperProps {
  contexts: IList[],
}

export type ContextWrapperProps = IContextWrapperProps & ReturnType<typeof mapDispatchToProps>;