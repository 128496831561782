import { ThunkDispatch } from 'redux-thunk';
import { AppStorageType } from '@app-types/appStorageType';
import { AnyAction } from 'redux';

export const mapStateToProps = (state: AppStorageType) => ({
  startSubject: state.schedule.currentLessonSubject,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => ({
});

export type ComponentProps = ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>

