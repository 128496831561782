import { CommonAction } from '@modules/common/actions';
import { IPromocode } from '@modules/promocode/types';

export const LOAD_PROMOCODE = 'LOAD_PROMOCODE';
export const CREATE_PROMOCODE = 'CREATE_PROMOCODE';
export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE';
export const DELETE_PROMOCODE = 'DELETE_PROMOCODE';

export const loadPromocodeAction = CommonAction<IPromocode[]>(LOAD_PROMOCODE);
export const createPromocodeAction = CommonAction<IPromocode>(CREATE_PROMOCODE);
export const updatePromocodeAction = CommonAction<IPromocode>(UPDATE_PROMOCODE);
export const deletePromocodeAction = CommonAction<number>(DELETE_PROMOCODE);

export type LoadPromocodeActionType = ReturnType<typeof loadPromocodeAction>;
export type CreatePromocodeActionType = ReturnType<typeof createPromocodeAction>;
export type UpdatePromocodeActionType = ReturnType<typeof updatePromocodeAction>;
export type DeletePromocodeActionType = ReturnType<typeof deletePromocodeAction>;

export type PromocodeActions = LoadPromocodeActionType | CreatePromocodeActionType | UpdatePromocodeActionType | DeletePromocodeActionType;
