import { BaseCommandProps } from '@app-types/CommonTypes';
import * as React from 'react';

export interface ICasbinRules {
  id: number,
  ptype: string,
  v0: string,
  v1: string,
  v2: string,
  v3: string,
  v4: string,
  v5: string,
}

export class CasbinRules implements ICasbinRules {
  public readonly id: number;
  public ptype: string;
  public v0: string;
  public v1: string;
  public v2: string;
  public v3: string;
  public v4: string;
  public v5: string;

  constructor(obj: ICasbinRules) {
    this.id = obj.id;
    this.ptype = obj.ptype;
    this.v0 = obj.v0;
    this.v1 = obj.v1;
    this.v2 = obj.v2;
    this.v3 = obj.v3;
    this.v4 = obj.v4;
    this.v5 = obj.v5;
  }
}

export interface ICasbinReducer {
  casbinRules: CasbinRules[],
  searchedRules: CasbinRules[],
}

export interface ICasbinRulesList extends BaseCommandProps {
  list: CasbinRules[],
}
