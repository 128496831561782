import { IStatus } from '@modules/status/types';
import { SET_STATUS, setStatusType, StatusActions } from '@modules/status/actions';

const StatusReducerMethod = (
  state: IStatus = {} as IStatus,
  action: StatusActions,
): IStatus => {
  if (action.type === SET_STATUS) {
    return (action as setStatusType).payload || {};
  }

  return state;
};

export default StatusReducerMethod;
