import { AppDispatch } from '@app-types/appStorageType';
import { ResultItemRes, ResultListRes } from '@modules/apiClient/type';
import { apiCall } from '@commands/httpClient';
import { IPersonal } from '@modules/personal/types';
import { setPersonalLangsList } from '@modules/personal/actions/langs';
import { setInterestsPop, setPersonalInterestsList } from '@modules/personal/actions/interests';
import { BaseCommandProps } from '@app-types/CommonTypes';

export const loadPersonalLangs = () => (dispatch: AppDispatch<ResultListRes<IPersonal[]>>) => {
  dispatch(apiCall<object, ResultListRes<IPersonal[]>>(`/v1/personal/langs`, undefined, { method: 'get', }))
    .then(({ list }) => dispatch(setPersonalLangsList(list)));
};

export const loadPersonalInterests = () => (dispatch: AppDispatch<ResultListRes<IPersonal[]>>) => {
  dispatch(apiCall<object, ResultListRes<IPersonal[]>>(`/v1/personal/interests`, undefined, { method: 'get', }))
    .then(({ list }) => dispatch(setPersonalInterestsList(list)));
};

export const loadInterestsPop = () => (dispatch: AppDispatch<ResultItemRes<{ value: boolean }>>) => {
  dispatch(apiCall<object, { value: boolean } & BaseCommandProps>(`/v1/personal/interests-pop`, undefined, { method: 'get', }))
    .then(({ value }) => dispatch(setInterestsPop(value)));
};

export const updateInterestsPop = () => (dispatch: AppDispatch<ResultItemRes<{ value: boolean }>>) => {
  dispatch(apiCall<object, BaseCommandProps>(`/v1/personal/interests-top-up`, undefined, { method: 'post', }))
    .then(() => dispatch(setInterestsPop(false)));
};