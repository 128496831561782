import { IPersonalReducer } from '@modules/personal/types';
import { LOAD_PERSONAL_LANGS, LoadPersonalLangsType, PersonalLangsActions } from '@modules/personal/actions/langs';

const defaultState: IPersonalReducer = {
  list: [],
}

const PersonalLangsReducerMethod = (
  state: IPersonalReducer = defaultState,
  action: PersonalLangsActions,
): IPersonalReducer => {
  if (action.type === LOAD_PERSONAL_LANGS) {
    const list = (action as LoadPersonalLangsType).payload;

    return {
      ...state,
      list,
    }
  }

  return state;
};

export default PersonalLangsReducerMethod;
