import { IProfile } from '@modules/auth/types/index';

export default interface IUser extends IProfile {
  id: number;
  role: string;
  deleted: boolean;
  docVerify: boolean;
}

export class User implements IUser {
  public readonly id: number;
  public email: string;
  public lang: string;
  public readonly role: string;
  public readonly deleted: boolean;
  public readonly blocked: boolean;
  public readonly verified: boolean;
  public createdAt?: string;
  public docVerify: boolean;

  public alias?: string;
  public readonly parent_id?: number;
  public firstName?: string;
  public middleName?: string;
  public surname?: string;
  public phone?: string;
  public telegram?: string;
  public readonly weight?: number;
  public path?: number[];

  constructor(obj: IUser) {
    this.id = obj.id;
    this.email = obj.email;
    this.lang = obj.lang;
    this.role = obj.role;
    this.deleted = obj.deleted;
    this.blocked = obj.blocked;
    this.verified = obj.verified;
    this.createdAt = obj.createdAt;

    this.alias = obj.alias;
    this.parent_id = obj.parent_id;
    this.firstName = obj.firstName;
    this.middleName = obj.middleName;
    this.surname = obj.surname;
    this.phone = obj.phone;
    this.telegram = obj.telegram;
    this.weight = obj.weight;
    this.docVerify = obj.docVerify;
    this.path = obj.path;
  }

  get shortName(): string {
    if (this.firstName && this.surname) {
      return `${this.firstName} ${this.surname}`;
    }

    return this.email;
  }

  get isAdmin(): boolean {
    return this.role === 'admin';
  }
  get isStudent(): boolean {
    return this.role === 'student';
  }

  get isTeacher(): boolean {
    return this.role === 'teacher';
  }

  get profileName(): string {
    if (this.isAdmin) {
      return 'Admin';
    }

    return this.shortName;
  }

  get fullName(): string {
    if (this.isAdmin) {
      return 'Admin';
    }

    if (!this.surname && !this.firstName && !this.middleName) {
      return this.email;
    }

    return `${this.firstName && `${this.firstName} ` || ''}${this.surname || ''}`;
  }

  get refId(): string {
    if (this.alias) {
      return this.alias;
    }

    return this.id && this.id.toString() || '';
  }

  setValues(obj: User) {
    if (obj.email !== undefined) {
      this.email = obj.email;
    }

    if (obj.lang !== undefined) {
      this.lang = obj.lang;
    }

    if (obj.alias !== undefined) {
      this.alias = obj.alias;
    }

    if (obj.firstName !== undefined) {
      this.firstName = obj.firstName;
    }

    if (obj.middleName !== undefined) {
      this.middleName = obj.middleName;
    }

    if (obj.surname !== undefined) {
      this.surname = obj.surname;
    }

    if (obj.phone !== undefined) {
      this.phone = obj.phone;
    }

    if (obj.telegram !== undefined) {
      this.telegram = obj.telegram;
    }

    if (obj.docVerify !== undefined) {
      this.docVerify = obj.docVerify;
    }

    if (obj.path !== undefined) {
      this.path = obj.path;
    }

    return this;
  }
}
