import axios, { AxiosInstance, Method } from 'axios';

export interface ApiClientOptions {
  method?: Method
  headers?: object
  asData?: boolean
  silent?: boolean
  context?: string
  passError?: boolean
  isFile?: boolean
  baseURL?: string
  transformResponse?: ((data: any) => any)[]
  asImpersonate?: boolean
}

const clientApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_CLIENT,
  validateStatus: () => true,
});

const serverApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  validateStatus: () => true,
});

const MAX_REQUESTS_COUNT = 5
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

clientApi.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})


clientApi.interceptors.response.use(function (response) {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
  return Promise.resolve(response)
}, function (error) {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
  return Promise.reject(error)
})

const commonApi = (api: AxiosInstance) => <P extends object | null, T extends object>(url: string, params?: P, options?: ApiClientOptions) => {
  const method = options?.method || 'post';
  const headers = { ...(options?.headers || {}), 'Content-Type': 'application/json' };
  const data = (method === 'get') ? { params } : { data: params };
  const extra = options?.baseURL ? { baseURL: options!.baseURL } : {};

  return api.request<T>({
    method,
    url,
    headers,
    ...data,
    ...extra,
    transformResponse: options?.transformResponse
  }).then(res => {
    return {
      statusCode: res.status,
      ...res.data,
    }
  });
}


export const simpleApiClient = commonApi(clientApi);
export const simpleApiServer = commonApi(serverApi);
