import { fork } from 'redux-saga/effects';
import { eventChannel } from '@redux-saga/core';
import { runActivity } from '../tools';
import { updateTranslateList } from '@modules/translate/actions';
import { TranslateObjectResSocket } from '@modules/translate/types/translate';

const subscribe = (socket: SocketIOClient.Socket) => {
  return eventChannel((emit) => {
    socket.on('onTranslateCreated', (payload: TranslateObjectResSocket) => {
      emit(updateTranslateList(payload));
    })

    return () => {
      socket.off('onTranslateCreated');
    }
  });
}

export const translateHandler = function* (socket: SocketIOClient.Socket) {
  yield fork(runActivity, subscribe, socket);
}
