import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.getTime());
}

type Data = { [key: string]: any };

const stringToDate = (data: Data, fields: string[]): Data => mapValues(data, (item, key) => {
  if (isObject(item) && !Array.isArray(item)) {
    return stringToDate(item, fields);
  }

  if (fields.includes(key)) {
    const date = new Date(item);
    return isValidDate(date) ? date : item;
  }

  return item;
});

const dateTransformer = (fields: string[]) => (req: string) => {
  const data: { [key: string]: any } = JSON.parse(req);
  return stringToDate(data, fields);
}

// TODO: Make it relative on interface type
export default dateTransformer;
