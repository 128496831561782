import { BaseCommandProps } from '@app-types/CommonTypes';
import { ApiClientOptions, simpleApiClient, simpleApiServer } from '@modules/apiClient';

const httpServer = <P extends object, T extends BaseCommandProps>(token: string, url: string, params?: P, options?: ApiClientOptions, isServer: boolean = false) => {
  const headers = {
    ...(options?.headers || {}),
    'Content-Type': 'application/json',
    'Authorization': token ? `Bearer ${token}` : '',
  };
  const clientOptions = { ...(options || {}), headers };
  const client = isServer ? simpleApiServer : simpleApiClient;

  return client<P, T>(url, params, clientOptions)
    .then((res: T) => {
      const { success, statusCode } = res;

      if (!success) {
        return { ...res, success: false, statusCode };
      }
      if (statusCode && statusCode !== 200) {
        return { ...res, success: false, statusCode };
      }

      return res;
    });
}

export default httpServer;
