import { CommonAction } from '@modules/common/actions';
import {
  Purchase, setPurchaseLessonAction, setPurchaseHistoryAction,
} from '@modules/investments/purchases/types/purchase';
import { StageLesson } from '@modules/investments/products/types/stage';

export const LOAD_PURCHASE_LESSON = 'LOAD_PURCHASE_LESSON';
export const LOAD_PURCHASE_HISTORY = 'LOAD_PURCHASE_HISTORY';

export const loadPurchaseLessonAction = CommonAction<Purchase<StageLesson>[]>(LOAD_PURCHASE_LESSON);
export const loadPurchaseHistoryAction = CommonAction<Purchase<StageLesson>[]>(LOAD_PURCHASE_HISTORY);

export type PurchaseAction = setPurchaseLessonAction | setPurchaseHistoryAction;
