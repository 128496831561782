import { NotificationReducer } from '@modules/notification/types/notificationReducer';
import { INotification } from '@modules/notification/types/notification';
import {
  NotificationAction,
  READ_ALL_NOTIFICATION,
  READ_ONE_NOTIFICATION,
  SET_NOTIFICATIONS,
  SetNotificationsType,
  SetReadOneNotificationType,
  UNREAD_NOTIFICATION_COUNT,
  UnreadNotificationCountType,
} from '@modules/notification/actions';

const defaultState: NotificationReducer = {
  list: [],
  count: 0,
};

const NotificationReducerMethod = (
  state: NotificationReducer = defaultState,
  action: NotificationAction,
): NotificationReducer => {
  if (action.type === SET_NOTIFICATIONS) {
    return {
      ...state,
      list: (action as SetNotificationsType).payload || [],
    };
  }

  if (action.type === UNREAD_NOTIFICATION_COUNT) {
    return {
      ...state,
      count: (action as UnreadNotificationCountType).payload,
    };
  }

  if (action.type === READ_ALL_NOTIFICATION) {
    const { list } = state;
    const newList = list.map(i => {
      i.read = true;
      return i;
    });

    return {
      ...state,
      list: newList,
      count: 0,
    };
  }

  if (action.type === READ_ONE_NOTIFICATION) {
    const { list, count } = state;

    const id = (action as SetReadOneNotificationType).payload;
    const index = list.findIndex((i: INotification) => i.id === id);
    const item = list[index];

    item.read = true;

    return {
      ...state,
      count: count - 1,
      list: [
        ...list.slice(0, index),
        item,
        ...list.slice(index + 1),
      ],
    };
  }

  return state;
};

export default NotificationReducerMethod;
