import { CommonAction } from '@modules/common/actions';
import { ITeamCategory, ITeamItem } from '@modules/information/team/types/information';

export const SET_LIST_TEAM_CATEGORIES = 'SET_LIST_TEAM_CATEGORIES';
export const SET_LIST_TEAM_ITEMS = 'SET_LIST_TEAM_ITEMS';
export const CREATE_OR_UPDATE_TEAM_CATEGORIES = 'CREATE_OR_UPDATE_TEAM_CATEGORIES';
export const CREATE_OR_UPDATE_TEAM_ITEMS = 'CREATE_OR_UPDATE_TEAM_ITEMS';
export const REMOVE_OR_UPDATE_TEAM_CATEGORIES = 'REMOVE_OR_UPDATE_TEAM_CATEGORIES';
export const REMOVE_OR_UPDATE_TEAM_ITEMS = 'REMOVE_OR_UPDATE_TEAM_ITEMS';

export const getTeamListCategory = CommonAction<ITeamCategory[]>(SET_LIST_TEAM_CATEGORIES)
export const getTeamListItems = CommonAction<ITeamItem[]>(SET_LIST_TEAM_ITEMS)
export const createOrUpdateTeamCategories = CommonAction<ITeamCategory>(CREATE_OR_UPDATE_TEAM_CATEGORIES)
export const createOrUpdateTeamItems = CommonAction<ITeamItem>(CREATE_OR_UPDATE_TEAM_ITEMS)
export const removeOrUpdateTeamCategories = CommonAction<number>(REMOVE_OR_UPDATE_TEAM_CATEGORIES)
export const removeOrUpdateTeamItems = CommonAction<number>(REMOVE_OR_UPDATE_TEAM_ITEMS)
