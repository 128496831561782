import {
  CREATE_OR_UPDATE_SECTION, CREATE_OR_UPDATE_SECTION_ITEM, createOrUpdateSectionItemType, createOrUpdateSectionType,
  InformationAction, REMOVE_SECTION, REMOVE_SECTION_ITEM, removeSectionItemType, removeSectionType, SET_SECTIONS,
  SET_SECTIONS_ITEM, SetSectionsItemType, SetSectionsListType,
} from '@modules/information/info/actions';
import { InformationReducer } from '@modules/information/info/types/information';

const defaultState: InformationReducer = {
  sections: [],
  sectionsItem: {},
}

const InformationReducerMethod = (
  state: InformationReducer = defaultState,
  action: InformationAction,
): InformationReducer => {
  if (action.type === SET_SECTIONS) {
    return {
      ...state,
      sections: (action as SetSectionsListType).payload,
    }
  }

  if (action.type === SET_SECTIONS_ITEM) {
    const { list, sectionId } = (action as SetSectionsItemType).payload;

    return {
      ...state,
      sectionsItem: {
        ...state.sectionsItem,
        [sectionId]: list,
      },
    }
  }

  if (action.type === CREATE_OR_UPDATE_SECTION) {
    const item = (action as createOrUpdateSectionType).payload;
    const index = state.sections.findIndex(i => i.id === item.id)

    if (!!~index) {
      return {
        ...state,
        sections: [
          ...state.sections.slice(0, index),
          item,
          ...state.sections.slice(index + 1),
        ],
      }
    }

    return {
      ...state,
      sections: [
        item,
        ...state.sections,
      ],
    }
  }

  if (action.type === CREATE_OR_UPDATE_SECTION_ITEM) {
    const { sectionId, item } = (action as createOrUpdateSectionItemType).payload;
    const index = state.sectionsItem[sectionId].findIndex(i => i.id === item.id)

    if (!!~index) {
      return {
        ...state,
        sectionsItem: {
          [sectionId]: [
            ...state.sectionsItem[sectionId].slice(0, index),
            item,
            ...state.sectionsItem[sectionId].slice(index + 1),
          ],
        },
      }
    }

    return {
      ...state,
      sectionsItem: {
        [sectionId]: [
          item,
          ...state.sectionsItem[sectionId],
        ],
      },
    }
  }

  if (action.type === REMOVE_SECTION) {
    const id = (action as removeSectionType).payload;
    const index = state.sections.findIndex(i => i.id === id)

    return {
      ...state,
      sections: [
        ...state.sections.slice(0, index),
        ...state.sections.slice(index + 1),
      ],
    }
  }

  if (action.type === REMOVE_SECTION_ITEM) {
    const { sectionId, item } = (action as removeSectionItemType).payload;
    const index = state.sectionsItem[sectionId].findIndex(i => i.id === item.id)

    return {
      ...state,
      sectionsItem: {
        [sectionId]: [
          ...state.sectionsItem[sectionId].slice(0, index),
          ...state.sectionsItem[sectionId].slice(index + 1),
        ],
      },
    }
  }

  return state;
};

export default InformationReducerMethod;
