import { ISource } from '@modules/translate/types/translate';
import { TranslateReducerAdmin } from '@modules/translate/types/transReducerAdmin';
import {
  CREATE_LANG_ITEM,
  CREATE_TRANSLATE_LIST_ITEM_ADMIN,
  CreateLangItemAction,
  CreateTranslateListItemAdminAction,
  EXPORT_TRANSLATE_ITEM_ADMIN,
  ExportTranslateListAdminAction,
  REMOVE_EXPORT_TRANSLATE_ITEM_ADMIN,
  REMOVE_TRANSLATE,
  RemoveExportTranslateListAdminAction, RemoveTranslateItem,
  SET_BASE_URL, SET_EDIT_TRANSLATE, SET_EDIT_TRANSLATE_KEY, SET_LANGUAGE_ADMIN_LIST,
  SET_LANGUAGE_LIST_ADMIN, SET_TRANSLATE_COUNT,
  SET_TRANSLATE_LIST_ADMIN,
  SetBaseUrlAction, setEditTranslateKeyType, setEditTranslateType,
  SetLanguageListAdminAction, setLanguageListAdminType, setTranslateCountAction,
  SetTranslateListAdminAction,
  TranslateActionAdmin,
  UPDATE_LANG_ITEM,
  UPDATE_TRANSLATE_LIST_ITEM_ADMIN,
  UpdateLangItemAction,
  UpdateTranslateListItemAdminAction,
} from '@modules/translate/actions/actionAdmin';
import { ITranslateListAdmin } from '@modules/translate/types/translateListAdmin';
import { ILang } from '@modules/translate/types/lang';

const defaultSource = {
  current: '',
  items: [
    { name: 'LANGCO', url: process.env.NEXT_PUBLIC_API_URL_CLIENT },
  ],
};
const defaultState: TranslateReducerAdmin = {
  langs: [],
  langsAdmin: [],
  list: [],
  source: defaultSource as ISource,
  export: [],
  count: 0,
  editTranslateKey: undefined,
  editTranslate: false
};

const AdminReducer = (
  state: TranslateReducerAdmin = defaultState,
  action: TranslateActionAdmin,
): TranslateReducerAdmin => {


  if (action.type === SET_LANGUAGE_LIST_ADMIN) {
    return {
      ...state,
      langs: (action as SetLanguageListAdminAction).payload.langs,
    };
  }

  if (action.type === SET_LANGUAGE_ADMIN_LIST) {
    return {
      ...state,
      langsAdmin: (action as setLanguageListAdminType).payload,
    };
  }

  if (action.type === SET_EDIT_TRANSLATE) {
    return {
      ...state,
      editTranslate: (action as setEditTranslateType).payload,
    };
  }

  if (action.type === SET_EDIT_TRANSLATE_KEY) {
    return {
      ...state,
      editTranslateKey: (action as setEditTranslateKeyType).payload,
    };
  }

  if (action.type === SET_BASE_URL) {
    const items = state.source.items;
    return {
      ...state,
      source: {
        current: (action as SetBaseUrlAction).payload.url,
        items,
      },
    };
  }

  if (action.type === SET_TRANSLATE_LIST_ADMIN) {
    return {
      ...state,
      list: (action as SetTranslateListAdminAction).payload.list,
    };
  }

  if (action.type === UPDATE_TRANSLATE_LIST_ITEM_ADMIN) {
    const item = (action as UpdateTranslateListItemAdminAction).payload.item;
    const index = state.list.findIndex((i: ITranslateListAdmin) => i.id === item.id);
    return {
      ...state,
      list: [
        ...state.list.slice(0, index),
        item,
        ...state.list.slice(index + 1),
      ],
    };
  }

  if (action.type === CREATE_TRANSLATE_LIST_ITEM_ADMIN) {
    const item = (action as CreateTranslateListItemAdminAction).payload.item;
    return {
      ...state,
      list: [
        item,
        ...state.list,
      ],
    };
  }

  if (action.type === REMOVE_TRANSLATE) {
    const { list } = state;
    const id = (action as RemoveTranslateItem).payload;
    const index = list.findIndex((i) => i.id === id);

    return {
      ...state,
      list: [
        ...list.slice(0, index),
        ...list.slice(index + 1),
      ],
    };
  }

  if (action.type === EXPORT_TRANSLATE_ITEM_ADMIN) {
    const item = (action as ExportTranslateListAdminAction).payload.item;

    return {
      ...state,
      export: [
        item,
        ...state.export,
      ],
    };
  }

  if (action.type === REMOVE_EXPORT_TRANSLATE_ITEM_ADMIN) {
    const item = (action as RemoveExportTranslateListAdminAction).payload.item;
    const index = state.export.findIndex((i: number) => i === item);

    return {
      ...state,
      export: [
        ...state.export.slice(0, index),
        ...state.export.slice(index + 1),
      ],
    };
  }

  if (action.type === SET_TRANSLATE_COUNT) {
    return {
      ...state,
      count: (action as setTranslateCountAction).payload,
    };
  }

  if (action.type === CREATE_LANG_ITEM) {
    const item = (action as CreateLangItemAction).payload.item;

    return {
      ...state,
      langs: [
        ...state.langs,
        item,
      ],
    };
  }

  if (action.type === UPDATE_LANG_ITEM) {
    const item = (action as UpdateLangItemAction).payload.item;
    const index = state.langs.findIndex((i: ILang) => i.id === item.id);

    return {
      ...state,
      langs: [
        ...state.langs.slice(0, index),
        item,
        ...state.langs.slice(index + 1),
      ],
    };
  }

  return state;
};

export default AdminReducer;
