import { CommonAction } from '@modules/common/actions';
import { ISections, ISectionsItem } from '@modules/information/info/types/information';

export const SET_SECTIONS = 'SET_SECTIONS';
export const SET_SECTIONS_ITEM = 'SET_SECTIONS_ITEM';
export const CREATE_OR_UPDATE_SECTION = 'CREATE_OR_UPDATE_SECTION';
export const CREATE_OR_UPDATE_SECTION_ITEM = 'CREATE_OR_UPDATE_SECTION_ITEM';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const REMOVE_SECTION_ITEM = 'REMOVE_SECTION_ITEM';

export const setSectionsList = CommonAction<ISections[]>(SET_SECTIONS)
export const setSectionsItem = CommonAction<ILoadItemsSection>(SET_SECTIONS_ITEM)
export const createOrUpdateSections = CommonAction<ISections>(CREATE_OR_UPDATE_SECTION)
export const createOrUpdateSectionItems = CommonAction<IUpdateAndRemoveItemSection>(CREATE_OR_UPDATE_SECTION_ITEM)
export const removeSection = CommonAction<number>(REMOVE_SECTION)
export const removeSectionItem = CommonAction<IUpdateAndRemoveItemSection>(REMOVE_SECTION_ITEM)

export type SetSectionsListType = ReturnType<typeof setSectionsList>
export type SetSectionsItemType = ReturnType<typeof setSectionsItem>
export type createOrUpdateSectionType = ReturnType<typeof createOrUpdateSections>
export type createOrUpdateSectionItemType = ReturnType<typeof createOrUpdateSectionItems>
export type removeSectionType = ReturnType<typeof removeSection>
export type removeSectionItemType = ReturnType<typeof removeSectionItem>

export type InformationAction =
  SetSectionsListType
  | SetSectionsItemType
  | createOrUpdateSectionType
  | createOrUpdateSectionItemType
  | removeSectionType
  | removeSectionItemType;

export interface ILoadItemsSection {
  sectionId: number
  list: ISectionsItem[]
}

interface IUpdateAndRemoveItemSection {
  sectionId: number
  item: ISectionsItem
}
