import {
  AccountsAction,
  CREATE_ACCOUNTS,
  CreateAccountsType,
  SET_ACCOUNTS_LIST, SetAccountsListType,
} from '@modules/accounts/actions/accounts';
import { IAccountsReducer } from '../../type/account';
import {
  ActionExchanger,
  CREATE_EXCHANGER,
  createExchangerType,
  REMOVE_EXCHANGER,
  removeExchangerType,
  SET_EXCHANGER_LIST,
  SetExchangersType,
} from '../../actions/exhanger';
import { ActionToken, CREATE_TOKEN, CreateTokenType, SET_TOKEN, SetTokenType } from '../../actions/token';
import {
  AccountsTokenAction, CREATE_ACCOUNT_TOKEN, CreateAccountTokenType, REMOVE_ACCOUNT_TOKEN, RemoveAccountTokenItemType,
  SET_ACCOUNT_TOKEN_LIST,
  SetAccountTokenListType,
} from '@modules/accounts/actions/accounts-token';


const defaultState: IAccountsReducer = {
  exchangers: [],
  tokens: [],
  accounts: [],
  accountToken: [],
}

const AccountsAdminReducer = (
  state: IAccountsReducer = defaultState,
  action: AccountsAction | ActionExchanger | ActionToken | AccountsTokenAction,
): IAccountsReducer => {
  if (action.type === SET_ACCOUNTS_LIST) {

    return {
      ...state,
      accounts: ((action as SetAccountsListType).payload || []),
    }
  }

  if (action.type === CREATE_ACCOUNTS) {
    const item = (action as CreateAccountsType).payload

    return {
      ...state,
      accounts: [
        ...state.accounts,
        item,
      ],
    }
  }

  if (action.type === SET_EXCHANGER_LIST) {
    return {
      ...state,
      exchangers: ((action as SetExchangersType).payload || []),
    }
  }

  if (action.type === CREATE_EXCHANGER) {
    const { exchangers } = state
    const item = (action as createExchangerType).payload;
    return {
      ...state,
      exchangers: [
        item,
        ...exchangers,
      ],
    }
  }

  if (action.type === REMOVE_EXCHANGER) {
    const { exchangers } = state
    const item = (action as removeExchangerType).payload;
    const index = exchangers.findIndex(i => i.slug === item.slug)
    return {
      ...state,
      exchangers: [
        ...exchangers.slice(0, index),
        ...exchangers.slice(index + 1),
      ],
    }
  }

  if (action.type === SET_TOKEN) {
    return {
      ...state,
      tokens: ((action as SetTokenType).payload || []),
    }
  }

  if (action.type === CREATE_TOKEN) {
    return {
      ...state,
      tokens: [
        (action as CreateTokenType).payload,
        ...state.tokens,
      ],
    }
  }

  if (action.type === SET_ACCOUNT_TOKEN_LIST) {
    return {
      ...state,
      accountToken: (action as SetAccountTokenListType).payload,
    }
  }

  if (action.type === CREATE_ACCOUNT_TOKEN) {
    return {
      ...state,
      accountToken: [
        (action as CreateAccountTokenType).payload,
        ...state.accountToken,
      ],
    }
  }

  if (action.type === REMOVE_ACCOUNT_TOKEN) {
    const { accountToken } = state
    const item = (action as RemoveAccountTokenItemType).payload;
    const index = accountToken.findIndex(i => i.account.id === item.account.id && i.token.slug === item.token.slug)
    return {
      ...state,
      accountToken: [
        ...accountToken.slice(0, index),
        ...accountToken.slice(index + 1),
      ],
    }

  }

  return state;
}

export default AccountsAdminReducer;
