import { TransModifier, TransProps } from '../types/translate';
import isObject from 'lodash/isObject';
import React, { ReactElement } from 'react';

export type TranslateFuncType = (trans: string | TransProps, modifier?: TransModifier, strOnly?: boolean, ignoreSkeleton?: boolean, params?: boolean) => string | React.ReactElement



export const TranslateFunction = (tr: (key: string, ignoreSkeleton: boolean, params?: boolean) => string | ReactElement, edit: boolean = false, editKey?: (key: string) => void): TranslateFuncType => (trans: string | TransProps, modifier?: TransModifier, strOnly: boolean = false, ignoreSkeleton: boolean = false, params: boolean = false) => {
  const buttonEdit = (value: string) => (
    <>
      <span
        onClick={(e) => {
          e.preventDefault();
          editKey && editKey(value);
        }}
        className="json icon-edit button-translate-edit"
      />
    </>
  );
  if (typeof trans === 'string') {
    if (edit && !strOnly) {
      const c = (
        <span>
          {tr(trans, ignoreSkeleton)}
          {buttonEdit(trans)}
        </span>
      );

      return {...c, toString: () => {
        const v = tr(trans, ignoreSkeleton);
        if (isObject(v)) {
          return trans as string;
        }

        return v;
      }};
    }

    return tr(trans, ignoreSkeleton, params);
  }

  if (!trans) {
    return '';
  }

  let tValue = tr(trans.key, ignoreSkeleton, !!trans.params);
  if (!tValue) {
    if (edit && !strOnly) {
      const c = (
        <span>
          {trans.key}
          {buttonEdit(trans.key)}
        </span>
      );
      return {...c, toString: () => {
          const v = tr(trans.key, ignoreSkeleton, !!trans.params);
          if (isObject(v)) {
            return trans.key as string;
          }

          return v;
        }};
    }

    return tr(trans.key, ignoreSkeleton, !!trans.params);
  }
  if (trans.params) {
    Object.keys(trans.params).forEach((pKey: string) => {
      const value = trans.params[pKey];
      const _val = isObject(value) ? value : value.toString();
      const val = TranslateFunction(tr)(_val, undefined, false, false, true);
      const mVal = modifier && modifier[pKey] ? modifier[pKey](val as string) : val;

      tValue = ('' + tValue).split('{$' + pKey + '}').join(mVal as string);
    });
  }

  if (edit && !strOnly) {
    const c = (
      <span>
        {tValue}
        {buttonEdit(trans.key)}
      </span>
    );

    return {...c, toString: () => {
        const v = tr(trans.key, ignoreSkeleton, !!trans.params);
        if (isObject(v)) {
          return trans.key as string;
        }

        return v;
      }};
  }

  return tValue;
};
