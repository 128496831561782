import { CommonAction } from '@modules/common/actions';
import { CasbinRules } from '@modules/privacy/types';

export const LOAD_CASBIN_RULES = 'LOAD_CASBIN_RULES';
export const ADD_OR_UPDATE_CASBIN_RULE = 'ADD_CASBIN_RULE';
export const REMOVE_CASBIN_RULE = 'REMOVE_CASBIN_RULE';
export const LOAD_SEARCHED_RULES = 'LOAD_SEARCHED_RULES';

export const loadCasbinRulesAction = CommonAction<CasbinRules[]>(LOAD_CASBIN_RULES);
export const addOrUpdateCasbinRule = CommonAction<CasbinRules>(ADD_OR_UPDATE_CASBIN_RULE);
export const removeCasbinRule = CommonAction<number>(REMOVE_CASBIN_RULE);
export const loadSearchedRules = CommonAction<CasbinRules[]>(LOAD_SEARCHED_RULES)

export type LoadCasbinRulesAction = ReturnType<typeof loadCasbinRulesAction>;
export type addOrUpdateCasbinAction = ReturnType<typeof addOrUpdateCasbinRule>;
export type removeCasbinAction = ReturnType<typeof removeCasbinRule>;
export type loadSearchedRules = ReturnType<typeof loadSearchedRules>;

export type CasbinAction = LoadCasbinRulesAction | addOrUpdateCasbinAction | removeCasbinAction | loadSearchedRules;
