import { CommonAction } from '@modules/common/actions';
import {  Status } from '@modules/status/types';

export const SET_STATUS = 'SET_STATUS';

export const setStatusAction = CommonAction<Status>(SET_STATUS);

export type setStatusType = ReturnType<typeof setStatusAction>;

export type StatusActions = setStatusType
