import uniqBy from 'lodash/uniqBy';
import { BaseUser, IUserReducer } from '@modules/user/types';
import {
  APPEND_USER, SET_STUDENTS,
  SET_USER,
  SET_USERS, setStudentsActionType,
  setUsersType,
  setUserType, teacherCountType,
  TEACHERS_COUNT, USERS_COUNT,
  UsersActions, usersCountType, CLEAR_TEACHERS,
} from '@modules/user/actions';

const defaultState: IUserReducer<BaseUser> = {
  list: [],
  item: {} as BaseUser,
  students: [] as BaseUser[],
  teachersCount: 0,
  count: 0,
};

const UserReducerMethod = (
  state: IUserReducer<BaseUser> = defaultState,
  action: UsersActions,
): IUserReducer<BaseUser> => {
  if (action.type === SET_USERS) {
    return {
      ...state,
      list:  (action as setUsersType).payload || [],
    };
  }

  if (action.type === CLEAR_TEACHERS) {
    return {
      ...state,
      list: [],
    };
  }

  if (action.type === SET_STUDENTS) {
    return {
      ...state,
      students: [
        ...(action as setStudentsActionType).payload || [],
      ]
    };
  }

  if (action.type === APPEND_USER) {
    return {
      ...state,
      list: uniqBy([
        ...(state.list || []),
        (action as setUserType).payload
      ].reverse(), (e) => e.user.id).reverse(),
    };
  }

  if (action.type === SET_USER) {
    const item = (action as setUserType).payload;

    if (!item) {
      return state;
    }

    return {
      ...state,
      item,
    };
  }

  if (action.type === TEACHERS_COUNT) {
    const count = (action as teacherCountType).payload;

    return {
      ...state,
      teachersCount: count,
    };
  }

  if (action.type === USERS_COUNT) {
    const count = (action as usersCountType).payload;

    return {
      ...state,
      count: count,
    };
  }

  return state;
};

export default UserReducerMethod;
