import { CommonAction } from '@modules/common/actions';
import { Notification } from '@modules/notification/types/notification';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const UNREAD_NOTIFICATION_COUNT = 'UNREAD_NOTIFICATION_COUNT';
export const READ_ALL_NOTIFICATION = 'READ_ALL_NOTIFICATION';
export const READ_ONE_NOTIFICATION = 'READ_ONE_NOTIFICATION';

export const setNotifications = CommonAction<Notification[]>(SET_NOTIFICATIONS);
export const unreadNotificationCount = CommonAction<number>(UNREAD_NOTIFICATION_COUNT);
export const setReadAllNotification = CommonAction<null>(READ_ALL_NOTIFICATION);
export const setReadOneNotification = CommonAction<number>(READ_ONE_NOTIFICATION);

export type SetNotificationsType = ReturnType<typeof setNotifications>;
export type UnreadNotificationCountType = ReturnType<typeof unreadNotificationCount>;
export type SetReadAllNotificationType = ReturnType<typeof setReadAllNotification>;
export type SetReadOneNotificationType = ReturnType<typeof setReadOneNotification>;

export type NotificationAction =
  SetNotificationsType
  | UnreadNotificationCountType
  | SetReadAllNotificationType
  | SetReadOneNotificationType