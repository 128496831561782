import Router from 'next/router';
import { User } from '@modules/auth/types/Users';
import { INotifMessage } from '@modules/common/types';
import { removeCookie, setCookie } from '@modules/common/tools/cookie';

export const INIT_APP = 'INIT_APP';
export const CLEAR_ALL = 'CLEAR_ALL';
export const CATCH_ERROR = 'CATCH_ERROR';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';

export const SET_LOADING_CONTEXT = 'SET_LOADING_CONTEXT';
export const REMOVE_LOADING_CONTEXT = 'REMOVE_LOADING_CONTEXT';

export const ON_USER_DATA_CHANGED = 'ON_USER_DATA_CHANGED';

export const CommonAction = <T>(type: string) => (payload: T) => ({
  type,
  payload,
});

export const catchError = (data: INotifMessage) => ({
  type: CATCH_ERROR,
  payload: data,
});

export const successMessage = (data: INotifMessage) => ({
  type: SUCCESS_MESSAGE,
  payload: data,
});

export const setLoadingContext = (context: string = 'common') => ({
  type: SET_LOADING_CONTEXT,
  payload: context,
});

export const removeLoadingContext = (context = 'common') => ({
  type: REMOVE_LOADING_CONTEXT,
  payload: context,
});

export const onUserDataChanged = CommonAction<User>(ON_USER_DATA_CHANGED);

export type onUserDataChangedType = ReturnType<typeof onUserDataChanged>;

export const clearData = () => {
  setCookie('remember', 'false');
  removeCookie('token');
  removeCookie('originToken');
  Router.replace('/');

  return {
    type: CLEAR_ALL,
  };
}

export const initApp = <T extends any>(app: T) => {
  app.init();

  return {
    type: INIT_APP,
    payload: {
      app
    }
  }
};

interface NotifAction {
  type: string
  payload: INotifMessage | string | void
}

export type CommonAction = NotifAction | onUserDataChangedType;
