import { Subject } from 'rxjs';
import { IListEditData, IListReducer } from '@modules/lists/types';
import { ListActions, SET_LIST_VALUE, setListValueActionType } from '@modules/lists/actions';

const ListReducerMethod = (
  state: IListReducer = {
    editSubject: new Subject<IListEditData>(),
    items: {}
  },
  action: ListActions,
): IListReducer => {
  if (action.type === SET_LIST_VALUE) {
    const item = (action as setListValueActionType).payload;
    return {
      ...state,
      items: {
        ...state.items,
        [item.context]: item.value,
      }
    };
  }

  return state;
};

export default ListReducerMethod;
