import { AppDispatch } from '@app-types/appStorageType';
import { apiCall } from '@commands/httpClient';
import {
  addOrUpdateCasbinRule,
  loadCasbinRulesAction, loadSearchedRules, removeCasbinRule,
} from '@modules/privacy/actions';
import {
  CasbinRules, ICasbinRules,
  ICasbinRulesList,
} from '@modules/privacy/types';
import { BaseCommandProps } from '@app-types/CommonTypes';
import { ResultItemRes, ResultListRes } from '@modules/apiClient/type';
import { IRole } from "@modules/roles/types";
import { loadRolesAction } from "@modules/roles/actions";

export const loadCasbinRules = () => (dispatch: AppDispatch<ICasbinRulesList>) => {
  dispatch(apiCall<object, ICasbinRulesList>(`/v1/privacy/casbin`, undefined, { method: 'get' }))
    .then(({ list }: ICasbinRulesList) => {
      const items: CasbinRules[] = list.map(i => new CasbinRules(i)) as CasbinRules[];
      dispatch(loadCasbinRulesAction(items))
    });
}

export const addOrUpdateRule = (value: CasbinRules) => (dispatch: AppDispatch<ICasbinRulesList>) => {
  if (!value.id) {
    return dispatch(apiCall<object, ResultItemRes<CasbinRules>>(`/v1/privacy/casbin`, value, { method: 'post', context: 'common' }))
      .then(({item}) => dispatch(addOrUpdateCasbinRule(item)));
  }
  return dispatch(apiCall(`/v1/privacy/casbin`, value, { method: 'put' }))
    .then((res) => dispatch(addOrUpdateCasbinRule(value)));
}

export const removeRule = (id: number) => (dispatch: AppDispatch<{ id: number }>) => {
  return dispatch(apiCall<object, BaseCommandProps>(`/v1/privacy/casbin`, { id }, { method: 'delete' }))
    .then((res) => dispatch(removeCasbinRule(id)));
}

export const reloadRule = () => (dispatch: AppDispatch<object>) => {
  return dispatch(apiCall<object, BaseCommandProps>(`/v1/privacy/casbin/reload`, undefined, { method: 'get' }))
}

export const searchRule = (value: string) => (dispatch: AppDispatch<{ value: string }>) => {
  return dispatch(apiCall<object, ICasbinRulesList>(`/v1/privacy/casbin/search`, { query: value }, { method: 'post' }))
    .then(({ list }) => {
      const items = list.map(i => new CasbinRules(i));
      dispatch(loadSearchedRules(items));
    });
}

export const loadRoles = () => (dispatch: AppDispatch<IRole[]>) => {
  return dispatch(apiCall<object, ResultListRes<IRole[]>>(`/v1/privacy/casbin/roles`, undefined, { method: 'get' }))
  .then(({ list }: ResultListRes<IRole[]>) => dispatch(loadRolesAction(list)));
}

export const loadListOfGRoles = () => (dispatch: AppDispatch<ICasbinRules>) => {
  return dispatch(apiCall<object, ResultListRes<ICasbinRules[]>>(`/v1/privacy/casbin/list-g-roles`, undefined, { method: 'get' }))
  .then(({ list }: ResultListRes<ICasbinRules[]>) => dispatch(loadCasbinRulesAction(list)));
}
