import { setCountryAction } from '@modules/country-corrency/actions/index';
import useLocale from '@modules/translate/useLocale';
import dynamic from 'next/dynamic';
import { AnyAction } from 'redux';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { initApp } from '@modules/common/actions';
import { CommonApp } from '@modules/common/models';
import { AppStorageType } from '@app-types/appStorageType';
import useSession from '@hooks/useSessions';
import { loadRoles } from '@commands/privacy';
import { User } from '@modules/auth/types/Users';

const ElfsightWidget = dynamic(() => import('react-elfsight-widget').then((m) => m.ElfsightWidget), { ssr: false });

export const mapStateToProps = (state: AppStorageType) => ({
  onUserChanged: state.common.onUserChanged,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => ({
  dispatch,
  init: (app: CommonApp<AppStorageType>) => dispatch(initApp(app)),
  loadRoles: () => dispatch(loadRoles()),
  setCountry: (country: string) => dispatch(setCountryAction(country)),
});

type ComponentProps =
  {
    appInit: (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => CommonApp<AppStorageType>,
    countryCode?: string
  }
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

const Component = ({ init, onUserChanged, countryCode, setCountry, appInit, dispatch, loadRoles }: ComponentProps) => {
  const session = useSession();
  const [translate] = useLocale();

  // useEffect(() => {
  //   countryCode && setCountry(countryCode);
  // }, [countryCode]);

  useEffect(() => {
    if (!session.userId) {
      return;
    }

    const app = appInit(dispatch);
    init(app);
    session.user.role === 'admin' && loadRoles();

  }, [session.userId]);

  useEffect(() => {
    const subscription = onUserChanged.subscribe({
      next: (u: User) => session.update(u),
    });

    return () => {
      !subscription.closed && subscription.unsubscribe();
    };
  }, [onUserChanged, session]);

  return (
    <>
      <ElfsightWidget widgetID={String(translate('rest.whatsapp-id'))} />
      <div data-tf-sidetab="S6xUM3Ss" data-tf-opacity="100" data-tf-iframe-props="title=LangCo - English Level Test"
           data-tf-transitive-search-params data-tf-button-color="#000000"
           data-tf-button-text="Know Your English Level!" data-tf-medium="snippet" style={{ 'all': 'unset' }}></div>
      <script src="//embed.typeform.com/next/embed.js"></script>

    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
