import { CommonAction } from '@modules/common/actions';
import { GroupedINote, INote, Note } from '@modules/notes/types';

export const LOAD_NOTES = 'LOAD_NOTES';
export const CREATE_NOTES = 'CREATE_NOTES';
export const UPDATE_NOTES = 'UPDATE_NOTES';
export const DELETE_NOTES = 'DELETE_NOTES';

export const loadNotesAction = CommonAction<GroupedINote>(LOAD_NOTES);
export const createNotesAction = CommonAction<Note>(CREATE_NOTES);
export const updateNotesAction = CommonAction<Note>(UPDATE_NOTES);
export const deleteNotesAction = CommonAction<Note>(DELETE_NOTES);

export type LoadNotesActionType = ReturnType<typeof loadNotesAction>;
export type CreateNotesActionType = ReturnType<typeof createNotesAction>;
export type UpdateNotesActionType = ReturnType<typeof updateNotesAction>;
export type DeleteNotesActionType = ReturnType<typeof deleteNotesAction>;

export type NotesActions = LoadNotesActionType | CreateNotesActionType | UpdateNotesActionType | DeleteNotesActionType;
