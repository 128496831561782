import { CommonAction } from '@modules/common/actions';
import { IReview, IReviewTeachers, ReviewTeachers } from '@modules/review/types';

export const SET_REVIEW_LIST = 'SET_REVIEW_LIST';
export const CREATE_OR_UPDATE_REVIEW = 'CREATE_OR_UPDATE_REVIEW';
export const REMOVE_REVIEW = 'REMOVE_REVIEW';
export const SET_REVIEW_TEACHERS_LIST = 'SET_REVIEW_TEACHERS_LIST';
export const CREATE_OR_UPDATE_REVIEW_TEACHERS = 'CREATE_OR_UPDATE_REVIEW_TEACHERS';
export const REMOVE_REVIEW_TEACHERS = 'REMOVE_REVIEW_TEACHERS';

export const setReviewListAction = CommonAction<IReview[]>(SET_REVIEW_LIST);
export const creteOrUpdateReviewAction = CommonAction<IReview>(CREATE_OR_UPDATE_REVIEW);
export const removeReviewAction = CommonAction<number>(REMOVE_REVIEW);
export const setReviewTeachersListAction = CommonAction<ReviewTeachers[]>(SET_REVIEW_TEACHERS_LIST);
export const creteOrUpdateReviewTeachersAction = CommonAction<IReviewTeachers>(CREATE_OR_UPDATE_REVIEW_TEACHERS);
export const removeReviewTeachersAction = CommonAction<number>(REMOVE_REVIEW_TEACHERS);

export type setReviewListType = ReturnType<typeof setReviewListAction>;
export type creteOrUpdateReviewType = ReturnType<typeof creteOrUpdateReviewAction>;
export type removeReviewActionType = ReturnType<typeof removeReviewAction>;
export type setReviewTeachersListType = ReturnType<typeof setReviewTeachersListAction>;
export type creteOrUpdateReviewTeachersType = ReturnType<typeof creteOrUpdateReviewTeachersAction>;
export type removeReviewTeachersActionType = ReturnType<typeof removeReviewTeachersAction>;

export type ReviewActions =
  setReviewListType
  | creteOrUpdateReviewType
  | removeReviewActionType
  | setReviewTeachersListType
  | creteOrUpdateReviewTeachersType
  | removeReviewTeachersActionType
