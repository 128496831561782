import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Router from 'next/router';
import appConfig from '@configs/app';
import { AppDispatch, AppStorageType } from '@app-types/appStorageType';
import { ApiClientOptions, simpleApiClient } from '@modules/apiClient';
import { catchError, clearData, removeLoadingContext, setLoadingContext } from '@modules/common/actions';
import { INotifMessage } from '@modules/common/types';
import { BaseCommandProps } from '@app-types/CommonTypes';
import { parseCookies } from 'nookies';
import StatusError from '@modules/apiClient/StatusError';

const httpClient = <P extends object | null, T extends BaseCommandProps>(url: string, params?: P, options?: ApiClientOptions) =>
  (dispatch: ThunkDispatch<AppStorageType, {}, Action<Promise<P>> | AnyAction>) => {
    const cookies = parseCookies()
    const { context, asImpersonate } = options || { context: '' };
    const { token, originToken } = cookies;
    const accessToken = asImpersonate ? { accessToken: `Bearer ${originToken}` } : { };
    const headers = {
      ...(options?.headers || {}),
      'Content-Type': 'application/json',
      'authorization': `Bearer ${token}`,
      ...accessToken,
    };

    const clientOptions = { ...(options || {}), headers };

    if (context) {
      dispatch(setLoadingContext(context))
    }

    return simpleApiClient<P, T>(url, params, clientOptions)
      .then((res: T) => {
        if (options?.isFile) {
          return res;
        }

        const { success } = res;

        if (context) {
          dispatch(removeLoadingContext(context))
        }

        if (!success) {
          const { message } = res;
          let extra;
          if (process.env.NODE_ENV !== 'production') {
            extra = {
              url: `/api${url}`,
            }
          }

          throw new StatusError(message ? `${message}` : `No success`, res.statusCode || 200, extra);
        }

        return res;
      })
      .catch((e: StatusError) => {
        if (context) {
          dispatch(removeLoadingContext(context))
        }

        if (options?.silent) {
          return;
        }

        const msg: INotifMessage = {
          message: e.message,
          extra: e.extra,
        }

        dispatch(catchError(msg));

        if (e.status === 405) {
          Router.push(appConfig.public.redirectPage);
          dispatch(clearData());
        }

        throw e;
      });
  }


export const apiCall = <P extends object | null, T extends BaseCommandProps>(url: string, params?: P, options?: ApiClientOptions) =>
  (dispatch: AppDispatch<P>) =>
    dispatch(httpClient<P, T>(url, params, options))
      .then((res: T | void) => res as T);

export default httpClient;
