import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

export abstract class CommonApp<T> {
  protected readonly dispatch: ThunkDispatch<T, void, AnyAction>;

  constructor(dispatch: ThunkDispatch<T, void, AnyAction>) {
    this.dispatch = dispatch;
  }

  init = async () => {

  }

}
