import { IAccountsUserReducer, UserBalance } from '@modules/accounts/type/account';
import {
  AccountsAction,
  SET_ACCOUNTS, SET_SHARES_VALUE, SET_SPECIFIC_BALANCE,
  SetAccountsType, SetSharesValueType, SetSpecificBalanceAction,
  UPDATE_BALANCE_VALUE, UpdateBalanceType,
} from '@modules/accounts/actions/accounts';

const defaultState: IAccountsUserReducer = {
  userBalance: [] as UserBalance[],
  userAllShares: 0,
  specificBalance: [] as UserBalance[],
}

const AccountsUserReducer = (
  state: IAccountsUserReducer = defaultState,
  action: AccountsAction,
): IAccountsUserReducer => {
  if (action.type === SET_ACCOUNTS) {
    return {
      ...state,
      userBalance: ((action as SetAccountsType).payload || [])
    }
  }

  if (action.type === UPDATE_BALANCE_VALUE) {
    const { userBalance } = state;
    return {
      ...state,
      userBalance: userBalance.map(i => {
        i.setValue((action as UpdateBalanceType).payload);
        return i;
      })
    }
  }

  if (action.type === SET_SHARES_VALUE) {
    return {
      ...state,
      userAllShares: (action as SetSharesValueType).payload
    }
  }

  if (action.type === SET_SPECIFIC_BALANCE) {
    return {
      ...state,
      specificBalance: (action as SetSpecificBalanceAction).payload,
    }
  }

  return state;
}

export default AccountsUserReducer;
