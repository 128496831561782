import { CommonAction } from '@modules/common/actions';
import { TeacherStage } from '@modules/teacher-stage/types';

export const SET_TEACHER_STAGE_LIST = 'SET_TEACHER_STAGE_LIST'
export const CREATE_TEACHER_STAGE = 'CREATE_TEACHER_STAGE'
export const REMOVE_TEACHER_STAGE = 'REMOVE_TEACHER_STAGE'

export const setTeacherStageListAction = CommonAction<TeacherStage[]>(SET_TEACHER_STAGE_LIST);
export const createTeacherStageAction = CommonAction<TeacherStage>(CREATE_TEACHER_STAGE);
export const removeTeacherStageAction = CommonAction<number>(REMOVE_TEACHER_STAGE);

export type setTeacherStageListType = ReturnType<typeof setTeacherStageListAction>;
export type createTeacherStageType = ReturnType<typeof createTeacherStageAction>;
export type removeTeacherStageType = ReturnType<typeof removeTeacherStageAction>;

export type TeacherStageActions = setTeacherStageListType | createTeacherStageType | removeTeacherStageType
