import {
  ADD_SECTION,
  ADD_ITEM,
  SHOW_ITEMS,
  SHOW_TREE,
  REFRESH_LAST_SECT,
  WikipediaAction,
  AddItemAction,
  ShowItemsList,
  ShowTreeAction,
  RefreshLastSectionAction,
  LOAD_ITEMS_IN_SECTION,
  LoadItemsInSectionAction,
  SHOW_SECTION,
  ShowSectionList,
  setWikiItemType, SET_WIKI_ITEM,
} from '@modules/information/wikipedia/actions';
import { IWikipediaItems, IWikipediaReducer, IWikipediaSections } from '@modules/information/wikipedia/types';
import { createTree } from '@modules/common/tools/createTree';
import reIndexList from '@helpers/reindexList';

const defaultState: IWikipediaReducer = {
  items: [],
  sections: [],
  tree: [],
  listTable: [],
  item: {} as IWikipediaItems,
};

let lastSelectedSection: IWikipediaSections = {} as IWikipediaSections;

const WikipediaReducer = (
  state: IWikipediaReducer = defaultState,
  action: WikipediaAction,
): IWikipediaReducer => {
  if (action.type === SET_WIKI_ITEM) {
    return ({
      ...state,
      item: (action as setWikiItemType).payload,
    });
  }


  if (action.type === ADD_SECTION) {
    return {
      ...state,
      tree: state.tree,
    };
  }

  if (action.type === ADD_ITEM) {
    const { listTable } = state;
    let index = listTable.findIndex(i => i.id === (action as AddItemAction).payload.id);
    if (index !== -1) {
      if (listTable[index].sectionId !== (action as AddItemAction).payload.sectionId) {
        return {
          ...state,
          listTable: [
            ...listTable.slice(0, index),
            ...listTable.slice(index + 1),
          ],
        };
      } else {
        return {
          ...state,
          listTable: [
            ...listTable.slice(0, index),
            (action as AddItemAction).payload,
            ...listTable.slice(index + 1),
          ],
        };
      }
    }
    if ((action as AddItemAction).payload.sectionId === lastSelectedSection.id) {
      return {
        ...state,
        listTable: [...state.listTable, (action as AddItemAction).payload],
      };
    }
    return {
      ...state,
      items: state.items,
    };
  }

  if (action.type === SHOW_ITEMS) {
    let listItems: IWikipediaItems[] = [] as IWikipediaItems[];

    for (let i of state.listTable) {
      (action as ShowItemsList).payload.map(x => {
        if (i.id === x.id && i.sectionId === x.sectionId) listItems.push(x);
      });
    }

    return {
      ...state,
      items: (action as ShowItemsList).payload,
      listTable: [...listItems],
    };
  }

  if (action.type === SHOW_TREE) {
    let listSection = createTree<IWikipediaSections>((action as ShowTreeAction).payload);

    return {
      ...state,
      tree: listSection,
      sections: (action as ShowTreeAction).payload,
    };
  }

  if (action.type === REFRESH_LAST_SECT) {
    lastSelectedSection = (action as RefreshLastSectionAction).payload;
  }

  if (action.type === LOAD_ITEMS_IN_SECTION) {
    const list = (action as LoadItemsInSectionAction).payload;
    reIndexList(list);
    return {
      ...state,
      listTable: list,
    };
  }

  if (action.type === SHOW_SECTION) {
    return {
      ...state,
      sections: (action as ShowSectionList).payload,
    };
  }

  return state;
};

export default WikipediaReducer;
