import { CommonAction } from '@modules/common/actions';

export const SET_EXIST_TWO_FA_GOOGLE = 'SET_EXIST_TWO_FA_GOOGLE';
export const SET_EXIST_EMAIL_TWO_FA = 'SET_EXIST_EMAIL_TWO_FA';
export const SET_GOOGLE_QR_URL = 'SET_GOOGLE_QR_URL';

export const setExistTwoFaGoogleAction = CommonAction<boolean>(SET_EXIST_TWO_FA_GOOGLE);
export const setGoogleQrUrlAction = CommonAction<string>(SET_GOOGLE_QR_URL);
export const setExistTwoFaEmailAction = CommonAction<boolean>(SET_EXIST_EMAIL_TWO_FA);

export type setExistTwoFaGoogleType = ReturnType<typeof setExistTwoFaGoogleAction>
export type setGoogleQrUrlType = ReturnType<typeof setGoogleQrUrlAction>
export type setExistTwoFaEmailType = ReturnType<typeof setExistTwoFaEmailAction>


export type TwoFaActions = setExistTwoFaGoogleType | setGoogleQrUrlType | setExistTwoFaEmailType
