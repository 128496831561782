import {
  SET_NEWS,
  REMOVE_NEWS, GET_NEWS_COUNT, SET_ADMIN_NEWS, SET_NEWS_ITEM, ADD_NEWS, UPDATE_NEWS,
} from '@modules/information/news/actions';
import {
  CreateNewsType,
  GetNewsCountType, INews,
  NewsAction, NewsReducer,
  RemoveNewsType, SetAdminNewsType, setNewsItemType,
  SetNewsListType, UpdateNewsType,
} from '@modules/information/news/types/news';

const defaultState: NewsReducer = {
  list: [],
  item: {} as INews,
  count: 0,
};

const NewsReducerMethod = (
  state: NewsReducer = defaultState,
  action: NewsAction,
): NewsReducer => {
  if (action.type === SET_NEWS) {
    const items = (action as SetNewsListType).payload;
    let includes = false;
    state.list.map(i => {
      items.map(j => {
        if (i.id === j.id) includes = true;
      });
    });

    if (includes) {
      return {
        ...state,
        list: [
          ...items || [],
        ],
      };
    }

    return {
      ...state,
      list: [
        ...state.list,
        ...items || [],
      ],
    };
  }

  if (action.type === SET_ADMIN_NEWS) {
    const list = (action as SetAdminNewsType).payload;

    return {
      ...state,
      list,
    };
  }

  if (action.type === ADD_NEWS) {
    const item = (action as CreateNewsType).payload;

    return {
      ...state,
      list: [
        item,
        ...state.list,
      ],
    };
  }

  if (action.type === UPDATE_NEWS) {
    const item = (action as UpdateNewsType).payload;
    const index = state.list.findIndex(i => i.id === item.id);

    return {
      ...state,
      list: [
        ...state.list.slice(0, index),
        item,
        ...state.list.slice(index + 1),
      ],
    };
  }

  if (action.type === REMOVE_NEWS) {
    const item = (action as RemoveNewsType).payload;
    const index = state.list.findIndex(i => i.id === item);

    return {
      ...state,
      list: [
        ...state.list.slice(0, index),
        ...state.list.slice(index + 1),
      ],
    };
  }

  if (action.type === GET_NEWS_COUNT) {
    const count = (action as GetNewsCountType).payload;

    return {
      ...state,
      count,
    };
  }

  if (action.type === SET_NEWS_ITEM) {
    const item = (action as setNewsItemType).payload;

    return {
      ...state,
      item,
    };
  }

  return state;
};

export default NewsReducerMethod;
