import { CommonAction } from '@modules/common/actions';
import { TeacherPriority } from '@modules/teacher-priority/types';

export const SET_TEACHER_PRIORITY = 'SET_TEACHER_PRIORITY'

export const setTeacherPriorityAction = CommonAction<TeacherPriority>(SET_TEACHER_PRIORITY);

export type setTeacherPriorityType = ReturnType<typeof setTeacherPriorityAction>;

export type TeacherPriorityActions = setTeacherPriorityType
