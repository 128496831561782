import { CommonAction } from '@modules/common/actions';
import { Account } from '../../model/account';
import { IAccountToken, IAccountTokenValue } from '@modules/accounts/type/account-token';

export const SET_ACCOUNT_TOKEN = 'SET_ACCOUNT_TOKEN';
export const CREATE_ACCOUNT_TOKEN = 'CREATE_ACCOUNT_TOKEN';
export const SET_ACCOUNT_TOKEN_LIST = 'SET_ACCOUNT_TOKEN_LIST';
export const REMOVE_ACCOUNT_TOKEN = 'REMOVE_ACCOUNT_TOKEN';

// export const SetAccountToken = CommonAction<UserBalance[]>(SET_ACCOUNT_TOKEN);
export const CreateAccountToken = CommonAction<IAccountToken>(CREATE_ACCOUNT_TOKEN);
export const SetAccountTokenList = CommonAction<IAccountToken[]>(SET_ACCOUNT_TOKEN_LIST);
export const removeAccountTokenItem = CommonAction<IAccountToken>(REMOVE_ACCOUNT_TOKEN);

// export type SetAccountsType = ReturnType<typeof SetAccounts>;
export type CreateAccountTokenType = ReturnType<typeof CreateAccountToken>;
export type SetAccountTokenListType = ReturnType<typeof SetAccountTokenList>;
export type RemoveAccountTokenItemType = ReturnType<typeof removeAccountTokenItem>;

export type AccountsTokenAction =  CreateAccountTokenType | SetAccountTokenListType | RemoveAccountTokenItemType

