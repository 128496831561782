import React from 'react';
import ReactDOM from 'react-dom';

const portals: IPortals = {};

interface IPortalProps {
  name: string
  children?: object
}

interface IPortalsProps {
  comp?: {
    forceUpdate(): void
    el: object
  }
  el: React.ReactElement
}

interface IPortals {
  'subheader-portal'?: IPortalsProps
}

interface IPortalHandlerProps {
  name: string
}

export class PortalHandler extends React.Component<IPortalHandlerProps> {
  private element: React.ReactElement;

  componentDidMount() {
    const { name } = this.props;
    let portal = portals[name as keyof IPortals];
    if (!portal) {
      portal = { el: this.element };
      portals[name as keyof IPortals] = portal;
    } else {
      if (portal.el) {
        throw new Error(`Portal with name ${name} already exists`);
      }
      portal.el = this.element;
      if (portal.comp) {
        portal.comp.forceUpdate();
      }
    }
  }

  componentWillUnmount() {
    delete portals[this.props.name as keyof IPortals];
  }

  setRef = (ref: HTMLElement) => this.element = ref;

  render() {
    return (<div ref={this.setRef} />);
  }
}

export class Portal extends React.Component<IPortalProps> {
  render() {
    const { name, children } = this.props;
    const portal = portals[name as keyof IPortals];

    if (!portal || !portal.el) {
      portals[name as keyof IPortals] = { comp: this as object } as IPortalsProps;
      return null;
    }

    return ReactDOM.createPortal(children, portal.el);
  }
}
