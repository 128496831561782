import React from 'react';
import moment from 'moment';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppStorageType } from '@app-types/appStorageType';
import { setLanguage, setLanguageList, setTranslateList } from '@modules/translate/actions';
import { loadCache } from '@commands/translate';
import { TranslateContext } from '@contexts/translate';
import { ITranslate, ITranslateContext } from '@modules/translate/types/translate';
import { ILang } from '@modules/translate/types/lang';
import { setCountryAction, setCountryCurrencyAction } from '@modules/country-corrency/actions';
import { ICountryCurrency } from '@modules/country-corrency/types';
import { setLanguageListAdminAction } from '@modules/translate/actions/actionAdmin';

const mapStateToProps = (state: AppStorageType) => ({
  currentLanguage: state.translate.user.currentLanguage,
  cache: state.translate.user.cache,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => ({
  setLangs: (langs: ILang[]) => dispatch(setLanguageList(langs)),
  setLangsAdmin: (langs: ILang[]) => dispatch(setLanguageListAdminAction(langs)),
  loadCache: () => dispatch(loadCache()),
  setLanguage: (code: string) => dispatch(setLanguage(code)),
  setTranslates: (value: ITranslate) => dispatch(setTranslateList(value)),
  setCountryCurrency: (value: ICountryCurrency) => dispatch(setCountryCurrencyAction(value)),
  setCountry: (country: string) => dispatch(setCountryAction(country)),
});

import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/ru';
import 'moment/locale/en-gb';


type ComponentProps =
  & ITranslateContext
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;


class TranslateProvider extends React.Component<React.PropsWithChildren<ComponentProps>> {
  componentDidMount() {
    const {
      setLangs,
      setTranslates,
      setLanguage,
      setCountryCurrency,
      translatesServer,
      currentLanguageServer,
      langsServer,
      setCountry,
      setLangsAdmin,
      langsAdminServer,
      countryCurrency,
    } = this.props;

    if (countryCurrency) {
      setCountryCurrency(countryCurrency);
      setCountry(countryCurrency.title);
    }
    langsServer && setLangs(langsServer);
    langsAdminServer && setLangsAdmin(langsAdminServer);
    setTranslates(translatesServer || {});
    currentLanguageServer && setLanguage(currentLanguageServer);
    moment.locale(currentLanguageServer);
  }

  render() {
    const { currentLanguageServer, translatesServer, children } = this.props;

    return (
      <TranslateContext.Provider value={{ currentLanguageServer, translatesServer }}>
        {children}
      </TranslateContext.Provider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TranslateProvider);
