import Router from 'next/router';
import { NextPageContext } from 'next';

const redirect = async (ctx: NextPageContext, to: string, extraHeader?: { [key: string]: string }) => {
  if (ctx.res) {
    if (extraHeader) {
      const keys = Object.keys(extraHeader);

      keys.forEach((key: string) => {
        ctx!.res!.setHeader(key, extraHeader[key].toString());
      })
    }

    ctx.res.writeHead(307, {
      Location: to,
    })
    ctx.res.end()
  } else {
    window.location.href = to;
    //await Router.push(to)
    await new Promise((resolve) => {})
  }
}

export default redirect;
