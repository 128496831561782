import { combineReducers } from 'redux';
import WebinarReducer from '@modules/information/webinars/reducers';
import { IWebinar } from '@modules/information/webinars/types/information';
import InformationReducerMethod from '@modules/information/info/reducers';
import { NewsReducer } from '@modules/information/news/types/news';
import NewsReducerMethod from '@modules/information/news/reducers';
import WikipediaReducer from '@modules/information/wikipedia/reducers';
import { IWikipediaReducer } from '@modules/information/wikipedia/types';
import { InformationReducer } from '@modules/information/info/types/information';
import TeamReducerMethod from '@modules/information/team/reducers';
import { ITeam } from '@modules/information/team/types/information';
import { IBlogReducer } from '@modules/information/blog/types';
import BlogReducerMethod from '@modules/information/blog/reducer';

export interface IInformationReducer {
  webinars: IWebinar[],
  info: InformationReducer,
  news: NewsReducer,
  team: ITeam,
  wikipedia: IWikipediaReducer,
  blog: IBlogReducer,
}
export const InformationsReducer = combineReducers<IInformationReducer>({
  webinars: WebinarReducer,
  info: InformationReducerMethod,
  news: NewsReducerMethod,
  team: TeamReducerMethod,
  wikipedia: WikipediaReducer,
  blog: BlogReducerMethod,
});
